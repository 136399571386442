import React from 'react';
import { aircraftFile } from '../../../helpers/aircraftFiles/output_bl_ccw'

export const AircraftSceneSummary = ({
	aircraftClasses,
	selectedAircraftClass,
	setSelectedAircraftClass
}) => {
	const aircraftCounts = aircraftFile.features.reduce((acc, item) => {
		const city = item.properties['class_name'];
		acc[city] = (acc[city] || 0) + 1;
		return acc;
	}, {});

	return (
		<div style={{ maxHeight: '220px' }}>
			<p>{aircraftFile.features.length} aircraft</p>
			<div class="scroll-container">
				<div class="content">
					<table>
						<thead>
							<tr>
								<td>
									<em>Qty</em>
								</td>
								<td className="text-start">
									<em>Class</em>
								</td>
							</tr>
						</thead>
						<tbody>
							{aircraftClasses.map((aircraftClass, idx) => (
								<tr key={idx}>
									<td
										className={
											selectedAircraftClass &&
											selectedAircraftClass.class_id === aircraftClass.class_id
												? 'fw-bold text-end'
												: 'text-end'
										}
									>
										{aircraftCounts.hasOwnProperty(aircraftClass.class_name)
											? aircraftCounts[aircraftClass.class_name]
											: 0}
									</td>
									<td
										onClick={(e) =>
											setSelectedAircraftClass({
												class_name: aircraftClass.class_name,
												class_id: aircraftClass.class_id
											})
										}
										className={
											selectedAircraftClass &&
											selectedAircraftClass.class_id === aircraftClass.class_id
												? 'fw-bold text-start'
												: 'text-start'
										}
									>
										{aircraftClass.class_name}
									</td>
									<td>
										<input
											type="radio"
											name="aircraftSceneSummary"
											className="me-3 form-check-input"
											checked={
												selectedAircraftClass
													? selectedAircraftClass.class_id ===
													  aircraftClass.class_id
													: false
											}
											onClick={(e) =>
												setSelectedAircraftClass({
													class_name: aircraftClass.class_name,
													class_id: aircraftClass.class_id
												})
											}
										></input>
									</td>
								</tr>
							))}
							{/* {Object.entries(aircraftCounts).map(([key, value]) => (
                <tr key={key}>
                    <td>{key}</td>
                    <td className="text-end">{value}</td>
                </tr>
            ))} */}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};
