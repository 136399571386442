import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import config from '../../../config';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import divideReturnQCList from '../../../helpers/divideReturnQCList';
import { pipelineContext } from '../../../contexts/pipelineContext';
import useGainsQCApi from '../../../services/useGainsQCApi';
import CapturesTable from '../../CapturesTable';
import { smelterFields, stockpileFields } from '../tableFields';
import exportConfig from '../../../config';

const QCCaptures = ({
	filters,
	formValue,
	moveToQC,
	setFilters,
	fetchCapturesError,
	user
}) => {
	const [autoCaptures, setAutoCaptures] = useState('');
	const [loading, setLoading] = useState(true);

	const {
		data: sendCapsBackResult,
		error: errorSendingCapsBack,
		makeRequest: sendCapsBack
	} = useGainsQCApi();

	const {
		data: sendCapsBackResultSP,
		error: errorSendingCapsBackSP,
		makeRequest: sendCapsBackSP
	} = useGainsQCApi();

	const {
		capsInPipeline,
		setCapsInPipeline,
		comm,
		setProgressBar,
		setQCInProgress,
		qcInProgress,
		straightToQC,
		qcType
	} = useContext(pipelineContext);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1000);
		setAutoCaptures([]);
	}, [capsInPipeline]);

	const resetCapsAndGoBackToForms = () => {
		if (capsInPipeline.length > 0) {
			let caps = capsInPipeline.map((cap) => cap.id);

			if (comm === 'smelters') {
				sendCapsBack(
					`${exportConfig.baseUrl}/${comm}/remove_from_queue`,
					'POST',
					{
						commodityIds: `${formValue.formCommodities.join(',')}`,
						qcUser: user,
						captureIds: caps.join(',')
					},
					'sending caps back'
				);
			} else {
				const urls = divideReturnQCList(capsInPipeline);
				for (let i in urls) {
					sendCapsBack(
						`${exportConfig.baseUrl}/${comm}/reset_captures?table=${exportConfig.tableNames[comm]}&captureIds=${urls[i]}`,
						'POST',
						null
					);
				}
			}

			
		}

		if (!errorSendingCapsBack) {
			setCapsInPipeline([]);
			setProgressBar({
				width: 3 * (100 / 7),
				title: qcType
			});
			setQCInProgress(false);
			//setLoading(false);
		}
	};

	const movingToQC = () => {
		moveToQC(capsInPipeline, autoCaptures);
	};

	const renderSkeletonTable = () => {
		return (
			<div className="table-responsive" style={{ maxHeight: '400px' }}>
				<table className="table">
					<thead className="table-light">
						<tr>
							<th scope="col">ID</th>
							<th scope="col">Capture Date</th>
							<th scope="col">Capture Time</th>
							<th scope="col">Site</th>
							<th scope="col">Sensor</th>
							<th scope="col">Site ID</th>
							<th scope="col">Capacity</th>
							<th scope="col">Initial State</th>
							<th scope="col">State</th>
							<th scope="col">Hotspots</th>
							<th scope="col">Invalid Frac</th>
							<th scope="col">Valid Data Frac</th>
							<th scope="col">Max Band Ratio</th>
						</tr>
					</thead>
					<tbody className="table-group-divider">
						{Array.from({ length: 4 }, (_, rowIndex) => (
							<tr key={rowIndex}>
								{Array.from({ length: 13 }, (_, rowIndex) => (
									<td>
										<Skeleton />
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	};

	if (loading) {
		return (
			<div>
				<hr></hr>

				<div className="px-3 mx-3">
					<div className="row">
						<Col className="col-6 my-2 my-md-0 col-md-3 order-1 order-md-6 d-flex justify-content-start">
							<div className="d-flex flex-row align-items-center">
								<button
									className={
										capsInPipeline.length > 0
											? 'btn interactButtonSecondary'
											: 'btn interactButton'
									}
									style={{ maxHeight: '40px', width: '200px' }}
									onClick={(e) => resetCapsAndGoBackToForms(qcType)}
									data-tooltip-id={!fetchCapturesError ? "regress-to-form-reset-caps" : ""}
								>
									<i class="fa-solid fa-left-long pe-3"></i>
									Back to form
								</button>
								<h1 className="ms-5 mt-2">QC</h1>
							</div>
						</Col>

						<Tooltip
							id="regress-to-form-reset-caps"
							place="bottom"
							effect="solid"
						>
							Send back to the database the {capsInPipeline.length} captures
							assigned to you and navigate back to the form
						</Tooltip>
						

						<Col className="col-6 my-2 my-md-0 col-md-3 order-2 order-md-5 offset-md-6 d-flex justify-content-end align-items-center">
							<button
								data-tooltip-id="autoQCCaptureTooltip"
								className="btn interactButton"
								style={{ maxHeight: '40px', width: '200px' }}
								disabled
								onClick={(e) => moveToQC(capsInPipeline, autoCaptures)}
							>
								QC captures
								<i class="fa-solid fa-right-long ps-3"></i>
							</button>
						</Col>
					</div>

					<h4 className="py-1 py-md-3">Loading captures ..</h4>

					<div className="row">
						<Col className="col-6 my-2 my-md-0 col-md-3 order-3 offset-md-3 order-md-1 text-center">
							{capsInPipeline.length > 0 ? (
								<h1>{capsInPipeline.length}</h1>
							) : (
								<h1>
									<Skeleton width={40} />
								</h1>
							)}
							{/* <h1>{captures.length}</h1> */}

							<p>captures to QC</p>
						</Col>
						<Col className="col-6 my-2 my-md-0 col-md-3 order-4 order-md-2 text-center">
							{autoCaptures.length > 0 ? (
								<h1>{autoCaptures.length}</h1>
							) : (
								<h1>
									<Skeleton width={40} />
								</h1>
							)}
							<p>captures eligible for Auto QC</p>
						</Col>
					</div>

					{/* <div className="row mt-3">
						<div className="col-12 mt-2 mt-sm-0 col-sm-6 d-flex justify-content-center justify-content-md-end">
							<button
								className="btn btn-outline-secondary btn-sm dropdown-toggle"
								type="button"
								disabled
							>
								Captures criteria
							</button>
						</div>

						<div
							className="col-12 mt-2 mt-sm-0 col-sm-6 d-flex justify-content-center justify-content-md-start"
							//style={{ cursor: 'not-allowed' }}
							title="Auto QC not available currently"
						>
							<button
								className="btn btn-outline-secondary btn-sm dropdown-toggle"
								type="button"
							>
								Set auto QC params
							</button>
						</div>
					</div> */}

					<>
						<div className="row mt-4">
							{capsInPipeline.length > 0 ? (
								<h4 className="text-secondary">0 captures to QC</h4>
							) : (
								<Col className="d-flex justify-content-start">
									<h4 className="text-secondary">
										<Skeleton width={50} />
									</h4>
								</Col>
							)}

							{renderSkeletonTable()}
						</div>

						<div className="row py-4">
							{autoCaptures.length > 0 ? (
								<h4 className="text-secondary">0 captures to Auto QC</h4>
							) : (
								<Col className="d-flex justify-content-start">
									<h4 className="text-secondary">
										<Skeleton width={50} />
									</h4>
								</Col>
							)}
							{/* <h4 className="text-secondary">0 captures to QC</h4> */}
							{renderSkeletonTable()}
						</div>
					</>
				</div>
			</div>
		);
	}

	if (capsInPipeline) {
		if (capsInPipeline.length > 0) {
			capsInPipeline.map((obj) => {
				obj.capturedDate = obj.captured.slice(0, 10);
				obj.capturedTime = obj.captured.slice(11, 19);

				// this fails because invalid data frac is sometimes NULL
				if (comm === 'smelters') {
					if (obj.invalid_data_frac !== null) {
						obj.invalid_data_frac = parseFloat(
							obj.invalid_data_frac.toFixed(2)
						);
					}
					if (obj.cloud_frac !== null) {
						obj.cloud_frac = parseFloat(obj.cloud_frac.toFixed(2));
					}

					if (obj.max_band_ratio !== null) {
						obj.max_band_ratio = parseFloat(obj.max_band_ratio.toFixed(2));
					}
				}

				return obj;
			});
		}
	}

	return (
		<div>
			<hr></hr>
			<div className="px-3 mx-3">
				<div className="row">
					<div className="col-6 my-2 my-md-0 col-md-3 order-1 order-md-6 d-flex justify-content-start">
						{straightToQC ? (
							<div className="d-flex flex-row align-items-center">
								<button
									className="btn interactButtonSecondary"
									style={{ maxHeight: '40px', width: '200px' }}
									disabled
								>
									<i class="fa-solid fa-left-long pe-3"></i>
									Back to form
								</button>
								<h1 className="ms-5 mt-2">QC</h1>
							</div>
						) : (
							<div className="d-flex flex-row align-items-center">
								<button
									className={
										capsInPipeline.length > 0
											? 'btn interactButtonSecondary'
											: 'btn interactButton'
									}
									style={{ maxHeight: '40px', width: '200px' }}
									onClick={(e) => resetCapsAndGoBackToForms(qcType)}
									data-tooltip-id={!fetchCapturesError && "regress-to-form-reset-caps"}
								>
									<i class="fa-solid fa-left-long pe-3"></i>
									Back to form
								</button>
								<h1 className="ms-5 mt-2">QC</h1>
							</div>
						)}
					</div>

					<Tooltip
							id="regress-to-form-reset-caps"
							place="bottom"
							effect="solid"
						>
							Send back to the database the {capsInPipeline.length} captures
							assigned to you and navigate back to the form
						</Tooltip>

					{fetchCapturesError ? (
						<div className="col-6 my-2 my-md-0 order-3 order-md-1 text-center">
							<h5 className="text-center text-warning mt-4">
							<i className="fa-solid fa-triangle-exclamation me-2"></i> Error fetching captures! Please try again later.
							</h5>
							{/* <p className="text-center">
								
								{fetchCapturesError.status}
								{fetchCapturesError.message}
							</p>

							<p className="text-center">
								Failed URL string:{' '}
								{fetchCapturesError.url.split('/').slice(-1)[0]}
							</p> */}
						</div>
					) : (
						<>
							<div className="col-6 my-2 my-md-0 col-md-3 order-2 order-md-5 offset-md-6 d-flex justify-content-end align-items-center">
								{capsInPipeline.length > 0 && (
									<>
										<button
											data-tooltip-id="autoQCCaptureTooltip"
											className="btn interactButton"
											style={{ maxHeight: '40px', width: '200px' }}
											//onClick={(e) => moveToQC(captures, autoCaptures)}
											onClick={movingToQC}
										>
											QC {capsInPipeline.length} captures{' '}
											<i className="fa-solid fa-right-long ps-1 ps-md-3"></i>
										</button>

										<Tooltip
											id="autoQCCaptureTooltip"
											place="bottom"
											effect="solid"
										>
											and send {autoCaptures.length} captures to auto QC
										</Tooltip>
									</>
								)}
							</div>
						</>
					)}
				</div>

				
				<h4 className="py-1 py-md-3">{capsInPipeline.length > 0 && 'Review the captures to QC'}</h4>

				<div className="row mt-2">
					<div className="col-6 my-2 my-md-0 col-md-3 offset-md-3 order-3 order-md-1 text-center">
						<h1>{capsInPipeline.length}</h1>
						<p>captures to QC</p>
					</div>
					<div className="col-6 my-2 my-md-0 col-md-3 order-4 order-md-2 text-center">
						<h1>{autoCaptures.length}</h1>
						<p>captures eligible for Auto QC</p>
					</div>
				</div>

				{capsInPipeline.length === 0 &&
					autoCaptures.length === 0 &&
					!loading &&
					!fetchCapturesError && (
						<Row>
							<h5 className="text-center mt-4">
								There are no captures which meet these criteria.
							</h5>
						</Row>
					)}

				{/* <div className="row mt-md-3">
					<div className="col-12 mt-2 mt-sm-0 col-sm-6 d-flex justify-content-center justify-content-md-end">
						<button
							className="btn btn-outline-secondary btn-sm dropdown-toggle"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseFormValues"
							aria-expanded="false"
							aria-controls="collapseFormValues"
						>
							Captures criteria
						</button>
					</div>

					<div
						className="col-12 mt-2 mt-sm-0 col-sm-6 d-flex justify-content-center justify-content-md-start"
						title="Auto QC not available currently"
					>
						<button
							className="btn btn-outline-secondary btn-sm dropdown-toggle"
							type="button"
							// data-bs-toggle="collapse"
							// data-bs-target="#collapseAutoQC"
							// aria-expanded="false"
							// aria-controls="collapseAutoQC"
						>
							Set auto QC params
						</button>
					</div>
				</div> */}

				<div className="collapse" id="collapseFormValues">
					<Row>
						<Col></Col>
						<Col className="col-8">
							<form>
								<fieldset className="form-group p-3 border rounded-3">
									<legend className="float-none w-auto px-3">
										Captures criteria
									</legend>

									{/* INTERVAL */}
									<Row
										className="d-flex justify-content-start
							"
									>
										<Col className="col-3">
											<label
												htmlFor="captures-criteria-s_date"
												className="form-check-label"
											>
												Interval
											</label>
										</Col>
										<Col className="col-1 d-flex justify-content-start">
											<label
												htmlFor="captures-criteria-s_date"
												className="form-check-label"
											>
												from
											</label>
										</Col>
										<Col className="col-3 d-flex justify-content-start">
											<input
												type="date"
												disabled
												id="captures-criteria-s_date"
												value={formValue.formStartDate}
												className="form-control"
											></input>
										</Col>
										<Col className="col-1 d-flex justify-content-start">
											<label
												htmlFor="captures-criteria-e_date"
												className="form-check-label"
											>
												to
											</label>
										</Col>
										<Col className="col-3 d-flex justify-content-start">
											<input
												type="date"
												disabled
												id="captures-criteria-e_date"
												value={formValue.formEndDate}
												className="form-control"
											></input>
										</Col>
									</Row>

									{/* SENSORS */}
									<Row className="py-2">
										<Col className="col-3">
											<label htmlFor="customRange1" className="form-label">
												Sensors
											</label>
										</Col>
										<Col className="col-3 d-flex justify-content-start">
											<label className="form-check-label pe-3">
												landsat-8-l1tp
											</label>
											{formValue.formSensors.includes('landsat-8-l1tp') && (
												<input
													type="checkbox"
													className="form-check-input"
													id="captures-criteria-sensors-landsat"
													checked
													disabled
												></input>
											)}
											{!formValue.formSensors.includes('landsat-8-l1tp') && (
												<input
													type="checkbox"
													className="form-check-input"
													id="captures-criteria-sensors-landsat"
													checked
													disabled
												></input>
											)}
										</Col>
										<Col className="col-3 d-flex justify-content-start">
											<label className="form-check-label pe-3">
												sentinel-2-l1c
											</label>
											{formValue.formSensors.includes('sentinel-2-l1c') && (
												<input
													type="checkbox"
													className="form-check-input"
													id="captures-criteria-sensors-sentinel"
													checked
													disabled
												></input>
											)}
											{!formValue.formSensors.includes('sentinel-2-l1c') && (
												<input
													type="checkbox"
													className="form-check-input"
													id="captures-criteria-sensors-sentinel"
													checked
													disabled
												></input>
											)}
										</Col>
									</Row>

									{/* COMMODITIES */}
									<Row className="py-2">
										<Col className="col-3">
											<label htmlFor="customRange1" className="form-label">
												Commodities
											</label>
										</Col>
										<Col className="col-9">
											{config.commodities.map((comm, idx) => (
												<>
													<label
														htmlFor={comm.class}
														className="form-check-label pe-2"
													>
														{comm.commodity}
													</label>
													{formValue.formCommodities.includes(comm.index) ? (
														<input
															id={comm.class}
															className="form-check-input me-3"
															type="checkbox"
															checked
															disabled
														></input>
													) : (
														<input
															id={comm.class}
															className="form-check-input me-3"
															type="checkbox"
															disabled
														></input>
													)}
												</>
											))}
										</Col>
									</Row>

									{/* PRODUCTION STATE */}
									<Row className="py-2">
										<Col className="col-3">
											<label htmlFor="customRange1" className="form-label">
												Production States
											</label>
										</Col>

										<Col className="col-9">
											{config.production_states.map((state, idx) => (
												<>
													<label
														htmlFor={state.state}
														className="form-check-label pe-2"
														title={state.descript}
													>
														{state.state}
													</label>
													{formValue.formProdStates.includes(state.state) && (
														<input
															id={state.state}
															className="form-check-input me-3"
															type="checkbox"
															disabled
															checked
														></input>
													)}
													{!formValue.formProdStates.includes(state.state) && (
														<input
															id={state.state}
															className="form-check-input me-3"
															type="checkbox"
															disabled
														></input>
													)}
												</>
											))}
										</Col>
									</Row>
								</fieldset>
							</form>
						</Col>
						<Col></Col>
					</Row>
				</div>

				<div className="collapse" id="collapseAutoQC">
					<Row>
						<Col></Col>
						<Col className="col-8">
							<form>
								<fieldset className="form-group p-3 border rounded-3">
									<legend className="float-none w-auto px-3">
										Set auto-qc parameters
									</legend>
									<Row className="pb-2">
										<Col className="col-6">Auto QC sites with status</Col>
										<Col className="col-3">
											<input
												type="checkbox"
												id="autoqc-invalid"
												className="form-check-input"
												name="autoQCFilterStatusInvalid"
												onChange={(e) =>
													setFilters({
														...filters,
														autoQCFilterStatusInvalid: e.target.checked
													})
												}
											></input>
											<label
												for="autoqc-invalid"
												className="form-check-label ps-2"
											>
												invalid
											</label>
										</Col>
										<Col className="col-3">
											<input
												type="checkbox"
												id="autoqc-inactive"
												className="form-check-input"
												name="autoQCFilterStatusInactive"
												onChange={(e) =>
													setFilters({
														...filters,
														autoQCFilterStatusInactive: e.target.checked
													})
												}
											></input>
											<label
												for="autoqc-inactive"
												className="form-check-label ps-2"
											>
												inactive
											</label>
										</Col>
									</Row>
									<Row className="py-2">
										<Col className="col-4">
											<label for="customRange1" class="form-label">
												Cloud Ratio Threshold
											</label>
										</Col>
										<Col className="col-6">
											<input
												type="range"
												className="form-range"
												id="cloud-ratio"
												name="autoQCFilterCR"
												min="0"
												max="100"
												step="5"
												defaultValue="95"
												onChange={(e) =>
													setFilters({
														...filters,
														autoQCFilterCR: e.target.value
													})
												}
											></input>
										</Col>
										<Col className="col-2 d-flex justify-content-center">
											<input
												className="form-control p-0 text-center text-small"
												type="number"
												//defaultValue={95}
												value={filters.autoQCFilterCR}
												disabled
												style={{ width: '50%', marginRight: '5px' }}
												id="cloud-ratio-output"
												//name="autoQCFilterMinSwath"
											></input>
											%
										</Col>
									</Row>

									<Row className="py-2">
										<Col className="col-4">
											<label for="customRange1" className="form-label">
												Min out of swath % area
											</label>
										</Col>
										<Col className="col-6">
											<input
												className="form-range"
												type="range"
												id="min-swath"
												min="0"
												max="100"
												step="5"
												defaultValue="15"
												name="autoQCFilterMinSwath"
												onChange={(e) =>
													setFilters({
														...filters,
														autoQCFilterMinSwath: e.target.value
													})
												}
											></input>
										</Col>
										<Col className="col-2 d-flex justify-content-center">
											<input
												type="number"
												className="form-control p-0 text-center"
												min="0"
												max="100"
												step="5"
												//defaultValue={15}
												value={filters.autoQCFilterMinSwath}
												disabled
												style={{ width: '50%', marginRight: '5px' }}
												id="min-swath-output"
											></input>
											%
										</Col>
									</Row>

									<Row>
										<Col>
											<label>12/11 band ratio</label>
										</Col>
										<Col className="d-flex justify-content-center">
											<input
												className="form-control p-0 text-center"
												type="number"
												id="12-11-br-invalid"
												style={{ width: '30%' }}
												// defaultValue={1}
												value={filters.autoQCFilter1211Invalid}
												min={0}
												max={5}
												step={0.1}
												name="autoQCFilter1211Invalid"
												onChange={(e) =>
													setFilters({
														...filters,
														autoQCFilter1211Invalid: e.target.value
													})
												}
												//onChange={(e) => this.handleSliderChange(e)}
											></input>
											<label className="form-label ms-2" for="12-11-br-invalid">
												invalid
											</label>
										</Col>
										<Col className="d-flex justify-content-center">
											<input
												className="form-control p-0 text-center"
												type="number"
												id="12-11-br-active"
												style={{ width: '30%' }}
												// defaultValue={2.3}
												value={filters.autoQCFilter1211Active}
												min={0}
												max={5}
												step={0.1}
												//onChange={(e) => this.handleSliderChange(e)}
												name="autoQCFilter1211Active"
												onChange={(e) =>
													setFilters({
														...filters,
														autoQCFilter1211Active: e.target.value
													})
												}
											></input>
											<label className="form-label ms-2" for="12-11-br-active">
												active
											</label>
										</Col>
									</Row>

									{/* <Row>
									<Col className="col-3">
										<label for="customRange1" className="form-label">
											Smelters
										</label>
									</Col>
									<Col className="col-9">
										<select id="example-getting-started" multiple="multiple">
											<option value="cheese">Cheese</option>
											<option value="tomatoes">Tomatoes</option>
											<option value="mozarella">Mozzarella</option>
											<option value="mushrooms">Mushrooms</option>
											<option value="pepperoni">Pepperoni</option>
											<option value="onions">Onions</option>
										</select>
									</Col>
								</Row> */}
								</fieldset>
							</form>
						</Col>
						<Col></Col>
					</Row>
				</div>

				{capsInPipeline.length === 0 && autoCaptures.length === 0 ? (
					<></>
				) : (
					<>
						<Row className="py-4">
							<h4>{capsInPipeline.length} captures to QC</h4>
							{capsInPipeline.length > 0 ? (
								<div className="table-responsive">
									{comm === 'smelters' && (
										<CapturesTable
											fields={smelterFields}
											captures={capsInPipeline}
											qcCat="smelters"
											tableHeight="600px"
										/>
									)}

									{comm === 'stockpiles' && (
										<CapturesTable
											fields={stockpileFields}
											captures={capsInPipeline}
											qcCat="stockpiles"
											tableHeight="600px"
										/>
									)}
								</div>
							) : (
								<h5>No captures</h5>
							)}
						</Row>

						{/* <Row className="py-4">
							<h4 className="text-secondary">
								{autoCaptures.length} captures to Auto QC
							</h4>
							{autoCaptures.length > 0 ? (
								<div
									className="table-responsive"
									style={{ maxHeight: '400px' }}
								>
									<table className="table table-hover">
										<thead className="table-light">
											<tr>
												<th scope="col">ID</th>
												<th scope="col">Capture Date</th>
												<th scope="col">Capture Time</th>
												<th scope="col">Site</th>
												<th scope="col">Sensor</th>
												<th scope="col">Site ID</th>
												<th scope="col">Capacity</th>
												<th scope="col">Initial State</th>
												<th scope="col">State</th>
												<th scope="col">Hotspots</th>
												<th scope="col">Invalid Data Frac</th>
												<th scope="col">Cloud Frac</th>
												<th scope="col">Max Band Ratio</th>
											</tr>
										</thead>
										<tbody className="table-group-divider">
											{autoCaptures.map((capture, idx) => (
												<tr key={idx}>
													<td>{capture.id}</td>
													<td>{capture.capturedDate}</td>
													<td>{capture.capturedTime}</td>
													<td>{capture.site_name}</td>
													<td>{capture.sensor}</td>
													<td>{capture.site_id}</td>
													<td>{capture.capacity}</td>
													<td>{capture.initial_state}</td>

													{(capture.state === 0 &&
														filters.autoQCFilterStatusInvalid) ||
													(capture.state === -1 &&
														filters.autoQCFilterStatusInactive) ? (
														<td
															title={`Eligible for auto-qc because state (${capture.state}) is INVALID (0) or INACTIVE (-1)`}
															className="fw-bold"
														>
															{capture.state}
														</td>
													) : (
														<td>{capture.state}</td>
													)}

													<td>{capture.hotspots}</td>

													{capture.cloud_frac >=
													filters.autoQCFilterCR / 100 ? (
														<td
															title={`Eligible for auto-qc because cloud ratio (${
																capture.cloud_frac
															}) is bigger than or equal to ${
																filters.autoQCFilterCR / 100
															}`}
															className="fw-bold"
														>
															{capture.cloud_frac}
														</td>
													) : (
														<td>{capture.cloud_frac}</td>
													)}
													{capture.valid_data_frac >=
													filters.autoQCFilterMinSwath / 100 ? (
														<td
															title={`Eligible for auto-qc because cloud ratio (${
																capture.valid_data_frac
															}) is bigger than or equal to ${
																filters.autoQCFilterMinSwath / 100
															}`}
															className="fw-bold"
														>
															{capture.valid_data_frac}
														</td>
													) : (
														<td>{capture.valid_data_frac}</td>
													)}

													{(capture.max_band_ratio >
														filters.autoQCFilter1211Active / 100 &&
														capture.initial_state === 1) ||
													(capture.max_band_ratio >
														filters.autoQCFilter1211Invalid / 100 &&
														capture.initial_state === 0) ? (
														<td
															title={`Eligible for auto-qc because max band ratio (${capture.max_band_ratio}) is bigger than ${filters.autoQCFilter1211Active} AND the initial state (${capture.initial_state}) is active (1) OR because max band ratio (${capture.max_band_ratio}) is bigger than ${filters.autoQCFilter1211Invalid} AND the initial state (${capture.initial_state}) is invalid (0)`}
															className="fw-bold"
														>
															{capture.max_band_ratio}
														</td>
													) : (
														<td>{capture.max_band_ratio}</td>
													)}

													
												</tr>
											))}
										</tbody>
									</table>
								</div>
							) : (
								<h5 className="text-secondary">
									Auto QC currently not available
								</h5>
								
							)}
						</Row> */}
					</>
				)}
			</div>
		</div>
	);
};

export default QCCaptures;
