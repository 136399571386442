import React, { useEffect, useContext } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-rastercoords';
import 'leaflet-easybutton/src/easy-button.css';
import 'leaflet-easybutton';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';
import { pipelineContext } from '../../../contexts/pipelineContext';

// Override Leaflet deprecated _flat method with the recommended isFlat method
L.Polyline._flat = L.LineUtil.isFlat;

export const QCImageSmelter = ({
	imageData,
	modalId,
	imageUrl,
	openModal,
}) => {

    const pipelineContextQCImage = useContext(pipelineContext);

	useEffect(() => {
		if (pipelineContextQCImage.comm === 'smelters' && imageData && imageData.data) {
			let modalIdName = `#${modalId}`;
			const mapContainerId = `map-${modalId}`;
			//var map;

			// AOI
			if (imageData.data.aoi) {
			let aoi = JSON.parse(imageData.data.aoi);
			var geojsonFeature = {
				type: 'Feature',
				properties: {},
				geometry: {
					type: 'Polygon',
					coordinates: aoi.coordinates[0]
				}
			};

			var aoiLayer = L.geoJSON(geojsonFeature);
			aoiLayer.setStyle({
				color: 'yellow',
				weight: 3,
				fillOpacity: 0,
				opacity: 0.5
			});
			var bounds = aoiLayer.getBounds();
		}


			// PERMS
			let perms = [];
			if (imageData.data.perm_regions) {
				try {
					let permsJson = JSON.parse(imageData.data.perm_regions);

					for (let i in permsJson.features) {
						let geojsonPerms = {
							type: 'Feature',
							properties: {},
							geometry: {
								type: 'Polygon',
								coordinates: permsJson.features[i].geometry.coordinates
							}
						};
						let permLayer = L.geoJSON(geojsonPerms);
						permLayer.setStyle({
							color: 'orange',
							weight: 1,
							fillOpacity: 0
						});
						perms.push(permLayer);
					}
				} catch (e) {
					console.log(e);
				}
			}

			// HOTSPOTS
			let hotspotsList = [];
			if (imageData.data.hotspots && modalId === 'id1' ) {
				let hotspotsJson = JSON.parse(imageData.data.hotspots);

				for (let i in hotspotsJson.features) {
					let geojsonPerms = {
						type: 'Feature',
						properties: {},
						geometry: {
							type: 'Polygon',
							coordinates: hotspotsJson.features[i].geometry.coordinates
						}
					};
					let hotspotsLayer = L.geoJSON(geojsonPerms);
					hotspotsLayer.setStyle({
						color: 'red',
						weight: 1,
						fillOpacity: 1
					});
					hotspotsList.push(hotspotsLayer);
				}
			}

			// create map
			var map = L.map(mapContainerId, {
				zoomControl: false
				//maxBounds: bounds,
				//maxBoundsViscosity: 1.0
			});

			map.setMaxBounds(bounds);

			// add layers to map
			aoiLayer.addTo(map);
			if (imageData.data.perm_regions) {
				for (let i in perms) {
					perms[i].addTo(map);
				}
			}

			if (imageData.data.hotspots) {
				for (let i in hotspotsList) {
					hotspotsList[i].addTo(map);
				}
			}

			aoiLayer
				.bindTooltip('site aoi', {
					direction: 'top'
				})
				.openTooltip();

			///console.log(bounds)

			const recenterButton = L.easyButton('fa-crosshairs', function () {
				map.setView([0, 0], 13);
			}).addTo(map);

			const imgUrl = imageUrl;
			var imageBounds = [
				[imageData.data.bbox[1], imageData.data.bbox[0]],
				[imageData.data.bbox[3], imageData.data.bbox[2]]
			];

			// Add the image overlay
			const imageOverlay = L.imageOverlay(imgUrl, imageBounds).addTo(map);

			// Add a tile layer (e.g., OpenStreetMap)
			var mapLayer = L.tileLayer(
				'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
				{
					lang: 'en',
					opacity: 0.2
				}
			).addTo(map);
			mapLayer.addTo(map);

			imageOverlay.on('error', (error) => {
				console.error('Error loading image:', error);
			});

			var permGroups = L.layerGroup(perms);
			var hotspotsGroup = L.layerGroup(hotspotsList);
			var baseLayers = {
				Hotspots: hotspotsGroup,
				AOI: aoiLayer,
				'Perm Regions': permGroups
			};

			//map.setView([0, 0], 13);
			map.fitBounds(bounds, { padding: [0, 0] });

			return () => {
				map.remove();
			};
		}
	}, [imageUrl, openModal, pipelineContextQCImage.comm, imageData]);

	return (
		<div className="QCImage-map-general-div-smelters">
			<div className="QCImage-map-absolute-div">
				<div
					className="QCImage-leaflet-map-smelters"
					id={`map-${modalId}`}
				></div>
			</div>
		</div>
	);
};
