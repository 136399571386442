import React, { useEffect, useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { endpointContext } from '../../contexts/endpointContext';
import { pipelineContext } from '../../contexts/pipelineContext';
import QCActivityGraph from '../Dashboard/QCActivityGraph';
import exportConfig from '../../config';
import CurrentlyQCingUser from '../Dashboard/CurrentlyQCingUser';
import { DataLoaded } from '../Dashboard/DataLoaded';
import { AvailableCaps } from '../Dashboard/AvailableCaps';
import { PerformedQCs } from '../Dashboard/PerformedQCs';
import useGainsQCApi from '../../services/useGainsQCApi';

export default function Dashboard(props) {
	//const endpointContextDashboard = useContext(endpointContext);
	const pipelineContextDashboard = useContext(pipelineContext);

	const [removeCapsList, setRemoveCapsList] = useState();

	const removeCapsFromQueue = useGainsQCApi();

	useEffect(() => {
		pipelineContextDashboard.setNavigateHome(false);
		document.title = 'GAINS QC | Home';
	}, []);

	const sendCapsBack = async () => {
		await removeCapsFromQueue.makeRequest(
			`${exportConfig.baseUrl}/smelters/remove_from_queue`,
			'POST',
			{
				commodityIds: '3',
				qcUser: 'lucy.colley',
				captureIds: removeCapsList
			},
			`sending ${removeCapsList.length} captures back to database`
		);
	};

	// useEffect(() => {
	// 	try {
	// 		endpointContextDashboard.refresh();
	// 	} catch (e) {
	// 		console.log(e);
	// 	}
	// }, []);

	const sumNumbers = (obj) => {
		let sum = 0;
		for (let key in obj) {
			if (typeof obj[key] === 'number') {
				sum += obj[key];
			} else if (typeof obj[key] === 'object' && obj[key] !== null) {
				sum += sumNumbers(obj[key]); // Recursively sum nested objects
			}
		}
		return sum;
	};

	// useEffect(() => {
	// 	try {
	// 		endpointContextDashboard.refresh();
	// 	} catch (e) {
	// 		console.log(e);
	// 	}
	// }, [props.authProps.environment, qcInProgress]);

	return (
		<>
			{props.authProps.isAuthenticated ? (
				<>
					<div className="px-0 col" id="secondColumn">
						<div className="contentContainer">
							<div style={{ marginTop: '58px' }}>
								<hr className="container-hr"></hr>

								{/* <SmelterInfoModal 
								data={makeRequestSmelters.data}
								 /> */}

								<h3>
									Welcome to QC{' '}
									<strong>{props.authProps.userAttributes.givenName}</strong>
								</h3>

								<div className="row">
									<div className="col col-md-6">
										<h6>
											Welcome to the Earth-i QC Tool, where you can find
											information about all captures in the database and perform
											a Quality Control inspection for a given set of captures.{' '}
										</h6>
									</div>
								</div>
{/* 
								<div className="row">
									<div className="col-3">
										{' '}
										<input
											type="text"
											value={removeCapsList}
											onChange={(e) => setRemoveCapsList(e.target.value)}
										></input>
										<button
											className="btn btn-secondary"
											onClick={(e) => sendCapsBack()}
										>
											Remove from queue
										</button>
									</div>
								</div> */}

								{/* <div className="row">
									<div className="col-8">
										<div className="row my-4" style={{ height: 'auto' }}>
											<div className="col-12 col-md-6 col-xl-4">
												<AvailableCaps
													lastQC={'42 mins ago'}
													siteType={'Smelter Captures'}
													siteIcon={
														exportConfig.siteTypesIds.filter(
															(siteType) => siteType.id === 1
														)[0].icon
													}
													loading={endpointContextDashboard.loading}
													error={endpointContextDashboard.error}
													data={
														endpointContextDashboard.dbData
															.availableCapsSmelters
													}
												/>
											</div>

											<div className="col-12 col-md-6 col-xl-4">
												<AvailableCaps
													lastQC={'4 hours ago'}
													siteType={'Stockpile Captures'}
													siteIcon={
														exportConfig.siteTypesIds.filter(
															(siteType) => siteType.id === 3
														)[0].icon
													}
													loading={endpointContextDashboard.loading}
													error={endpointContextDashboard.error}
													data={
														endpointContextDashboard.dbData
															.availableCapsStockpiles
													}
												/>
											</div>

											<div className="col-12 col-md-6 col-xl-4">
												<AvailableCaps
													lastQC="11 days ago"
													siteType={'Airfields'}
													siteIcon={'fa-solid fa-city me-2'}
													loading={endpointContextDashboard.loading}
													error={endpointContextDashboard.error}
													data={{
														0: { aircraft: 98 },
														3: {
															aircraft: {
																'lucy.colley': 23,
																'george.begkas': 2
															}
														},
														4: { aircraft: { 'lucy.colley': 1 } },
														1: {}
													}}
												/>
											</div>
										</div>

										<div className="row my-4" style={{ height: 'auto' }}>
											<div className="col-12">
												<div className="dashboard-div p-3">
													<h6 className="fw-bold">Number of captures QC'd</h6>
													<QCActivityGraph />
												</div>
											</div>
										</div>
									</div>
									<div className="col-4">
										<div className="row my-4" style={{ height: 'auto' }}>
											<div className="col-12">
												<DataLoaded
													loading={endpointContextDashboard.loading}
													error={endpointContextDashboard.error}
													data={endpointContextDashboard.dbData}
													timeNow={endpointContextDashboard.dbData.lastUpdate}
												/>
											</div>
										</div>

										<div className="row my-4" style={{ height: 'auto' }}>
											<div className="col-12">
												<div class="dashboard-card">
													<div
														class="dashboard-card-header"
														data-tooltip-id="dashboard-system-status"
													>
														<div className="row">
															<div className="col">
															<i className="fa-solid fa-link me-2"></i> Useful
														links
															</div>
															<div className="col d-flex justify-content-end">
																
															</div>
														</div>
													</div>
													<div class="dashboard-card-body">
													<div className="table my-0 py-0">
														<table className="table dashboard-stats-table my-0">
															<tr>
																<td></td>
																<td>Gains Navigate</td>
															</tr>

															<tr>
																<td></td>
																<td>Gains Admin Tool</td>
															</tr>

															<tr>
																<td></td>
																<td>Savant QC</td>
															</tr>

															<tr>
																<td></td>
																<td>Sentinel Hub</td>
															</tr>
														</table>
													</div>
													</div>
												</div>

												
											</div>
										</div>

										<div className="row my-4" style={{ height: 'auto' }}>
											<div className="col-12">
												<PerformedQCs />
											</div>
										</div>

										<div className="row my-4" style={{ height: 'auto' }}>
											<div className="col-12">
												<CurrentlyQCingUser
													user={props.authProps.userAttributes}
													loading={endpointContextDashboard.loading}
													error={endpointContextDashboard.error}
													data={endpointContextDashboard.dbData}
												/>
											</div>
										</div>

										<div className="row my-4" style={{ height: '45vh' }}>
											<div className="col-12">
												<div className="dashboard-div p-3">
													<h6 className="fw-bold">
														Recent Activity
														<span className="fw-light"> | today</span>
													</h6>

													<div className="table">
														<table className="table dashboard-stats-table">
															<tr>
																<td>32 mins</td>
																<td className="truncate">
																	Madiha uploaded 46 smelter captures
																</td>
															</tr>

															<tr>
																<td>2 hours</td>
																<td className="truncate">
																	Madiha QC'd 46 smelter captures
																</td>
															</tr>

															<tr>
																<td>4 hours</td>
																<td className="truncate">
																	George QC'd 22 stockpiles captures
																</td>
															</tr>

															<tr>
																<td>1 day</td>
																<td className="truncate">
																	Peter QC'd 134 smelter captures
																</td>
															</tr>

															<tr>
																<td>1 day</td>
																<td className="truncate">
																	Josh uploaded 78 stockpiles captures
																</td>
															</tr>

															<tr>
																<td>1 day</td>
																<td className="truncate">
																	Josh QC'd 78 stockpiles captures
																</td>
															</tr>

															<tr>
																<td>2 days</td>
																<td className="truncate">
																	Guy requested Re-QC for 4 smelter captures
																</td>
															</tr>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div> */}
							</div>
						</div>
					</div>
				</>
			) : (
				<Navigate to="/login" />
			)}
		</>
	);
}
