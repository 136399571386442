import React, { useContext, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import StockpilesTableBasic from '../../StockpilesTableBasic';
import SmeltersTableBasic from '../../SmeltersTableBasic';
import { pipelineContext } from '../../../contexts/pipelineContext';
import useGainsQCApi from '../../../services/useGainsQCApi';
import exportConfig from '../../../config';

const QCFinishedModal = ({ props, commodity }) => {
	const [capturesQCd, setCapturesQCd] = useState([]);
	const [capturesToReset, setCapturesToReset] = useState([]);

	const {
		comm,
		capsInPipeline,
		currentCaptureIndex,
		setProgressBar,
		straightToUpload,
		qcPerc
	} = useContext(pipelineContext);

	const { error: errorSendingCapsBack, makeRequest: sendCapsBack } =
		useGainsQCApi();

	useEffect(() => {
		if (!straightToUpload) {
			setCapturesToReset(capsInPipeline.filter((cap) => cap.qc_state === 0));
			setCapturesQCd(capsInPipeline.filter((cap) => cap.qc_state !== 0));
		} else {
			setCapturesQCd(capsInPipeline);
		}
	}, [capsInPipeline, currentCaptureIndex, qcPerc]);

	const sendCapsToPostQC = () => {
		console.log('sending to post QC');
		// send back reset caps too

		let user = props.authProps.userAttributes
		let caps = capturesToReset.map((cap) => cap.id);

		sendCapsBack(
			`${exportConfig.baseUrl}/${comm}/remove_from_queue`,
			'POST',
			{
				commodityIds: `${commodity.join(',')}`,
				qcUser: `${user.givenName.toLowerCase()}.${user.familyName.toLowerCase()}`,
				captureIds: caps.join(',')
			},
			'sending caps back'
		);
	};

	const returnHome = () => {
		console.log('return home');
	};

	return (
		<div
			className="modal modal-xl fade"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			id="moveToUploadModal"
			tabindex="-1"
			aria-labelledby="moveToUploadModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="moveToUploadModalLabel">
							Finish QC and move to Upload
						</h5>
					</div>

					<div
						class="modal-body"
						style={{ maxHeight: '600px', overflowY: 'auto' }}
					>
						{capsInPipeline.length > 0 && (
							<>
								{capturesQCd.length > 0 && (
									<>
										<h5>QC'd ({capturesQCd.length})</h5>

										<p>
											<small>
												Post-QC will be performed on the captures that have been
												QC'd and they will be available to upload.<br></br>{' '}
												Captures not QC'd will be removed from your pipeline
												upon completion of QC.
											</small>
										</p>

										{comm === 'stockpiles' && (
											<StockpilesTableBasic
												props={props}
												captures={capturesQCd}
												currentCaptureIndex={currentCaptureIndex}
											/>
										)}

										{comm === 'smelters' && (
											<SmeltersTableBasic
												props={props}
												captures={capturesQCd}
												currentCaptureIndex={currentCaptureIndex}
											/>
										)}
									</>
								)}

								{/* {capturesToReset.length > 0 && (
									<>
										<h5>Not QC'd ({capturesToReset.length})</h5>
										<p>
											<small>
												These captures are still in your pipeline and not QC'd.
												<br></br> If you don't return to QC and complete them,
												they will be removed from your pipeline.
											</small>
										</p>
										{comm === 'stockpiles' && (
											<StockpilesTableBasic
												props={props}
												captures={capturesToReset}
												currentCaptureIndex={currentCaptureIndex}
												postQCPerformed={false}
												uploaded={false}
											/>
										)}

										{comm === 'smelters' && (
											<SmeltersTableBasic
												props={props}
												captures={capturesToReset}
												currentCaptureIndex={currentCaptureIndex}
											/>
										)}
									</>
								)} */}
							</>
						)}
					</div>
					<div class="modal-footer">
						{!straightToUpload ? (
							<>
								<button
									type="button"
									className="btn interactButtonSecondary"
									data-bs-dismiss="modal"
								>
									Continue with QC
								</button>

								<button
									type="button"
									className="btn interactButton"
									data-bs-dismiss="modal"
									data-tooltip-id="moveToUploadPageTT"
									onClick={(e) =>
										setProgressBar({
											width: 6 * (100 / 7),
											title: 'Upload Captures'
										})
									}
								>
									Move To Upload <i class="ps-2 fa-solid fa-upload"></i>
								</button>
								<Tooltip id="moveToUploadPageTT" place="right" effect="solid">
									finish QCing and move to upload page
								</Tooltip>
							</>
						) : (
							<>
								<button
									type="button"
									className="btn btn-danger"
									data-bs-dismiss="modal"
									data-tooltip-id="exitQCOnFinishedModal"
									onClick={(e) => returnHome()}
								>
									Exit
								</button>
								<Tooltip
									id="exitQCOnFinishedModal"
									place="right"
									effect="solid"
								>
									Exit without performing Post-QC or uploading and return to
									home
								</Tooltip>
								<button
									type="button"
									className="btn interactButton"
									data-bs-dismiss="modal"
									data-tooltip-id="sendToPostQCTT"
									onClick={(e) => sendCapsToPostQC()}
								>
									Send to post-qc
								</button>
								<Tooltip id="sendToPostQCTT" place="right" effect="solid">
									Send all captures to post-qc
								</Tooltip>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default QCFinishedModal;
