import React, { useContext, useEffect, useState } from 'react';
import config from '../../../config';
import { Tooltip } from 'react-tooltip';
import divideReturnQCList from '../../../helpers/divideReturnQCList';
import StockpilesTableBasic from '../../StockpilesTableBasic';
import SmeltersTableBasic from '../../SmeltersTableBasic';
import { pipelineContext } from '../../../contexts/pipelineContext';
import useGainsQCApi from '../../../services/useGainsQCApi';
import exportConfig from '../../../config';

// Clear Title:  Are you sure you want to exit?
// Description of the Current Process: No items QC'd, but caps in pipeline.  Items QC'd, QC not finished
// Consequences of Exiting: Caps removed from pipeline, directed to homepage
// Options for Saving Progress (if applicable) - NA
// Actionable Buttons - Exit QC/ Continue QC
// Optional Warning for Irreversible Actions
// Visual Cues - eg warning icon

const ExitQCModal = ({ props, commodity, user }) => {
	const {
		currentCaptureIndex,
		setQCInProgress,
		qcInProgress,
		capsInPipeline,
		comm,
		setCapsInPipeline,
		resetState,
		qcPerc,
		progressBar,
		navigateHome,
		setNavigateHome
	} = useContext(pipelineContext);

	const { error: errorSendingCapsBack, makeRequest: sendCapsBack } =
		useGainsQCApi();
	const [capturesToReset, setCapturesToReset] = useState([]);
	const [capturesQCd, setCapturesQCd] = useState([]);
	const [isCollapsed, setIsCollapsed] = React.useState(false);

	useEffect(() => {
		if (navigateHome) {
			setTimeout(props.navigate('/'), 2000);
		}
		
	}, [navigateHome]);

	useEffect(() => {
		setCapturesToReset(capsInPipeline.filter((cap) => cap.qc_state === 0));
		setCapturesQCd(capsInPipeline.filter((cap) => cap.qc_state !== 0));

		// if (comm === 'smelters') {
		// 	setCapturesToReset(
		// 		capsInPipeline.filter((cap) => cap.qc_state === 0)

		// 		// capsInPipeline.filter(
		// 		// 	(cap) =>
		// 		// 		(cap.state === null && !cap.hasOwnProperty('newQC')) ||
		// 		// 		(cap.state === null &&
		// 		// 			cap.hasOwnProperty('newQC') &&
		// 		// 			cap.newQC === 'failed')
		// 		// )
		// 	);

		// 	setCapturesQCd(
		// 		// capsInPipeline.filter(
		// 		// 	(cap) => cap.state !== null && cap.hasOwnProperty('newQC')
		// 		// )

		// 		capsInPipeline.filter(
		// 			(cap) => cap.qc_state !== 0
		// 		)
		// 	);
		// } else {
		// 	setCapturesToReset(
		// 		capsInPipeline.filter(
		// 			(cap) =>
		// 				!cap.hasOwnProperty('newQC') ||
		// 				(cap.hasOwnProperty('newQC') && cap.newQC === 'failed')
		// 		)
		// 	);
		// 	setCapturesQCd(
		// 		capsInPipeline.filter((cap) => cap.hasOwnProperty('newQC'))
		// 	);
		// }
	}, [currentCaptureIndex, progressBar, qcInProgress]);

	const exitQCWithCaps = async () => {
		// reset all QC captures
		let caps = capturesToReset.map((cap) => cap.id);

		await sendCapsBack(
			`${exportConfig.baseUrl}/${comm}/remove_from_queue`,
			'POST',
			{
				commodityIds: `${commodity.join(',')}`,
				qcUser: `${user.givenName.toLowerCase()}.${user.familyName.toLowerCase()}`,
				captureIds: caps.join(',')
			},
			`sending ${caps.length} captures back to database`
		);

		// set global captures
		resetState();
		setNavigateHome(true)
		//setTimeout(props.navigate('/'), 2000);
	};

	const exitQCNoCaps = () => {
		resetState();
		setNavigateHome(true)
		//setTimeout(props.navigate('/'), 2000);
	};

	console.log(capsInPipeline);
	console.log(capturesToReset);
	console.log(capturesQCd);

	return (
		<div
			//className="modal modal-lg fade"
			className={
				capturesQCd.length > 0 ? 'modal modal-xl fade' : 'modal modal-lg fade'
			}
			id="exitQCModal"
			tabindex="-1"
			aria-labelledby="exitQCModalLabel"
			aria-hidden="true"
			style={{ marginTop: '80px' }}
		>
			<div className="modal-dialog p-4">
				<div className="modal-content" style={{ minHeight: '500px' }}>
					<div
						className="modal-header text-light"
						style={{ backgroundColor: 'rgb(48, 48, 48)' }}
					>
						<h4 className="modal-title" id="exitQCModalLabel">
							Are you sure you want to exit QC?
						</h4>
					</div>

					<div
						class="modal-body p-4"
						style={{ maxHeight: '600px', overflowY: 'auto' }}
					>
						<div className="d-flex flex-row align-items-center justify-content-center">
							<div className="flashing-dot me-3"></div>{' '}
							<h5 className="py-0 my-0">QC in progress</h5>
						</div>

						<div className="progress mt-4">
							<div
								className="progress-bar progress-bar-striped progress-bar-animated text-center px-4 fs-5"
								style={{
									backgroundColor: 'rgba(80, 145, 39, 0.8)',
									width: { qcPerc }
								}}
								role="progressbar"
								aria-valuenow="10"
								aria-valuemin="0"
								aria-valuemax="100"
							>
								{capturesQCd.length}/{capsInPipeline.length}
							</div>
						</div>

						{capturesQCd.length === 0 ? (
							<>
								<h5 className="mt-4">
									You have not QC'd any captures, but
									<span className="fw-bold"> {capsInPipeline.length}</span>{' '}
									captures are assigned to you in the database.
								</h5>
								<h5 className="mt-4">
									Upon exiting QC, these captures will be unassigned from your
									QC queue and you will be directed back to the homepage.
								</h5>
							</>
						) : (
							<div className="p-2">
								<h5 className="mt-4">
									{capturesQCd.length}/{capsInPipeline.length} captures have
									been QC'd.
								</h5>

								<h5 className="mt-2">
									Upon exiting QC, non-QC'd captures will be unassigned from
									your QC queue and you will be directed back to the homepage.
								</h5>
								<h5 className="mt-2">
									QC'd captures will be available to upload at a later date from
									your <span className="fw-bold">My Captures</span> page
								</h5>
							</div>
						)}

						{capsInPipeline.length > 0 && capturesQCd.length > 0 && (
							<div className="mt-4 p-2">
								<p>
									<button
										className="btn interactButtonSecondary"
										onClick={() => setIsCollapsed(!isCollapsed)}
										href="#exitQCCollapse"
										data-bs-target="#exitQCCollapse"
										role="button"
										aria-expanded="false"
										aria-controls="collapseExample"
									>
										{isCollapsed ? 'Hide capture list' : 'View capture list'}
									</button>
								</p>

								<div
									className={`collapse ${isCollapsed ? 'show' : ''}`}
									id="exitQCCollapse"
								>
									{comm === 'smelters' && (
										<SmeltersTableBasic props={props} height={300} />
									)}

									{comm === 'stockpiles' && (
										<StockpilesTableBasic props={props} height={300} />
									)}
								</div>
							</div>
						)}
					</div>
					<div class="modal-footer">
						{/* <button
							type="button"
							className="btn btn-danger"
							data-bs-dismiss="modal"
						>
							Exit QC
						</button> */}

						{capturesQCd.length > 0 && (
							<>
								{/* <button
									type="button"
									className="btn btn-info"
									data-bs-dismiss="modal"
									//onClick={exitQCWithCaps}
									data-tooltip-id="exit-qc-upload-button"
								>
									Upload Captures
								</button> 
								<Tooltip
									id="exit-qc-upload-button"
									place="right"
									effect="solid"
								>
									
									This button currently does absolutely nothing
								</Tooltip>*/}
							</>
						)}

						{capsInPipeline.length > 0 ? (
							<>
								<button
									type="button"
									className="btn btn-secondary"
									data-bs-dismiss="modal"
									onClick={exitQCWithCaps}
									data-tooltip-id="exit-qc-button"
								>
									Exit QC
								</button>
								<Tooltip id="exit-qc-button" place="top" effect="solid">
									Remove {capturesToReset.length} captures from QC pipeline and
									exit QC
								</Tooltip>
							</>
						) : (
							<button
								type="button"
								className="btn btn-secondary"
								data-bs-dismiss="modal"
								onClick={exitQCNoCaps}
							>
								Exit QC
							</button>
						)}

						<button
							type="button"
							className="btn interactButton"
							data-bs-dismiss="modal"
						>
							Continue with QC
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ExitQCModal;
