import React, { useState, useEffect, useContext } from 'react';
import earthiLogo from '../images/earthi-rev2.svg';
import packageJson from '../../package.json';
import { getAuthenticatedUserAttributes } from '../actions/authUtils';
import Logout from './Logout';
import Navlink from './Navlink';
import { endpointContext } from '../contexts/endpointContext';
import { pipelineContext } from '../contexts/pipelineContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import config from '../config';
import { Tooltip } from 'react-tooltip';

export default function Sidenav(props) {
	const [userAttributes, setUserAttributes] = useState({});
	const endpointContextSidenav = useContext(endpointContext);
	const pipelineContextSideNav = useContext(pipelineContext);

	useEffect(() => {
		const getUserAttributes = async () => {
			const userAttributesAuth = await getAuthenticatedUserAttributes();

			setUserAttributes({
				givenName: userAttributesAuth.givenName,
				familyName: userAttributesAuth.familyName,
				email: userAttributesAuth.email,
				initials: userAttributesAuth.initials
			});
		};

		getUserAttributes().catch(console.error);
	}, []);

	const openAndCloseNav = () => {
		var x = document.getElementById('myLinks');
		var y = document.getElementById('myLinks2');
		if (x.style.display === 'block') {
			x.style.display = 'none';
			y.style.display = 'none';
		} else {
			x.style.display = 'block';
			y.style.display = 'block';
		}
	};

	useEffect(() => {
		const mediaQuery = window.matchMedia('(min-width: 768px)');
		var x = document.getElementById('myLinks');
		var y = document.getElementById('myLinks2');
		const handleScreenChange = (e) => {
			if (e.matches) {
				x.style.display = 'block';
				y.style.display = 'block';
			} else {
				x.style.display = 'none';
				y.style.display = 'none';
			}
		};

		handleScreenChange(mediaQuery);
		mediaQuery.addEventListener('change', handleScreenChange);

		return () => {
			mediaQuery.removeEventListener('change', handleScreenChange);
		};
	}, []);

	console.log(pipelineContextSideNav.capsInPipeline);

	return (
		<div
			className="navbarContainer pe-4 pt-2 pb-4 ps-1"
			style={{ overflow: 'visible' }}
		>
			<div className="topnav">
				<div className="d-flex align-items-center my-2 mb-md-0 p-md-2 me-md-auto text-white text-decoration-none">
					<img
						src={earthiLogo}
						alt=""
						width="39"
						height="39"
						className="rounded-circle ms-1 me-3"
					></img>
					<span className="fs-4">QC</span>
				</div>

				<hr className="sidenav-hr"></hr>

				<div id="myLinks">
					<Navlink
						path={'/'}
						icon={'fa-solid fa-house pe-2'}
						text={'Homepage'}
						userQCing={
							pipelineContextSideNav.capsInPipeline.length > 0 ? true : false
						}
						userUploading={pipelineContextSideNav.straightToUpload}
						setUserUploading={pipelineContextSideNav.setStraightToUpload}
						disabled={endpointContextSidenav.loading}
					/>
					<Navlink
						path={'/qc'}
						icon={'fa fa-solid fa-calendar-day pe-3'}
						text={'QC'}
						userQCing={
							pipelineContextSideNav.capsInPipeline.length > 0 ? true : false
						}
						setUserUploading={pipelineContextSideNav.setStraightToUpload}
						userUploading={pipelineContextSideNav.straightToUpload}
						disabled={endpointContextSidenav.loading}
					/>

					{/* <Navlink
						path={'/reports'}
						icon={'fa fa-solid fa-file-lines pe-3'}
						text={'QC Reports'}
						userQCing={
							pipelineContextSideNav.capsInPipeline.length > 0 ? true : false
						}
						userUploading={pipelineContextSideNav.straightToUpload}
						setUserUploading={pipelineContextSideNav.setStraightToUpload}
						disabled={endpointContextSidenav.loading}
					/> */}

					{/* <Navlink
						path={'/captures'}
						icon={'fa fa-solid fa-images pe-3'}
						text={'Captures'}
						userQCing={
							pipelineContextSideNav.capsInPipeline.length > 0 ? true : false
						}
						userUploading={pipelineContextSideNav.straightToUpload}
						setUserUploading={pipelineContextSideNav.setStraightToUpload}
						disabled={endpointContextSidenav.loading}
					/> */}

					{/* <Navlink
						path={'/users_captures'}
						icon={'fa fa-solid fa-user-tag pe-3'}
						text={'My captures'}
						userQCing={
							pipelineContextSideNav.capsInPipeline.length > 0 ? true : false
						}
						userUploading={pipelineContextSideNav.straightToUpload}
						setUserUploading={pipelineContextSideNav.setStraightToUpload}
						disabled={endpointContextSidenav.loading}
					/> */}
{/* 
					<Navlink
						path={'/reports'}
						icon={'fa fa-solid fa-user-gear pe-3'}
						text={'Profile'}
						userQCing={
							pipelineContextSideNav.capsInPipeline.length > 0 ? true : false
						}
						userUploading={pipelineContextSideNav.straightToUpload}
						setUserUploading={pipelineContextSideNav.setStraightToUpload}
						disabled={endpointContextSidenav.loading}
					/> */}

					{/* <Navlink
						path={'/info'}
						icon={'fa fa-solid fa-circle-info pe-3'}
						text={'Info'}
						userQCing={
							pipelineContextSideNav.capsInPipeline.length > 0 ? true : false
						}
						userUploading={pipelineContextSideNav.straightToUpload}
						setUserUploading={pipelineContextSideNav.setStraightToUpload}
						disabled={endpointContextSidenav.loading}
					/> */}

					{/* <Navlink
						path={'/testing'}
						icon={'fa fa-solid fa-images pe-3'}
						text={'Testing'}
						userQCing={
							pipelineContextSideNav.capsInPipeline.length > 0 ? true : false
						}
						userUploading={pipelineContextSideNav.straightToUpload}
						setUserUploading={pipelineContextSideNav.setStraightToUpload}
						disabled={endpointContextSidenav.loading}
					/> */}

					{/* 

				

					<Navlink
						path={'/aircraftqc'}
						icon={'fa fa-solid fa-images pe-3'}
						text={'Aircraft QC'}
						userQCing={
							pipelineContextSideNav.capsInPipeline.length > 0 ? true : false
						}
						userUploading={pipelineContextSideNav.straightToUpload}
						setUserUploading={pipelineContextSideNav.setStraightToUpload}
						disabled={endpointContextSidenav.loading}
					/> */}
				</div>

				<div id="myLinks2">
					<hr style={{ border: '2px solid white', margin: '5px' }}></hr>

					<p
						className="my-3 py-0 text-center text-primary fw-bold"
						style={{ position: 'relative', bottom: '0' }}
						data-tooltip-id="environment"
					>
						{' '}
						{config.gainsEnvironment.toUpperCase()}
					</p>

					<div className="dropdown px-2">
						<div class="dropdown d-flex justify-content-center">
							<button
								className="dropdown-toggle mb-4"
								style={{
									backgroundColor: '#332d2d',
									color: 'white',
									fontSize: '20px',
									border: 'none'
								}}
								type="button"
								id="dropdownMenuButton1"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<strong className="px-1 fs-5" title={userAttributes.email}>
									{userAttributes.initials}
								</strong>
							</button>
							<ul
								className="dropdown-menu bg-dark"
								aria-labelledby="dropdownMenuButton1"
								style={{ border: 'solid 1px red', zIndex: '9999' }}
							>
								<li className="dropdown-item bg-dark text-white">
									{' '}
									{userAttributes.givenName} {userAttributes.familyName}
								</li>
								<li className="dropdown-item bg-dark text-white">
									{userAttributes.email}
								</li>
								<li className="dropdown-item bg-dark text-white">
									App version: {packageJson.version}
								</li>
								<li>
									<hr className="dropdown-divider"></hr>
								</li>
								<li className="dropdown-item bg-dark text-white">
									<Logout props={props} />
								</li>
							</ul>
						</div>
					</div>
				</div>

				<a
					href="javascript:void(0);"
					className="close-sidenav-icon"
					onClick={(e) => openAndCloseNav()}
				>
					<i className="fa fa-bars"></i>
				</a>
			</div>
		</div>
	);
}
