import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Tooltip } from 'react-tooltip';

const StockpilesGeojsonsList = ({
	featureLayers,
	setFeatureLayers,
	stockpilesGeojsons,
	activeStockpile,
	setActiveStockpile,
	selectedMapView,
	setSelectedMapView
}) => {
	useEffect(() => {
		if (activeStockpile) {
			try {
				document.getElementById(activeStockpile).classList.add('fa-solid');
				document.getElementById(`${activeStockpile}-text`).style.color =
					'white';
				document.getElementById(activeStockpile).classList.add('fa-regular');
			} catch (e) {}
		} else {
			try {
				let allSPs = document.getElementsByClassName('allStockpiles');
				let allIconText = document.getElementsByClassName('icon-text');
				for (let i = 0; i < allIconText.length; i++) {
					allIconText[i].style.color = 'black';
				}
				for (let i = 0; i < allSPs.length; i++) {
					allSPs[i].classList.remove('fa-solid');
					allSPs[i].classList.add('fa-regular');
				}
			} catch (e) {}
		}
	}, [activeStockpile]);

	console.log(stockpilesGeojsons)

	if (stockpilesGeojsons) {
		if (stockpilesGeojsons[stockpilesGeojsons.length - 1].length > 0) {
			return (
				<div
					className="py-3"
					style={{
						overflowY: 'scroll',
						maxHeight: '360px',
						width: '100%',
					}}
				>
					<h5>
						<i className="fa-solid fa-layer-group me-2"></i> Layers
					</h5>
					{/* <p>Edits: {stockpilesGeojsons.length}</p> */}

					<div >
						<div className="row">
							<div className="col" data-tooltip-id='originalPolygon'>
								<i
									className="fa-regular fa-square mx-1 fa-md"
									style={{ color: 'black' }}
								></i>
								Original layer
							</div>
							<div className="col" data-tooltip-id='newPolygon'>
								<i
									className="fa-solid fa-square mx-1"
									style={{ color: 'blue' }}
								></i>
								New layer drawn
							</div>
						</div>

						<div className="row">
							<div className="col" data-tooltip-id='editedPolygon'>
								<i
									className="fa-solid fa-square mx-1"
									style={{ color: 'green' }}
								></i>
								Edited
							</div>
							<div className="col" data-tooltip-id='deletedPolygon'>
								{' '}
								<i
									className="fa-solid fa-square mx-1"
									style={{ color: 'red', opacity: '0.5' }}
								></i>
								Deleted
							</div>
						</div>

						<div className="row">
							<div className="col" data-tooltip-id='invalidPolygon'>
								<i
									className="fa-solid fa-square mx-1"
									style={{ color: 'orange', opacity: '0.5' }}
									
								></i>
								Invalid shape
							</div>
							<div className="col">
								
							</div>
						</div>
					</div>

					<h6 className="pt-4 pb-1">
						Stockpiles (
						{stockpilesGeojsons[stockpilesGeojsons.length - 1].length})
					</h6>

					<div style={{ maxWidth: '100%' }}>
						{stockpilesGeojsons[stockpilesGeojsons.length - 1].map(
							(item, index) => (
								<div
									className="icon-container p-1"
									style={{ position: 'relative', display: 'inline-block' }}
									key={index}
								>
									<>
										<i
											onMouseEnter={() => setActiveStockpile(item.id)}
											onMouseLeave={() => setActiveStockpile(null)}
											onClick={() => setSelectedMapView(item.geometry)}
											className="allStockpiles fa-regular fa-square mx-1 fa-xl"
											id={item.id}
											style={{
												color:
													item.properties.hasOwnProperty('status') &&
													item.properties.status === 'deleted'
														? 'red'
														: item.properties.hasOwnProperty('status') &&
														  item.properties.status === 'simplified'
														? 'green'
														: item.properties.hasOwnProperty('status') &&
														  item.properties.status === 'edited'
														? 'green'
														: item.properties.hasOwnProperty('status') &&
														  item.properties.status === 'created'
														? 'blue'
														: item.properties.hasOwnProperty('status') &&
														  item.properties.status === 'invalid'
														? 'orange'
														: 'black'
											}}
											data-tooltip-id="zoomToStockpile"
										></i>
										<span className="icon-text" id={`${item.id}-text`}>
											{Number(item.id) + 1}
										</span>
									</>
									{/* {item.feature.status === 'Edited' && (
								<>
									<i
										className="fa-solid fa-square mx-1 fa-xl"
										style={{ color: 'green' }}
									></i>
									<span className="icon-text">{item.feature.properties.id}</span>
								</>
							)}
	
							{item.feature.status === 'Simplified' && (
								<>
									<i
										className="fa-solid fa-square mx-1 fa-xl"
										style={{ color: 'green' }}
									></i>
									<span className="icon-text">{item.feature.properties.id}</span>
								</>
							)}
	
							{item.feature.status === '' && (
								<>
									<i
										className="fa-regular fa-square mx-1 fa-xl"
										style={{ color: 'black' }}
									></i>
									<span className="icon-text">{item.feature.properties.id}</span>
								</>
							)}
	
							{!item.feature.hasOwnProperty('status') && (
								<>
									<i
										className="fa-regular fa-square mx-1 fa-xl"
										style={{ color: 'black' }}
									></i>
									<span className="icon-text">{item.feature.properties.id}</span>
								</>
							)}
	
							{item.feature.status === 'Created' && (
								<>
									<i
										className="fa-solid fa-square mx-1 fa-xl"
										style={{ color: 'blue' }}
									></i>
									<span className="icon-text" style={{ color: 'white' }}>
										{item.feature.properties.id}
									</span>
								</>
							)}
	
							{item.feature.status === 'Deleted' && (
								<>
									<i
										className="fa-solid fa-square mx-1 fa-xl"
										style={{ color: 'red' }}
									></i>
									<span className="icon-text" style={{ color: 'white' }}>
										{item.feature.properties.id}
									</span>
								</>
							)} */}
								</div>
							)
						)}
					</div>
					{/* <h6 className="pt-4 pb-1">AOI</h6> */}
					<Tooltip id="zoomToStockpile" place="top" effect="solid">
						Click to zoom to stockpile
					</Tooltip>

					<Tooltip id="originalPolygon" place="right" effect="solid">
						The original geometry of these stockpiles has not been changed. 
					</Tooltip>

					<Tooltip id="invalidPolygon" place="right" effect="solid">
						Stockpiles determined to be invalid.<br />  Either it falls outwith the site AOI or it has intersecting points. 
					</Tooltip>

					<Tooltip id="newPolygon" place="right" effect="solid">
						New stockpiles drawn on the map.<br />  It will be added to the stockpiles geojson for this capture. 
					</Tooltip>

					<Tooltip id="deletedPolygon" place="right" effect="solid">
						Stockpiles which have been deleted.<br />  The geometry for these stockpiles will be removed from the database. 
					</Tooltip>

					<Tooltip id="editedPolygon" place="right" effect="solid">
						Stockpiles which have been edited.<br />  The geometry for these stockpiles has been edited and the new geometry will be changed in the database. 
					</Tooltip>
				</div>
			);
		}
	} else {
		return (
			<div style={{ height: '100%' }}>
				<Skeleton style={{ height: '35vh', width: '100%' }} />
			</div>
		);
	}
};

export default StockpilesGeojsonsList;
