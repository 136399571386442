export const aircraftFile = {
  "type": "FeatureCollection",
  "name": "20211206_100334_ssc6_u0001_analytic_SR_clip_reproject",
  "crs": {
    "type": "name",
    "properties": {
      "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
    }
  },
  "features": [
    {
      "type": "Feature",
      "properties": {
        "class_id": 11.0,
        "confidence": 0.9094283580780029,
        "class_name": "Fokker F27 / 50"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.58664838526938,
              29.545384185770097
            ],
            [
              52.58691779458881,
              29.54538096783176
            ],
            [
              52.58692200174349,
              29.54565024942887
            ],
            [
              52.586652591710546,
              29.545653467402282
            ],
            [
              52.58664838526938,
              29.545384185770097
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 2.0,
        "confidence": 0.9061431288719177,
        "class_name": "IL-76 Ilyushin (Candid)"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.57899016808185,
              29.548698818485537
            ],
            [
              52.579468183997975,
              29.54869313566539
            ],
            [
              52.57947501515169,
              29.54913237003901
            ],
            [
              52.578996997170265,
              29.549138052960206
            ],
            [
              52.57899016808185,
              29.548698818485537
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 11.0,
        "confidence": 0.9026086330413818,
        "class_name": "Fokker F27 / 50"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.586526396854495,
              29.545537542592456
            ],
            [
              52.58681998855772,
              29.54553403604137
            ],
            [
              52.58682401016127,
              29.545791455664837
            ],
            [
              52.586530417714734,
              29.545794962252454
            ],
            [
              52.586526396854495,
              29.545537542592456
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 0.0,
        "confidence": 0.9003860950469971,
        "class_name": "C130 Hercules"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.57428615316297,
              29.551627572653754
            ],
            [
              52.574671707051515,
              29.551623002570434
            ],
            [
              52.57467617067096,
              29.551910848922684
            ],
            [
              52.57429061569062,
              29.551915419059252
            ],
            [
              52.57428615316297,
              29.551627572653754
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 2.0,
        "confidence": 0.8992647528648376,
        "class_name": "IL-76 Ilyushin (Candid)"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.57559734094733,
              29.550660011902803
            ],
            [
              52.57607855604854,
              29.550654303108054
            ],
            [
              52.57608529812115,
              29.55108870779715
            ],
            [
              52.57560408096349,
              29.55109441669228
            ],
            [
              52.57559734094733,
              29.550660011902803
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 2.0,
        "confidence": 0.8978212475776672,
        "class_name": "IL-76 Ilyushin (Candid)"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.58027543585493,
              29.54818279250021
            ],
            [
              52.58074865029912,
              29.548177162249697
            ],
            [
              52.580755424528334,
              29.548612392640596
            ],
            [
              52.58028220805826,
              29.548618022990304
            ],
            [
              52.58027543585493,
              29.54818279250021
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 2.0,
        "confidence": 0.8952654004096985,
        "class_name": "IL-76 Ilyushin (Candid)"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.5771531740951,
              29.55004772568067
            ],
            [
              52.57766232753925,
              29.550041679502037
            ],
            [
              52.57766926518934,
              29.550488247678
            ],
            [
              52.5771601095085,
              29.55049429396593
            ],
            [
              52.5771531740951,
              29.55004772568067
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 0.0,
        "confidence": 0.8916731476783752,
        "class_name": "C130 Hercules"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.57340195393608,
              29.551962851633526
            ],
            [
              52.57379581231206,
              29.551958185692946
            ],
            [
              52.57380030685351,
              29.55224818353092
            ],
            [
              52.57340644735384,
              29.552252849526276
            ],
            [
              52.57340195393608,
              29.551962851633526
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 0.0,
        "confidence": 0.8912048935890198,
        "class_name": "C130 Hercules"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.575861836545,
              29.549902121491897
            ],
            [
              52.57626212144183,
              29.549897372195055
            ],
            [
              52.57626705853392,
              29.550215452213585
            ],
            [
              52.57586677238461,
              29.55022020157159
            ],
            [
              52.575861836545,
              29.549902121491897
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 0.0,
        "confidence": 0.8878899216651917,
        "class_name": "C130 Hercules"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.57394382431199,
              29.55097734002423
            ],
            [
              52.57431974903864,
              29.55097288512137
            ],
            [
              52.57432431537574,
              29.55126742907256
            ],
            [
              52.57394838955982,
              29.55127188402854
            ],
            [
              52.57394382431199,
              29.55097734002423
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 0.0,
        "confidence": 0.8868895769119263,
        "class_name": "C130 Hercules"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.568378172973944,
              29.55240789118323
            ],
            [
              52.56879073994918,
              29.55240301914417
            ],
            [
              52.568795088375566,
              29.552684479854506
            ],
            [
              52.56838252025791,
              29.552689351949066
            ],
            [
              52.568378172973944,
              29.55240789118323
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 2.0,
        "confidence": 0.8841143846511841,
        "class_name": "IL-76 Ilyushin (Candid)"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.572678958164985,
              29.550086945798693
            ],
            [
              52.57313499550541,
              29.55008154582697
            ],
            [
              52.573141703558605,
              29.550514577854315
            ],
            [
              52.57268566427553,
              29.550519977920693
            ],
            [
              52.572678958164985,
              29.550086945798693
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 0.0,
        "confidence": 0.8797638416290283,
        "class_name": "C130 Hercules"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.568097692196886,
              29.553849169310062
            ],
            [
              52.56852606035716,
              29.55384411140145
            ],
            [
              52.56853036536875,
              29.55412279268813
            ],
            [
              52.56810199603395,
              29.5541278506538
            ],
            [
              52.568097692196886,
              29.553849169310062
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 2.0,
        "confidence": 0.8780714273452759,
        "class_name": "IL-76 Ilyushin (Candid)"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.57967814008735,
              29.548418203675105
            ],
            [
              52.58015477157458,
              29.54841253487934
            ],
            [
              52.58016158664189,
              29.54885054944522
            ],
            [
              52.57968495310107,
              29.54885621834149
            ],
            [
              52.57967814008735,
              29.548418203675105
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 0.0,
        "confidence": 0.8777070641517639,
        "class_name": "C130 Hercules"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.56808059705608,
              29.554245061309885
            ],
            [
              52.56851273938032,
              29.55423995884577
            ],
            [
              52.568516833528996,
              29.5545049881653
            ],
            [
              52.5680846900779,
              29.554510090684143
            ],
            [
              52.56808059705608,
              29.554245061309885
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 3.0,
        "confidence": 0.8767213225364685,
        "class_name": "Pilatus PC-6 porter"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.57699983222798,
              29.54957971318553
            ],
            [
              52.577194120263094,
              29.549577406499317
            ],
            [
              52.57719630914498,
              29.54971834779521
            ],
            [
              52.577002020840496,
              29.549720654494582
            ],
            [
              52.57699983222798,
              29.54957971318553
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 11.0,
        "confidence": 0.8707690238952637,
        "class_name": "Fokker F27 / 50"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.58658478394361,
              29.545712425680343
            ],
            [
              52.58687746679985,
              29.545708929843777
            ],
            [
              52.58688156380234,
              29.54597116428707
            ],
            [
              52.586588880191215,
              29.54597466016075
            ],
            [
              52.58658478394361,
              29.545712425680343
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 0.0,
        "confidence": 0.8642261624336243,
        "class_name": "C130 Hercules"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.5730641743847,
              29.551315360016115
            ],
            [
              52.57346760672502,
              29.55131058173141
            ],
            [
              52.573472106567706,
              29.551600989875805
            ],
            [
              52.57306867307479,
              29.55160576821667
            ],
            [
              52.5730641743847,
              29.551315360016115
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 0.0,
        "confidence": 0.8624868988990784,
        "class_name": "C130 Hercules"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.57384392875889,
              29.55179394004806
            ],
            [
              52.57423939485722,
              29.551789253758724
            ],
            [
              52.57424400455571,
              29.552086599928504
            ],
            [
              52.573848537300535,
              29.552091286274244
            ],
            [
              52.57384392875889,
              29.55179394004806
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 1.0,
        "confidence": 0.8468373417854309,
        "class_name": "Su-24 (Fencer)"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.566677025672774,
              29.540814074075094
            ],
            [
              52.56683919816949,
              29.540812161658096
            ],
            [
              52.56684267434716,
              29.54103755153157
            ],
            [
              52.566680501491,
              29.54103946396601
            ],
            [
              52.566677025672774,
              29.540814074075094
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 3.0,
        "confidence": 0.8448749780654907,
        "class_name": "Pilatus PC-6 porter"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.57729375759656,
              29.549466525710194
            ],
            [
              52.57748115071191,
              29.54946430047921
            ],
            [
              52.57748330983148,
              29.54960330072906
            ],
            [
              52.577295916459896,
              29.54960552597256
            ],
            [
              52.57729375759656,
              29.549466525710194
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 10.0,
        "confidence": 0.8393076658248901,
        "class_name": "Boeing 737"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.59207864640595,
              29.543928369364302
            ],
            [
              52.59243205420169,
              29.54392413367593
            ],
            [
              52.59243618922427,
              29.544187897167586
            ],
            [
              52.5920827805118,
              29.544192132901202
            ],
            [
              52.59207864640595,
              29.543928369364302
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 3.0,
        "confidence": 0.831496000289917,
        "class_name": "Pilatus PC-6 porter"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.57756576074318,
              29.54935821734801
            ],
            [
              52.57775731638093,
              29.549355942298135
            ],
            [
              52.5777594195099,
              29.54949131487297
            ],
            [
              52.57756786361706,
              29.549493589935313
            ],
            [
              52.57756576074318,
              29.54935821734801
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 1.0,
        "confidence": 0.8295848965644836,
        "class_name": "Su-24 (Fencer)"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.56692294082543,
              29.5407228651071
            ],
            [
              52.56707766300246,
              29.540721040269183
            ],
            [
              52.56708122302193,
              29.54095183199629
            ],
            [
              52.56692650049376,
              29.54095365685126
            ],
            [
              52.56692294082543,
              29.5407228651071
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 1.0,
        "confidence": 0.8219427466392517,
        "class_name": "Su-24 (Fencer)"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.56621263404155,
              29.541019504159745
            ],
            [
              52.56636979309715,
              29.54101765140834
            ],
            [
              52.56637331606266,
              29.54124614148583
            ],
            [
              52.56621615665393,
              29.541247994254373
            ],
            [
              52.56621263404155,
              29.541019504159745
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 0.0,
        "confidence": 0.8150919675827026,
        "class_name": "C130 Hercules"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.57351609211824,
              29.551140737861985
            ],
            [
              52.57389003750741,
              29.55113630760779
            ],
            [
              52.57389477069048,
              29.55144169495944
            ],
            [
              52.57352082417787,
              29.551446125268416
            ],
            [
              52.57351609211824,
              29.551140737861985
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 3.0,
        "confidence": 0.8132575154304504,
        "class_name": "Pilatus PC-6 porter"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.57785554437601,
              29.549250042287113
            ],
            [
              52.57804010310201,
              29.549247849944468
            ],
            [
              52.578042197640166,
              29.549382645976273
            ],
            [
              52.57785763866944,
              29.549384838330877
            ],
            [
              52.57785554437601,
              29.549250042287113
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 0.0,
        "confidence": 0.7967284917831421,
        "class_name": "C130 Hercules"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.569877804591755,
              29.55220754494879
            ],
            [
              52.57019992703912,
              29.552203737463927
            ],
            [
              52.5702054919155,
              29.552563614149626
            ],
            [
              52.56988336832765,
              29.55256742168996
            ],
            [
              52.569877804591755,
              29.55220754494879
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 1.0,
        "confidence": 0.7631155252456665,
        "class_name": "Su-24 (Fencer)"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.56550032685528,
              29.54129477303213
            ],
            [
              52.565698649842226,
              29.54129243601798
            ],
            [
              52.565702053950126,
              29.541513309564593
            ],
            [
              52.56550373053242,
              29.541515646599652
            ],
            [
              52.56550032685528,
              29.54129477303213
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 4.0,
        "confidence": 0.7612945437431335,
        "class_name": "Lockheed P3 Orion"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.578145755578774,
              29.550415822283103
            ],
            [
              52.57846475873166,
              29.5504120319467
            ],
            [
              52.5784692682976,
              29.55070215639871
            ],
            [
              52.57815026423427,
              29.55070594677962
            ],
            [
              52.578145755578774,
              29.550415822283103
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 2.0,
        "confidence": 0.7116830348968506,
        "class_name": "IL-76 Ilyushin (Candid)"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.56782661314176,
              29.54247239151881
            ],
            [
              52.56827253420635,
              29.542467128482524
            ],
            [
              52.568279246027785,
              29.542901882797608
            ],
            [
              52.567833323056675,
              29.54290714592654
            ],
            [
              52.56782661314176,
              29.54247239151881
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 13.0,
        "confidence": 0.7084147334098816,
        "class_name": "Airbus A300"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.56243163117625,
              29.552186581829865
            ],
            [
              52.56302111221413,
              29.55217964664012
            ],
            [
              52.56303066472173,
              29.552800235900378
            ],
            [
              52.562441180084775,
              29.552807171264327
            ],
            [
              52.56243163117625,
              29.552186581829865
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 2.0,
        "confidence": 0.6030834317207336,
        "class_name": "IL-76 Ilyushin (Candid)"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.56757982677321,
              29.54199254038539
            ],
            [
              52.56802069478221,
              29.54198733786806
            ],
            [
              52.56802786770568,
              29.542452043200395
            ],
            [
              52.56758699768194,
              29.54245724581561
            ],
            [
              52.56757982677321,
              29.54199254038539
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 3.0,
        "confidence": 0.5441256165504456,
        "class_name": "Pilatus PC-6 porter"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.57812572558937,
              29.549145357989243
            ],
            [
              52.57832413260119,
              29.549143000736148
            ],
            [
              52.57832617788924,
              29.54927460410428
            ],
            [
              52.57812777062058,
              29.549276961369948
            ],
            [
              52.57812572558937,
              29.549145357989243
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 3.0,
        "confidence": 0.5151299834251404,
        "class_name": "Pilatus PC-6 porter"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.56930669568958,
              29.553670927824474
            ],
            [
              52.56949006659892,
              29.553668761200424
            ],
            [
              52.56949185714137,
              29.553784600529035
            ],
            [
              52.56930848602305,
              29.553786767163245
            ],
            [
              52.56930669568958,
              29.553670927824474
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 11.0,
        "confidence": 0.42344361543655396,
        "class_name": "Fokker F27 / 50"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.60112424918702,
              29.540957366309502
            ],
            [
              52.6012996852168,
              29.540955251957833
            ],
            [
              52.60130267779737,
              29.541145107412973
            ],
            [
              52.60112724144007,
              29.541147221780893
            ],
            [
              52.60112424918702,
              29.540957366309502
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 10.0,
        "confidence": 0.40636494755744934,
        "class_name": "Boeing 737"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.601014727454654,
              29.540716130114465
            ],
            [
              52.60129012988872,
              29.540712811109806
            ],
            [
              52.60129459704429,
              29.540996220453195
            ],
            [
              52.601019193842724,
              29.540999539495946
            ],
            [
              52.601014727454654,
              29.540716130114465
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 6.0,
        "confidence": 0.38014641404151917,
        "class_name": "Fokker 100"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.58638307882308,
              29.54537256495387
            ],
            [
              52.58676325898624,
              29.545368024524176
            ],
            [
              52.58676806628905,
              29.545675748827342
            ],
            [
              52.586387884975274,
              29.54568028931361
            ],
            [
              52.58638307882308,
              29.54537256495387
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 6.0,
        "confidence": 0.36255162954330444,
        "class_name": "Fokker 100"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.586904015927225,
              29.5455963324476
            ],
            [
              52.587119603256916,
              29.54559375699903
            ],
            [
              52.587124346066744,
              29.545897282025507
            ],
            [
              52.586908758093465,
              29.545899857505727
            ],
            [
              52.586904015927225,
              29.5455963324476
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 10.0,
        "confidence": 0.3555762469768524,
        "class_name": "Boeing 737"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.575358990473326,
              29.550077609590225
            ],
            [
              52.57568509931871,
              29.550073741688824
            ],
            [
              52.575689719438316,
              29.550371507321287
            ],
            [
              52.575363609637705,
              29.550375375269304
            ],
            [
              52.575358990473326,
              29.550077609590225
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 3.0,
        "confidence": 0.31950774788856506,
        "class_name": "Pilatus PC-6 porter"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.56902708896214,
              29.553074784043947
            ],
            [
              52.56922267927764,
              29.553072473472742
            ],
            [
              52.569224514743915,
              29.553191242276117
            ],
            [
              52.56902892419987,
              29.553193552858435
            ],
            [
              52.56902708896214,
              29.553074784043947
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 2.0,
        "confidence": 0.270128458738327,
        "class_name": "IL-76 Ilyushin (Candid)"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.591550227808334,
              29.522063636939038
            ],
            [
              52.59237957619245,
              29.522053703311002
            ],
            [
              52.59238237703824,
              29.522232528515563
            ],
            [
              52.59155302719689,
              29.52224246221556
            ],
            [
              52.591550227808334,
              29.522063636939038
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 11.0,
        "confidence": 0.26188763976097107,
        "class_name": "Fokker F27 / 50"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.601128254755615,
              29.541011227207534
            ],
            [
              52.601301580103275,
              29.541009138287574
            ],
            [
              52.601303766195784,
              29.5411478281952
            ],
            [
              52.601130440611755,
              29.54114991712688
            ],
            [
              52.601128254755615,
              29.541011227207534
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "class_id": 11.0,
        "confidence": 0.25242289900779724,
        "class_name": "Fokker F27 / 50"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              52.58690669185332,
              29.545600705357867
            ],
            [
              52.587114661870594,
              29.54559822090875
            ],
            [
              52.58711936835647,
              29.545899422217044
            ],
            [
              52.58691139772311,
              29.54590190669645
            ],
            [
              52.58690669185332,
              29.545600705357867
            ]
          ]
        ]
      }
    }
  ]
}