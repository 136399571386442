import React, { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Tooltip } from 'react-tooltip';
import { Row, Col } from 'reactstrap';
import { QCingFormAircraft } from '../QC/Aircraft/QCingFormAircraft';
import AircraftPlot from '../QC/Plots/AircraftPlot';
import formatDate from '../../helpers/formatDate';
import { QCProgressBar } from '../QC';
import { pipelineContext } from '../../contexts/pipelineContext';
import { AircraftSceneSummary } from '../QC/Aircraft/AircraftSceneSummary';
import { aircraftFile } from '../../helpers/aircraftFiles/newAirCraftFile';
import { QCImageAircraft } from '../QC/Aircraft/QCImageAircraft';

export const AircraftQC = ({
	onAircraftQCSubmit,
	skipCapture,
	drawnItemsValid,
	aircraftFormValue,
	setAircraftFormValue,
	aircraftCase,
	setModalOpen
}) => {
	const pipelineContextAircraftQC = useContext(pipelineContext);

	const [activeAircraft, setActiveAircraft] = useState(null);
	const [selectedAircraft, setSelectedAircraft] = useState(null);
	const [selectedAircraftClass, setSelectedAircraftClass] = useState(null);
	const [editingAircraft, setEditingAircraft] = useState(null);
	const [confidenceThreshold, setConfidenceThreshold] = useState(50);
	const [aircraftZoom, setAircraftZoom] = useState(null);
	const [aircraftGeojsons, setAircraftGeojsons] = useState([aircraftFile]);

	useEffect(() => {
		pipelineContextAircraftQC.setComm('aircraft');
		pipelineContextAircraftQC.setProgressBar({
			width: 5 * (100 / 7),
			title: 'Perform QC'
		});
		pipelineContextAircraftQC.setQCInProgress(true);
	}, []);

	const handleChange = (e) => {
		console.log(e.target.value)
		console.log(typeof(e.target.value))
		console.log(selectedAircraft)

		setSelectedAircraftClass({
			class_name:
				aircraftList.filter(aircraft => aircraft.class_id === parseInt(e.target.value))[0].class_name,
			class_id:
				e.target.value
		})

		console.log(aircraftGeojsons)

		try {
			let lastList =
				aircraftGeojsons[aircraftGeojsons.length - 1].features;
			let thisAircraft = lastList.filter(
				(feature) => feature.properties.id ===  selectedAircraft.aircraft.id
			)[0];
			console.log(thisAircraft);
			let updatedAircraft = thisAircraft;

			
			updatedAircraft.properties.confidence = 1;
			updatedAircraft.properties.status = 'Class changed';
			console.log(updatedAircraft);

			let removeOldFeatureList = lastList.filter(
				(item) => item.properties.id !== selectedAircraft.aircraft.id
			);
			console.log(removeOldFeatureList);
			removeOldFeatureList.push(updatedAircraft);
			console.log(removeOldFeatureList);

			let fullAircraftGeojsonsNew = aircraftGeojsons
			fullAircraftGeojsonsNew.features = removeOldFeatureList

			console.log(fullAircraftGeojsonsNew)

			setAircraftGeojsons((aircraftGeojsons) => [...aircraftGeojsons, fullAircraftGeojsonsNew]);
		} catch (e) {
			console.log(e);
		}
	}

	let currentCapture = {
		site_id: '100',
		site_name: 'Shiraz',
		id: '482',
		model_name: 'SkySat Collect',
		captured: '2022-03-28 00:00:00'
	};

	// const handleChangeAircraftClass = () => {
	// 	selectedAircraftClass
	// };

	const aircraftList = [
		{ class_id: 0, class_name: 'C130 Hercules' },
		{ class_id: 1, class_name: 'Su-24 (Fencer)' },
		{ class_id: 2, class_name: 'IL-76 Ilyushin (Candid)' },
		{ class_id: 3, class_name: 'Pilatus PC-6 porter' },
		{ class_id: 4, class_name: 'Lockheed P3 Orion' },
		{ class_id: 5, class_name: 'Sukhoi 22 Fitter' },
		{ class_id: 6, class_name: 'Fokker 100' },
		{ class_id: 7, class_name: 'MIL Mi-8 / 17 Hip' },
		{ class_id: 8, class_name: 'BAE 146' },
		{ class_id: 9, class_name: 'Harbin Y-12' },
		{ class_id: 10, class_name: 'Boeing 737' },
		{ class_id: 11, class_name: 'Fokker F27 / 50' },
		{ class_id: 12, class_name: 'Bombardier Canadair CRJ100/200' },
		{ class_id: 13, class_name: 'Airbus A300' },
		{ class_id: 14, class_name: 'Unknown' },
		{ class_id: 15, class_name: 'AN-74 Coaler' },
		{ class_id: 16, class_name: 'Dassault Falcon 20 (Probable)' },
		{ class_id: 17, class_name: 'Airbus A340' },
		{ class_id: 18, class_name: 'background' }
	];

	let imageData2 = {};

	console.log(selectedAircraftClass);

	return (
		<>
			<Col className="px-0">
				<div className="contentContainer" style={{ overflow: 'auto' }}>
					<QCProgressBar />
					<div style={{ paddingX: '20px' }}>
						<hr></hr>
						<div className="row px-0 px-md-5 pb-2">
							<div className="col-12 col-md-4 d-flex justify-content-center">
								<p className="py-0 my-0" data-tooltip-id="QCingSiteInfo">
									<i class="fa-solid fa-location-dot me-2"></i>
									{!currentCapture ? (
										<Skeleton width={80} />
									) : (
										`${currentCapture.site_id} - ${currentCapture.site_name}`
									)}
								</p>
								<Tooltip id="QCingSiteInfo" place="top" effect="solid">
									Site id - Site name
								</Tooltip>
							</div>
							<div className="col-12 col-md-4 d-flex justify-content-center">
								<Row>
									<div className="d-flex justify-content-end">
										<p
											className="text-capitalize ps-4 py-0 my-0"
											data-tooltip-id="QCingCaptureInfo"
										>
											<i className="fa-regular fa-image  me-2"></i>
											{!currentCapture ? (
												<Skeleton width={80} />
											) : (
												`${currentCapture.id} - ${currentCapture.model_name}`
											)}
										</p>
										<Tooltip id="QCingCaptureInfo" place="top" effect="solid">
											Capture id - Sensor
										</Tooltip>
									</div>
								</Row>
							</div>
							<div className="col-12 col-md-4 d-flex justify-content-center">
								<Row>
									<div className="d-flex justify-content-end">
										<p
											className="text-capitalize ps-4 py-0 my-0"
											data-tooltip-id="QCingCaptureDate"
										>
											<i class="fa-solid fa-calendar-day me-2"></i>
											{!currentCapture ? (
												<Skeleton width={80} />
											) : (
												`${formatDate(currentCapture.captured.slice(0, 10))}`
											)}
										</p>
										{/* <p className="ms-2">
                            <a href={sentinelUrl} target="_blank">
                                Sentinel Hub
                            </a>
                        </p> */}
										<Tooltip id="QCingCaptureDate" place="top" effect="solid">
											Capture date
										</Tooltip>
									</div>
								</Row>
							</div>
						</div>

						<div className="row">
							<div
								className="col-12 py-3 py-md-1 col-lg-6"
								//style={{border: 'solid red 1px'}}
							>
								{!imageData2 ? (
									<div
										style={{
											width: '100%',
											height: '100%'
											//border: 'solid orange 1px'
										}}
									>
										<Skeleton
											height={'82vh'}
											data-tooltip-id="imageTrueColour"
										/>
									</div>
								) : (
									<div
										style={{
											width: '100%',
											height: '85vh'
											//border: 'solid yellow 1px'
										}}
										//data-bs-toggle="modal"
										//data-bs-target="#id1"
										//onClick={() => setModalOpen(true)}
										//data-tooltip-id="imageTrueColour"
									>
										<QCImageAircraft
											modalId={'id8'}
											// stockpilesGeojsons={stockpilesGeojsons}
											// setStockpilesGeojsons={setStockpilesGeojsons}
											// imageUrl={imageData2.true_color}
											// geojson={imageData2.aoi}
											// perm_regions={imageData2.perm_regions}
											// hotspots={imageData2.hotspots}
											// bbox={imageData2.bbox}
											// imageData={imageData2}
											// setEditedGeojson={setEditedGeojson}
											// openModal={modalOpen}
											// featureCollection={featureCollection}
											// setFeatureCollection={setFeatureCollection}
											// featureLayers={featureLayers}
											// setFeatureLayers={setFeatureLayers}
											currentCapture={currentCapture}
											// setDrawnItemsValid={setDrawnItemsValid}
											// drawnItemsValid={drawnItemsValid}
											// stockpilesCase={stockpilesCase}
											// activeStockpile={activeStockpile}
											// setActiveStockpile={setActiveStockpile}
											// selectedMapView={selectedMapView}
											// setSelectedMapView={setSelectedMapView}
											aircraftGeojsons={aircraftGeojsons}
											setAircraftGeojsons={setAircraftGeojsons}
											confidenceThreshold={confidenceThreshold}
											selectedAircraft={selectedAircraft}
											setSelectedAircraft={setSelectedAircraft}
											selectedAircraftClass={selectedAircraftClass}
											setSelectedAircraftClass={setSelectedAircraftClass}
											aircraftZoom={aircraftZoom}
										/>

										<div className="row mt-2">
											<div className="col p-3">
												<label className="fw-bold">
													Change confidence threshold:{' '}
													<span>{confidenceThreshold}%</span>
												</label>
												<div className="slidecontainer">
													<input
														type="range"
														data-tooltip-id="aircraftConfidenceSlider"
														min="1"
														max="100"
														value={confidenceThreshold}
														onChange={(e) =>
															setConfidenceThreshold(e.target.value)
														}
														className="slider"
														id="myRange"
														style={{
															height: '10px',
															width: '80%'
														}}
														disabled={selectedAircraftClass}
													></input>
												</div>
											</div>
											<Tooltip id="aircraftConfidenceSlider">
												{selectedAircraftClass
													? 'Not available when an aircraft class is selected.  Click anywhere on the map to remove the selected aircraft class.'
													: 'Slide to change confidence threshold'}
											</Tooltip>
											<div className="col p-3">
												<div className="d-flex justify-content-start">
													<div
														style={{
															margin: '5px',
															width: '15px',
															height: '15px',
															border: 'solid green 2px',
															borderRadius: '5px',
															backgroundColor: 'green'
														}}
													></div>
													<p className="my-0">
														detection box/aircraft class edited
													</p>
												</div>
												<div className="d-flex justify-content-start">
													<div
														style={{
															margin: '5px',
															width: '15px',
															height: '15px',
															border: 'solid blue 2px',
															borderRadius: '5px',
															backgroundColor: 'blue'
														}}
													></div>
													<p className="my-0">&gt; confidence threshold</p>
												</div>
												<div className="d-flex justify-content-start">
													<div
														style={{
															margin: '5px',
															width: '15px',
															height: '15px',
															border: 'solid orange 2px',
															borderRadius: '5px',
															backgroundColor: 'orange'
														}}
													></div>
													<p className="my-0">&lt; confidence threshold</p>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>

							<div className="col-12 py-3 py-md-1 col-lg-6">
								<div
									className="row d-flex"
									style={{
										height: '40vh',
										//border: 'solid pink 1px'
									}}
								>
									<div className="col">
										<AircraftPlot
											aircraftClasses={aircraftList}
											selectedClass={selectedAircraftClass}
										/>
									</div>
								</div>
								<div
									style={{
										height: '43vh'
										// border: 'solid black 1px'
									}}
									className="row d-flex align-items-end"
								>
									<div className="col" style={{ height: '100%' }}>
										<fieldset
											className="form-group p-3 border rounded-3"
											style={{ height: '95%' }}
										>
											<legend className="float-none w-auto px-3 my-0 fs-6">
												QC Aircraft Detection{' '}
											</legend>

											<div className="row">
												<div className="col">
													<label className="fw-bold">Scene Summary</label>
													<p></p>
													<AircraftSceneSummary
														aircraftClasses={aircraftList}
														selectedAircraftClass={selectedAircraftClass}
														setSelectedAircraftClass={setSelectedAircraftClass}
													/>
												</div>
												<div className="col d-flex flex-column">
													<div>
														<label className="fw-bold">
															Selected aircraft{' '}
															<i
																data-tooltip-id="zoomSelectedAircraft"
																onClick={(e) =>
																	selectedAircraft &&
																	setAircraftZoom(selectedAircraft.centerPoint)
																}
																className="ms-2 fa-solid fa-magnifying-glass-location"
															></i>
														</label>
														{selectedAircraft ? (
															<>
																<table
																	className="table"
																	id="selectedAircraftTable"
																>
																	<tbody>
																		<tr>
																			<td>
																				Class<sup>*</sup>
																			</td>
																			<td>
																				<select
																					className="form-select py-0 my-0"
																					id="change-class-aircraft"
																					aria-label="Default select example"
																					style={{
																						border: 'none',
																						backgroundColor: '#f8f9fa'
																					}}
																					value={selectedAircraftClass.class_id}
																					data-tooltip-id="changeAircraftClass"
																					// onChange={(e) =>
																					// 	setSelectedAircraftClass({
																					// 		class_name:
																					// 			aircraftList.filter(aircraft => aircraft.class_id === e.target.value)[0].class_name,
																					// 		class_id:
																					// 			e.target.value
																					// 	})
																					// }
																					onChange={(e)=>handleChange(e)}
																				>
																					<option selected>
																						Identify class of object
																					</option>
																					{aircraftList.map((aircraft, idx) => (
																						<>
																							<option
																								key={idx}
																								value={aircraft['class_id']}
																								selected={
																									selectedAircraft.aircraft[
																										'class_id'
																									] === aircraft['class_id']
																								}
																								
																							>
																								{aircraft['class_name']}
																							</option>
																						</>
																					))}
																				</select>
																			</td>
																		</tr>
																		<tr>
																			<td>Class ID</td>
																			<td className="text-center">
																				{selectedAircraft.aircraft['class_id']}
																			</td>
																		</tr>
																		<tr>
																			<td>Confidence</td>
																			<td className="text-center">{`${(
																				selectedAircraft.aircraft[
																					'confidence'
																				] * 100
																			).toFixed(0)}%`}</td>
																		</tr>
																	</tbody>
																</table>

																<Tooltip
																	id="changeAircraftClass"
																	place="top"
																	effect="solid"
																>
																	Changing the aircraft class will set the
																	<br></br>
																	confidence to 100% as the class has been
																	<br></br>
																	manually identified and confirmed
																</Tooltip>
															</>
														) : (
															<p>No aircraft selected</p>
														)}
													</div>

													<div className="mt-auto">
														<QCingFormAircraft
															onAircraftQCSubmit={onAircraftQCSubmit}
															currentCapture={currentCapture}
															imageData={imageData2}
															skipCapture={skipCapture}
															drawnItemsValid={drawnItemsValid}
															aircraftFormValue={aircraftFormValue}
															setAircraftFormValue={setAircraftFormValue}
															aircraftCase={aircraftCase}
															activeAircraft={activeAircraft}
															setActiveAircraft={setActiveAircraft}
														/>
													</div>
												</div>
											</div>
										</fieldset>
									</div>
									{/* <div
										className="col-6"
										style={{ height: '100%', border: 'solid green 1px' }}
									>
										<QCingFormAircraft
											onAircraftQCSubmit={onAircraftQCSubmit}
											currentCapture={currentCapture}
											imageData={imageData2}
											skipCapture={skipCapture}
											drawnItemsValid={drawnItemsValid}
											aircraftFormValue={aircraftFormValue}
											setAircraftFormValue={setAircraftFormValue}
											aircraftCase={aircraftCase}
											activeAircraft={activeAircraft}
											setActiveAircraft={setActiveAircraft}
										/>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Col>
		</>
	);
};
