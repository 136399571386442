import React, { useEffect, useState, useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Col } from 'reactstrap';
import { Tooltip } from 'react-tooltip';
import SendCapsBackModal from '../Dashboard/SendCapsBackModal';
import Breadcrumb from '../Breadcrumb';
import userCaptures from '../../helpers/usersCaptures';
import { endpointContext } from '../../contexts/endpointContext';
import Skeleton from 'react-loading-skeleton';
import { pipelineContext } from '../../contexts/pipelineContext';
import useGainsQCApi from '../../services/useGainsQCApi';
import exportConfig from '../../config';
import CapturesTable from '../CapturesTable';
import { smelterFields, stockpileFields } from '../QC/tableFields';
import checkAllCapsAreAssignedToUser from '../../helpers/endpointChecks';

const UsersCaptures = (props) => {
	const endpointContextUserCaps = useContext(endpointContext);
	const pipelineContextUserCaps = useContext(pipelineContext);

	const [qcType, setQCType] = useState('smelters');
	const [qcCaps, setQCCaps] = useState([]);
	const [uploadCaps, setUploadCaps] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();

	const navigate = useNavigate();

	const userCapsSmelters = useGainsQCApi();
	const userCapsStockpiles = useGainsQCApi();

	useEffect(() => {
		document.title = 'GAINS QC | Your captures';
		// fetchCapturesInfo();
	}, []);

	useEffect(() => {
		setLoading(true);
		fetchCapturesInfo();
	}, [qcType]);

	const fetchCapturesInfo = async () => {

		let userHandle = `${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`

		// if (qcType === 'smelters') {
		// 	await userCapsSmelters.makeRequest(
		// 		`${exportConfig.baseUrl}/smelters/captures?qcState=3&prodStates=-1,0,1,2,5&commodities=3&qcUser=${userHandle}`,
		// 		'GET',
		// 		null,
		// 		`your smelter captures`,
		// 		1000,
		// 		10000
		// 	);
		// }
	};

	console.log(props)

	// useEffect(() => {
	// 	let user = props.authProps.userAttributes;
	// 	if (qcType === 'smelters') {
	// 		if (makeRequestSmeltersQC3.data && !makeRequestSmeltersQC3.error) {
	// 			userCaptures({
	// 				user: user,
	// 				allCaps: makeRequestSmeltersQC3.data.data,
	// 				setState: setQCCaps
	// 			});
	// 		}

	// 		if (makeRequestSmeltersQC4.data && !makeRequestSmeltersQC4.error) {
	// 			userCaptures({
	// 				user: user,
	// 				allCaps: makeRequestSmeltersQC4.data.data,
	// 				setState: setUploadCaps
	// 			});
	// 		}
	// 	}

	// 	if (qcType === 'stockpiles') {
	// 		if (makeRequestStockpilesQC3.data && !makeRequestStockpilesQC3.error) {
	// 			userCaptures({
	// 				user: user,
	// 				allCaps: makeRequestStockpilesQC3.data.data,
	// 				setState: setQCCaps
	// 			});
	// 		}

	// 		if (makeRequestStockpilesQC4.data && !makeRequestStockpilesQC4.error) {
	// 			userCaptures({
	// 				user: user,
	// 				allCaps: makeRequestStockpilesQC4.data.data,
	// 				setState: setUploadCaps
	// 			});
	// 		}
	// 	}

	// 	setLoading(false);
	// }, [
	// 	makeRequestSmeltersQC3.data,
	// 	makeRequestSmeltersQC4.data,
	// 	makeRequestStockpilesQC3.data,
	// 	makeRequestStockpilesQC4.data
	// ]);

	const moveToUpload = (uploadCaps) => {
		pipelineContextUserCaps.setStraightToUpload(true);
		pipelineContextUserCaps.setCapsInPipeline(uploadCaps);
		pipelineContextUserCaps.setComm(qcType);
		pipelineContextUserCaps.setProgressBar({
			width: 6 * (100 / 7),
			title: 'Upload Captures'
		});
		pipelineContextUserCaps.setQCPerc(100);
		navigate('/qc');
	};

	const moveToQC = (capsQCUser) => {
		//checkAllCapsAreAssignedToUser(capsQCUser)

		pipelineContextUserCaps.setStraightToQC(true);
		pipelineContextUserCaps.setCapsInPipeline(capsQCUser);
		pipelineContextUserCaps.setComm(qcType);
		pipelineContextUserCaps.setQCInProgress(true);
		navigate('/qc');
	};

	const userCapturesProps = [
		{
			title: 'Home',
			currentPage: false,
			link: '/',
			afterCurrent: false,
			warning: false
		},
		{
			title: 'User captures',
			currentPage: true,
			link: '/user_capture',
			afterCurrent: false,
			warning: false
		}
	];

	console.log(userCapsSmelters)
	console.log(userCapsStockpiles)

	return (
		<h1></h1>
		// <>
		// 	{props.authProps.isAuthenticated ? (
		// 		<>
		// 			{/* <SendCapsBackModal
		// 				user={props.authProps.userAttributes}
		// 				capturesCurrentlyInQC={qcCaps}
		// 				qcCat={qcType}
		// 			/> */}
		// 			<Col className="px-0">
		// 				<div className="contentContainer" style={{ height: 'auto' }}>
		// 					<Breadcrumb props={userCapturesProps} marginTop={22} />
		// 					<hr></hr>

		// 					<div className="row">
		// 						<div className="col pb-3">
		// 							<div class="form-check-dashboard-qcinfo pt-1">
		// 								<input
		// 									className="form-check-input-dashboard-qcinfo"
		// 									type="radio"
		// 									name="flexRadioDefault"
		// 									id="flexRadioDefault1"
		// 									checked={qcType === 'smelters'}
		// 									onClick={(e) => setQCType('smelters')}
		// 								></input>
		// 								<label
		// 									class="form-check-label-dashboard-qcinfo"
		// 									for="flexRadioDefault1"
		// 									data-tooltip-id="dashboard-qcinfo-smelters"
		// 								>
		// 									<i className="fa-solid fa-city"></i>
		// 								</label>
		// 							</div>
		// 							<div className="form-check-dashboard-qcinfo">
		// 								<input
		// 									className="form-check-input-dashboard-qcinfo"
		// 									type="radio"
		// 									name="flexRadioDefault"
		// 									checked={qcType === 'stockpiles'}
		// 									id="flexRadioDefault2"
		// 									onClick={(e) => setQCType('stockpiles')}
		// 								></input>
		// 								<label
		// 									className="form-check-label-dashboard-qcinfo"
		// 									for="flexRadioDefault2"
		// 									data-tooltip-id="dashboard-qcinfo-stockpiles"
		// 								>
		// 									<i class="fa-solid fa-cubes-stacked"></i>
		// 								</label>
		// 							</div>
		// 						</div>

		// 						<h3>
		// 							Your <strong>{qcType}</strong> captures
		// 						</h3>
		// 					</div>

		// 					<h6>
		// 						Use this page to view captures which are assigned to you in the
		// 						database
		// 					</h6>

		// 					<div className="row mt-4 mx-2">
		// 						{endpointContextUserCaps.loading ? (
		// 							<h4 style={{ display: 'flex', alignItems: 'center' }}>
		// 								<strong style={{ display: 'inline', alignItems: 'center' }}>
		// 									<Skeleton width={30} />
		// 								</strong>
		// 								<span
		// 									style={{
		// 										display: 'inline',
		// 										alignItems: 'center',
		// 										paddingLeft: '6px'
		// 									}}
		// 								>
		// 									captures in {qcType} QC
		// 								</span>
		// 							</h4>
		// 						) : (
		// 							<h4>
		// 								<strong>{qcCaps.length}</strong> captures in {qcType} QC
		// 							</h4>
		// 						)}

		// 						<div>
		// 							{qcCaps.length !== 0 && (
		// 								<p>
		// 									These captures are assigned to you and nobody else can
		// 									access them to QC. Please either continue with QC or
		// 									release them from your pipeline.
		// 								</p>
		// 							)}
		// 						</div>

		// 						{loading ? (
		// 							<div style={{ height: '25vh' }}>
		// 								<Skeleton width={'100%'} height={'100%'} />
		// 							</div>
		// 						) : (
		// 							<>
		// 								{qcType === 'smelters' && (
		// 									<CapturesTable
		// 										fields={smelterFields}
		// 										captures={qcCaps}
		// 										qcCat={qcType}
		// 										tableHeight={'400px'}
		// 									/>
		// 								)}

		// 								{qcType === 'stockpiles' && (
		// 									<CapturesTable
		// 										fields={stockpileFields}
		// 										captures={qcCaps}
		// 										qcCat={qcType}
		// 										tableHeight={'400px'}
		// 									/>
		// 								)}

		// 								{qcCaps.length !== 0 && (
		// 									<div className="d-flex justify-content-end py-4">
		// 										<button
		// 											data-tooltip-id="dashboard-qc-caps"
		// 											className="btn interactButton mx-2"
		// 											style={{ width: '160px', height: '40px' }}
		// 											onClick={(e) => moveToQC(qcCaps)}
		// 										>
		// 											QC {qcCaps.length} captures
		// 										</button>
		// 										<button
		// 											data-tooltip-id="dashboard-remove-caps"
		// 											className="btn interactButtonSecondary mx-2"
		// 											style={{ width: '200px', height: '40px' }}
		// 											data-bs-toggle="modal"
		// 											data-bs-target="#sendCapsBackModal"
		// 										>
		// 											Remove {qcCaps.length} captures
		// 										</button>
		// 									</div>
		// 								)}
		// 							</>
		// 						)}
		// 					</div>

		// 					<div
		// 						className="row mt-5 mx-2"
		// 						style={{ height: uploadCaps.length > 0 ? '25%' : '5vh' }}
		// 					>
		// 						{endpointContextUserCaps.loading ? (
		// 							<h4 style={{ display: 'flex', alignItems: 'center' }}>
		// 								<strong style={{ display: 'inline', alignItems: 'center' }}>
		// 									<Skeleton width={30} />
		// 								</strong>
		// 								<span
		// 									style={{
		// 										display: 'inline',
		// 										alignItems: 'center',
		// 										paddingLeft: '6px'
		// 									}}
		// 								>
		// 									captures to upload
		// 								</span>
		// 							</h4>
		// 						) : (
		// 							<h4>
		// 								<strong>{uploadCaps.length}</strong> captures to upload
		// 							</h4>
		// 						)}

		// 						<div>
		// 							<p>
		// 								These captures have been QC'd and are ready to upload to the
		// 								database.
		// 							</p>
		// 						</div>
		// 						{loading ? (
		// 							<div style={{ height: '300px' }}>
		// 								<Skeleton width={'100%'} height={'100%'} />
		// 							</div>
		// 						) : (
		// 							<>
		// 								{qcType === 'smelters' && (
		// 									<CapturesTable
		// 										fields={smelterFields}
		// 										captures={uploadCaps}
		// 										qcCat={qcType}
		// 										height={'400px'}
		// 									/>
		// 								)}

		// 								{qcType === 'stockpiles' && (
		// 									<CapturesTable
		// 										fields={stockpileFields}
		// 										captures={uploadCaps}
		// 										qcCat={qcType}
		// 										height={'400px'}
		// 									/>
		// 								)}

		// 								{uploadCaps.length !== 0 && (
		// 									<div className="d-flex justify-content-end py-4">
		// 										<button
		// 											data-tooltip-id="dashboard-qc-caps"
		// 											className="btn interactButton mx-2"
		// 											style={{ width: '180px', height: '40px' }}
		// 											onClick={(e) => moveToUpload(uploadCaps)}
		// 										>
		// 											Upload {uploadCaps.length} captures
		// 										</button>
		// 									</div>
		// 								)}
		// 							</>
		// 						)}
		// 					</div>
		// 				</div>

		// 				<Tooltip id="back-to-homepage">back to homepage</Tooltip>
		// 			</Col>

		// 			<Tooltip id="dashboard-qcinfo-stockpiles">stockpiles</Tooltip>
		// 			<Tooltip id="dashboard-qcinfo-smelters">smelters</Tooltip>
		// 		</>
		// 	) : (
		// 		<Navigate to="/login" />
		// 	)}
		// </>
	);
};

export default UsersCaptures;
