import React, { useEffect, useRef, useContext } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-rastercoords';
import 'leaflet-easybutton/src/easy-button.css';
import 'leaflet-easybutton';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';
import { findCollectionCentroid } from '../../../helpers/leafletHelpers';
import { pipelineContext } from '../../../contexts/pipelineContext';
//import exampleImage from '../../images/20231010_103830_ssc10_u0001_analytic_SR_clip_proj.png';
import * as turf from '@turf/turf';

// Override Leaflet deprecated _flat method with the recommended isFlat method
L.Polyline._flat = L.LineUtil.isFlat;

export const QCImageAircraft = ({
	modalId,
	currentCapture,
	selectedAircraft,
	setSelectedAircraft,
	confidenceThreshold,
	aircraftGeojsons,
	setSelectedAircraftClass,
	selectedAircraftClass,
	aircraftZoom
}) => {
	const pipelineContextQCImage = useContext(pipelineContext);

	const mapRef = useRef(null);
	const imageLayerRef = useRef(null);
	const originalLayerRef = useRef(new L.FeatureGroup());

	const aircraftCase = 2;

	useEffect(() => {
		if (aircraftZoom) {
			console.log(aircraftZoom);
			mapRef.current.setView(
				[
					aircraftZoom.geometry.coordinates[1],
					aircraftZoom.geometry.coordinates[0]
				],
				18
			);
		}
	}, [aircraftZoom]);

	useEffect(() => {
		console.log(selectedAircraftClass);
		console.log(selectedAircraft)

		/**
		 * highlights stockpile on map when hovering over stockpile in list
		 * in StockpilesGeojsonsList
		 */

		if (selectedAircraftClass) {
			try {
				mapRef.current.eachLayer(function (layer) {
					if (layer.customName === 'drawnItemsLayer') {
						layer.eachLayer(function (layer2) {
							if (layer2.hasOwnProperty('feature')) {
								if (
									layer2.feature.properties.class_id ===
									selectedAircraftClass.class_id
								) {
									layer2.setStyle({ weight: 3, opacity: 0.8 });
								} else {
									layer2.setStyle({ weight: 1, opacity: 0.5 });
								}
							}
						});
					}
				});
			} catch (e) {
				console.log(e);
			}
		} else {
			try {
				mapRef.current.eachLayer(function (layer) {
					if (layer.customName === 'drawnItemsLayer') {
						layer.eachLayer(function (layer2) {
							layer2.setStyle({ weight: 1, opacity: 0.5 });
						});
					}
				});
			} catch (e) {}
		}
	}, [selectedAircraftClass]);

	useEffect(() => {
		if (pipelineContextQCImage.comm === 'aircraft') {
			if (aircraftGeojsons) {
				try {
					// add unique id to aircraft
					let idCounter = 1;
					for (let i in aircraftGeojsons[0].features) {
						aircraftGeojsons[0].features[i].properties.id = idCounter;
						idCounter++;
					}

					const convertedFeatureCollection = aircraftGeojsons[0];
					const centerPoint = findCollectionCentroid(
						convertedFeatureCollection
					);

					//const bounds = getBoundingBox(JSON.parse(imageData.data.aoi));
					let currentlyEditingLayer = null;
					// create map ONCE
					if (!mapRef.current) {
						mapRef.current = L.map(`map-${modalId}`, {
							zoomControl: false
						}).setView(centerPoint, 18);
						L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
							lang: 'en',
							opacity: 0.2
						}).addTo(mapRef.current);
						//mapRef.current.fitBounds(bounds, { padding: [0, 0] });
						mapRef.current.setMaxZoom(35);
						var recenterBtn = L.easyButton({
							states: [
								{
									stateName: 'Zoom to image',
									icon: 'fa-solid fa-location-crosshairs',
									title: 'Relocate map to image', // Tooltip text
									onClick: function () {
										mapRef.current.setView(centerPoint, 20);
									}
								}
							]
						}).addTo(mapRef.current);

						// image layer ONCE
						// if (!imageLayerRef.current) {
						// 	try {
						// 		const imgUrl = exampleImage;

						// 		let imageBounds = L.latLngBounds([
						// 			// [29.521354323037976, 52.557141644600684],
						// 			// [29.558232971447964, 52.61755648505539]
						// 			[29.521352110002855, 52.55713916919134],
 						// 			[29.55823544685731, 52.61755864305871]
						// 		]);

						// 		imageLayerRef.current = L.imageOverlay(imgUrl, imageBounds).addTo(
						// 			mapRef.current
						// 		);

						// 		mapRef.current.fitBounds(imageBounds, {
						// 			padding: [0, 0] // No padding around the image
						// 		});

						// 		imageLayerRef.current.on('error', (error) => {
						// 			console.error('Error loading image:', error);
						// 		});
						// 		imageLayerRef.current.setOpacity(1);
						// 	} catch (e) {
						// 		console.log(e);
						// 	}
						// }

						const drawnItems = new L.featureGroup();

						// const drawControl = new L.Control.Draw({
						// 	edit: {
						// 		featureGroup: drawnItems
						// 	}
						// });
						// mapRef.current.addControl(drawControl);

						let currentlyEditingLayer = null;
						let currentlySelectedLayer = null;

						drawnItems.customName = 'drawnItemsLayer';
						mapRef.current.addLayer(drawnItems);

						let layerIdCounter = 1;
						let geojsonData = convertedFeatureCollection.features;

						if (originalLayerRef.current.getLayers().length === 0) {
							if (aircraftCase === 2) {
								const calcPolygonArea = (feature, layer) => {
									try {
										var turfPolygon = turf.polygon(
											feature.geometry.coordinates
										);
										var area = turf.area(turfPolygon);

										return area;
									} catch (e) {
										console.log(e);
									}
								};

								const handleLayerEdit = (event, type) => {
									console.log(event);
									const layerId = event.target.feature.properties.id;
									const editedLayer = event.target;

									editedLayer.setStyle({ color: 'green' });
									editedLayer.unbindTooltip()

									

									if (editedLayer instanceof L.Rectangle) {
										const newBounds = editedLayer.getBounds(); // Get the new bounds

										// Convert bounds to GeoJSON Polygon (rectangle)
										const sw = newBounds.getSouthWest();
										const ne = newBounds.getNorthEast();
										const nw = new L.LatLng(ne.lat, sw.lng);
										const se = new L.LatLng(sw.lat, ne.lng);

										try {
											let lastList =
												aircraftGeojsons[aircraftGeojsons.length - 1].features;
											console.log(lastList);
											let thisAircraft = lastList.filter(
												(feature) => feature.properties.id === layerId
											)[0];
											console.log(thisAircraft);
											let updatedAircraft = thisAircraft;

											updatedAircraft.geometry.coordinates = [
												[
													[sw.lng, sw.lat],
													[nw.lng, nw.lat],
													[ne.lng, ne.lat],
													[se.lng, se.lat],
													[sw.lng, sw.lat]
												]
											];
											updatedAircraft.properties.confidence = 1;
											updatedAircraft.properties.status = 'Edited';
											console.log(updatedAircraft);

											let removeOldFeatureList = lastList.filter(
												(item) => item.properties.id !== layerId
											);
											console.log(removeOldFeatureList);
											removeOldFeatureList.push(updatedAircraft);
											console.log(removeOldFeatureList);
										} catch (e) {
											console.log(e);
										}

										// feature.geometry = {
										//   type: "Polygon",
										//   coordinates: [[
										// 	[sw.lng, sw.lat],
										// 	[nw.lng, nw.lat],
										// 	[ne.lng, ne.lat],
										// 	[se.lng, se.lat],
										// 	[sw.lng, sw.lat] // Closing the polygon
										//   ]]
										// };
									}

									let area = calcPolygonArea(editedLayer.feature, editedLayer);

									editedLayer.bindTooltip(
										`<strong>Class:</strong>  ${
											editedLayer.feature.properties['class_name']
										}<br><strong>Area:</strong>  ${area.toFixed(
											0
										)} m<sup>2</sup><br><strong>Confidence:</strong>  ${(
											editedLayer.feature.properties['confidence'] * 100
										).toFixed(0)}%<br><strong>Status: </strong>geometry edited`
									);

									// setAircraftGeojsons((prevState) => {
									// 	// Clone the last list to avoid mutation

									// 	try {
									// 		console.log(prevState)
									// 		const lastList =
									// 			prevState.length > 0
									// 				? [...prevState[prevState.length - 1]]
									// 				: [];

									// 		console.log(lastList);
									// 	} catch (e) {
									// 		console.log(e);
									// 	}

									// 	// Assuming you know the id of the layerObj you want to edit
									// 	//const layerIdToEdit = layer.feature.id;
									// });
								};

								// Add each feature from the GeoJSON layer to the FeatureGroup and make it editable
								var geojsonLayer = L.geoJSON(geojsonData, {
									onEachFeature: function (feature, layer) {
										// Check if the feature is a Polygon with 5 coordinates (including closure)
										if (
											feature.geometry.type === 'Polygon' &&
											feature.geometry.coordinates[0].length === 5
										) {
											// Extract the bounds from the polygon's coordinates
											const coordinates = feature.geometry.coordinates[0];

											const latLngs = coordinates.map(function (coord) {
												return [coord[1], coord[0]]; // Convert [lng, lat] to [lat, lng]
											});

											// Create bounds using the southwest and northeast corners
											const bounds = L.latLngBounds(latLngs);

											// Create an L.Rectangle from the bounds
											const rectangle = L.rectangle(bounds);
											rectangle.feature = feature;

											var center = turf.center(rectangle.feature);

											if (
												rectangle.feature.properties.confidence <
												confidenceThreshold / 100
											) {
												rectangle.setStyle({
													color: 'orange',
													weight: 1,
													fillOpacity: '0.2'
												});
											} else {
												rectangle.setStyle({
													color: 'blue',
													weight: 1,
													fillOpacity: '0.2'
												});
											}

											// Enable editing on double-click
											rectangle.on('click', function () {
												rectangle.setStyle({ fillOpacity: 0.5 });
												currentlySelectedLayer = rectangle;

												setSelectedAircraft({
													aircraft: rectangle.feature.properties,
													centerPoint: center
												});
												setSelectedAircraftClass({
													class_name: rectangle.feature.properties.class_name,
													class_id: rectangle.feature.properties.class_id
												});
												//enableButton(deleteBtn);
												//enableButton(simplifyBtn);
											});

											// Enable editing on double-click
											rectangle.on('dblclick', function () {
												currentlyEditingLayer = rectangle;
												currentlySelectedLayer = rectangle;
												rectangle.editing.enable();
												setSelectedAircraftClass({
													class_name: rectangle.feature.properties.class_name,
													class_id: rectangle.feature.properties.class_id
												});
												setSelectedAircraft({
													aircraft: rectangle.feature.properties,
													centerPoint: center
												});
												mapRef.current.setView(
													[
														center.geometry.coordinates[1],
														center.geometry.coordinates[0]
													],
													mapRef.current.getZoom()
												);
												//enableButton(deleteBtn);
												//enableButton(simplifyBtn);
											});

											rectangle.on('mouseover', () => {
												rectangle.setStyle({ fillOpacity: 0.5 });
												//setActiveAircraft(layer.feature.id);
											});
											rectangle.on('mouseout', () => {
												if (!currentlySelectedLayer) {
													rectangle.setStyle({ fillOpacity: 0.2 });
												}

												//setActiveAircraft(null);
											});

											rectangle.on('dragend', handleLayerEdit);
											rectangle.on('edit', handleLayerEdit);
											rectangle.on('update', handleLayerEdit);

											let area = calcPolygonArea(feature, rectangle);

											// layer.bindTooltip(`Stockpiles ${layerIdCounter}`);
											rectangle.bindTooltip(
												`<strong>Class:</strong>  ${
													rectangle.feature.properties['class_name']
												}<br><strong>Area:</strong>  ${area.toFixed(
													0
												)} m<sup>2</sup><br><strong>Confidence:</strong>  ${(
													rectangle.feature.properties['confidence'] * 100
												).toFixed(0)}%`
											);
											layerIdCounter++;

											// Add the rectangle to the drawnItems FeatureGroup
											drawnItems.addLayer(rectangle);
										}
									}
								});
							}
						}
						originalLayerRef.current.addLayer(drawnItems);

						const handleMapClick = (e) => {
							// Function to convert FeatureCollection to MultiPolygon
							function convertToMultiPolygon(featureCollection) {
								
								var multiPolygonCoordinates = [];

								featureCollection.features.forEach(function (feature) {
									if (feature.geometry.type === 'Polygon') {
										multiPolygonCoordinates.push(feature.geometry.coordinates);
									}
								});

								var multiPolygon = {
									type: 'Feature',
									geometry: {
										type: 'MultiPolygon',
										coordinates: multiPolygonCoordinates
									},
									properties: {} // Optional: Merge or manage properties if needed
								};

								return multiPolygon;
							}

							// Convert the FeatureCollection into a MultiPolygon
							var multiPolygon = convertToMultiPolygon(
								convertedFeatureCollection
							);

							var latlng = e.latlng;
							var isInside = false;
							var turfPoint = turf.point([latlng.lng, latlng.lat]);

							if (turf.booleanPointInPolygon(turfPoint, multiPolygon)) {
								console.log('inside');
							} else {
								setSelectedAircraftClass(null)
								setSelectedAircraft(null);
								try {
									if (currentlySelectedLayer) {
										currentlySelectedLayer.setStyle({ fillOpacity: 0.2 });
										currentlySelectedLayer.editing.disable();
										currentlySelectedLayer = null;
									}
								} catch (e) {
									console.log(e);
								}

								try {
									if (currentlyEditingLayer) {
										currentlyEditingLayer.setStyle({ fillOpacity: 0.2 });
										currentlyEditingLayer.editing.disabled();
										currentlyEditingLayer = null;
									}
								} catch (e) {
									console.log(e);
								}
							}
						};
						mapRef.current.on('click', handleMapClick);
					}
				} catch (e) {
					console.log(e);
				}

				originalLayerRef.current.eachLayer(function (layer) {
					if (
						layer.customName === 'drawnItemsLayer' &&
						!selectedAircraftClass
					) {
						let aircraftLayers = layer.getLayers();
						for (let i in aircraftLayers) {
							if (
								aircraftLayers[i].feature.properties.confidence <
								confidenceThreshold / 100
							) {
								aircraftLayers[i].setStyle({
									color: 'orange',
									fillOpacity: '0.2'
								});
							} else {
								if (
									!aircraftLayers[i].feature.properties.hasOwnProperty(
										'status'
									) &&
									aircraftLayers[i].feature.properties.status != 'edited'
								) {
									aircraftLayers[i].setStyle({
										color: 'blue',
										fillOpacity: '0.2'
									});
								}
							}
						}
					}
				});
			}
		}
	}, [currentCapture, confidenceThreshold]);

	return (
		<div className="QCImage-map-general-div-aircraft">
			<div className="QCImage-map-absolute-div">
				<div
					className="QCImage-leaflet-map-aircraft"
					id={`map-${modalId}`}
				></div>
			</div>
		</div>
	);
};
