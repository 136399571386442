import React from 'react';
import { Navigate } from 'react-router-dom';

export const Info = (props) => {
	return (
		<>
			{props.authProps.isAuthenticated ? (
				<>
					<div className="px-0 col" id="secondColumn">
						<div className="contentContainer">
							<div style={{ marginTop: '58px' }}>
								<hr className="container-hr"></hr>

								{/* <SmelterInfoModal 
								data={makeRequestSmelters.data}
								 /> */}

								<h3>QC Info</h3>

								<div
									style={{
										border: 'solid orange 1px',
										position: 'relative',
										height: '100px'
									}}
								>
									<div
										class="progress"
										style={{
											border: 'solid 1px red',
											position: 'absolute',
											top: '0',
											left: '0',
                                            width: '500px'
										}}
									>
										<div
											className="progress-bar progress-bar-striped progress-bar-animated"
											role="progressbar"
											style={{ width: '20%' }}
											aria-valuenow="10"
											aria-valuemin="0"
											aria-valuemax="100"
										>
											<i className="fa-solid fa-images fa-xl m-4"></i>
                                          
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>{' '}
				</>
			) : (
				<Navigate to="/login" />
			)}
		</>
	);
};
