export const aircraftFile = {
    "type": "FeatureCollection",
    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
    "features": [
    { "type": "Feature", "properties": { "class_id": 0.0, "class_name": "C130 Hercules", "confidence": 0.80408906936645508 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.568791842000849, 29.552389227403864 ], [ 52.568791842000849, 29.552680283810552 ], [ 52.56838268391104, 29.552680283810552 ], [ 52.56838268391104, 29.552389227403864 ], [ 52.568791842000849, 29.552389227403864 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 3.0, "class_name": "Pilatus PC-6 porter", "confidence": 0.78863054513931274 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.569744950141867, 29.553174739877058 ], [ 52.569744950141867, 29.553303275628124 ], [ 52.569566883843038, 29.553303275628124 ], [ 52.569566883843038, 29.553174739877058 ], [ 52.569744950141867, 29.553174739877058 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 0.0, "class_name": "C130 Hercules", "confidence": 0.78175020217895508 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.568505779472638, 29.554230970162077 ], [ 52.568505779472638, 29.554499748828963 ], [ 52.568076427397109, 29.554499748828963 ], [ 52.568076427397109, 29.554230970162077 ], [ 52.568505779472638, 29.554230970162077 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 0.0, "class_name": "C130 Hercules", "confidence": 0.66490733623504639 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.568528010866501, 29.553860782186693 ], [ 52.568528010866501, 29.554133911063683 ], [ 52.568112808088159, 29.554133911063683 ], [ 52.568112808088159, 29.553860782186693 ], [ 52.568528010866501, 29.553860782186693 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 3.0, "class_name": "Pilatus PC-6 porter", "confidence": 0.6151282787322998 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.569214551083334, 29.553064321572041 ], [ 52.569214551083334, 29.553189762457077 ], [ 52.569024110759528, 29.553189762457077 ], [ 52.569024110759528, 29.553064321572041 ], [ 52.569214551083334, 29.553064321572041 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 3.0, "class_name": "Pilatus PC-6 porter", "confidence": 0.46615797281265259 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.569471435539768, 29.553652159676052 ], [ 52.569471435539768, 29.553769731950336 ], [ 52.569287922856901, 29.553769731950336 ], [ 52.569287922856901, 29.553652159676052 ], [ 52.569471435539768, 29.553652159676052 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 0.0, "class_name": "C130 Hercules", "confidence": 0.8248404860496521 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.570212969119105, 29.552208483843465 ], [ 52.570212969119105, 29.552559443222371 ], [ 52.569889770238227, 29.552559443222371 ], [ 52.569889770238227, 29.552208483843465 ], [ 52.570212969119105, 29.552208483843465 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 0.0, "class_name": "C130 Hercules", "confidence": 0.8405231237411499 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.573828428112137, 29.55195069105201 ], [ 52.573828428112137, 29.552251341482954 ], [ 52.57341472377685, 29.552251341482954 ], [ 52.57341472377685, 29.55195069105201 ], [ 52.573828428112137, 29.55195069105201 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 0.0, "class_name": "C130 Hercules", "confidence": 0.29514002799987793 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.575519967279909, 29.552085410189481 ], [ 52.575519967279909, 29.552362020261949 ], [ 52.575126217344142, 29.552362020261949 ], [ 52.575126217344142, 29.552085410189481 ], [ 52.575519967279909, 29.552085410189481 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 2.0, "class_name": "IL-76 Ilyushin (Candid)", "confidence": 0.37858852744102478 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.561275440111991, 29.548729875277726 ], [ 52.561275440111991, 29.549149301844135 ], [ 52.560632257330433, 29.549149301844135 ], [ 52.560632257330433, 29.548729875277726 ], [ 52.561275440111991, 29.548729875277726 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 10.0, "class_name": "Boeing 737", "confidence": 0.27489298582077026 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.569812255168195, 29.550506646113888 ], [ 52.569812255168195, 29.550821328598509 ], [ 52.569661606928726, 29.550821328598509 ], [ 52.569661606928726, 29.550506646113888 ], [ 52.569812255168195, 29.550506646113888 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 2.0, "class_name": "IL-76 Ilyushin (Candid)", "confidence": 0.58348220586776733 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.572981190085407, 29.550089354557819 ], [ 52.572981190085407, 29.55051583326086 ], [ 52.572644376075424, 29.55051583326086 ], [ 52.572644376075424, 29.550089354557819 ], [ 52.572981190085407, 29.550089354557819 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 0.0, "class_name": "C130 Hercules", "confidence": 0.56714218854904175 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.572981789749697, 29.551467731043953 ], [ 52.572981789749697, 29.551760210992324 ], [ 52.572661092189584, 29.551760210992324 ], [ 52.572661092189584, 29.551467731043953 ], [ 52.572981789749697, 29.551467731043953 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 10.0, "class_name": "Boeing 737", "confidence": 0.28198954463005066 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.570062263258826, 29.550527134417081 ], [ 52.570062263258826, 29.550816850682118 ], [ 52.569813323447683, 29.550816850682118 ], [ 52.569813323447683, 29.550527134417081 ], [ 52.570062263258826, 29.550527134417081 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 0.0, "class_name": "C130 Hercules", "confidence": 0.81634372472763062 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.574699599792147, 29.551618036920289 ], [ 52.574699599792147, 29.551898347113294 ], [ 52.574290011406582, 29.551898347113294 ], [ 52.574290011406582, 29.551618036920289 ], [ 52.574699599792147, 29.551618036920289 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 0.0, "class_name": "C130 Hercules", "confidence": 0.77340447902679443 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.573923265268505, 29.551134138057638 ], [ 52.573923265268505, 29.551426018794981 ], [ 52.573529827440694, 29.551426018794981 ], [ 52.573529827440694, 29.551134138057638 ], [ 52.573923265268505, 29.551134138057638 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 0.0, "class_name": "C130 Hercules", "confidence": 0.72459554672241211 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.575287446357031, 29.5492502760032 ], [ 52.575287446357031, 29.549611065706422 ], [ 52.574981032863711, 29.549611065706422 ], [ 52.574981032863711, 29.5492502760032 ], [ 52.575287446357031, 29.5492502760032 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 10.0, "class_name": "Boeing 737", "confidence": 0.610889732837677 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.57464958032687, 29.550795727586912 ], [ 52.57464958032687, 29.550954974036241 ], [ 52.574478777082113, 29.550954974036241 ], [ 52.574478777082113, 29.550795727586912 ], [ 52.57464958032687, 29.550795727586912 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 2.0, "class_name": "IL-76 Ilyushin (Candid)", "confidence": 0.45492663979530334 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.576034316820106, 29.550576905193164 ], [ 52.576034316820106, 29.551019624841768 ], [ 52.575584701258826, 29.551019624841768 ], [ 52.575584701258826, 29.550576905193164 ], [ 52.576034316820106, 29.550576905193164 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 10.0, "class_name": "Boeing 737", "confidence": 0.33418232202529907 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.573123259945184, 29.550107037175493 ], [ 52.573123259945184, 29.550476698707129 ], [ 52.57298178865431, 29.550476698707129 ], [ 52.57298178865431, 29.550107037175493 ], [ 52.573123259945184, 29.550107037175493 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 2.0, "class_name": "IL-76 Ilyushin (Candid)", "confidence": 0.87087756395339966 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.579160367204345, 29.548885228366252 ], [ 52.579160367204345, 29.549309860786202 ], [ 52.57868599754962, 29.549309860786202 ], [ 52.57868599754962, 29.548885228366252 ], [ 52.579160367204345, 29.548885228366252 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 2.0, "class_name": "IL-76 Ilyushin (Candid)", "confidence": 0.85187578201293945 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.577669295078906, 29.550060166061044 ], [ 52.577669295078906, 29.550481823880212 ], [ 52.577189564857562, 29.550481823880212 ], [ 52.577189564857562, 29.550060166061044 ], [ 52.577669295078906, 29.550060166061044 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 4.0, "class_name": "Lockheed P3 Orion", "confidence": 0.77822530269622803 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.578444743949461, 29.550418842208277 ], [ 52.578444743949461, 29.550713387818053 ], [ 52.578139437621644, 29.550713387818053 ], [ 52.578139437621644, 29.550418842208277 ], [ 52.578444743949461, 29.550418842208277 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 1.0, "class_name": "Su-24 (Fencer)", "confidence": 0.66238605976104736 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.577178744006901, 29.54956347934613 ], [ 52.577178744006901, 29.549736755582831 ], [ 52.577048971155506, 29.549736755582831 ], [ 52.577048971155506, 29.54956347934613 ], [ 52.577178744006901, 29.54956347934613 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 2.0, "class_name": "IL-76 Ilyushin (Candid)", "confidence": 0.4548327624797821 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.579317998986944, 29.54872987497555 ], [ 52.579317998986944, 29.549035316072967 ], [ 52.579045639293845, 29.549035316072967 ], [ 52.579045639293845, 29.54872987497555 ], [ 52.579317998986944, 29.54872987497555 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 2.0, "class_name": "IL-76 Ilyushin (Candid)", "confidence": 0.75345689058303833 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.581315556272259, 29.548731384636817 ], [ 52.581315556272259, 29.548979937249761 ], [ 52.580792278850382, 29.548979937249761 ], [ 52.580792278850382, 29.548731384636817 ], [ 52.581315556272259, 29.548731384636817 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 2.0, "class_name": "IL-76 Ilyushin (Candid)", "confidence": 0.5393519401550293 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.57975126557546, 29.548731669889065 ], [ 52.57975126557546, 29.548990034695883 ], [ 52.579318836159992, 29.548990034695883 ], [ 52.579318836159992, 29.548731669889065 ], [ 52.57975126557546, 29.548731669889065 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 2.0, "class_name": "IL-76 Ilyushin (Candid)", "confidence": 0.30352047085762024 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.580244848642415, 29.548729875277726 ], [ 52.580244848642415, 29.548821868523564 ], [ 52.579720113570964, 29.548821868523564 ], [ 52.579720113570964, 29.548729875277726 ], [ 52.580244848642415, 29.548729875277726 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 0.0, "class_name": "C130 Hercules", "confidence": 0.85255694389343262 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.580996063630948, 29.548055416808083 ], [ 52.580996063630948, 29.548330805644362 ], [ 52.580602533375668, 29.548330805644362 ], [ 52.580602533375668, 29.548055416808083 ], [ 52.580996063630948, 29.548055416808083 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 2.0, "class_name": "IL-76 Ilyushin (Candid)", "confidence": 0.84690588712692261 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.580174913436998, 29.548400625003826 ], [ 52.580174913436998, 29.548729874956667 ], [ 52.579692221759451, 29.548729874956667 ], [ 52.579692221759451, 29.548400625003826 ], [ 52.580174913436998, 29.548400625003826 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 0.0, "class_name": "C130 Hercules", "confidence": 0.84129363298416138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.580550618901739, 29.548229119374515 ], [ 52.580550618901739, 29.548527574435862 ], [ 52.580158392376148, 29.548527574435862 ], [ 52.580158392376148, 29.548229119374515 ], [ 52.580550618901739, 29.548229119374515 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 2.0, "class_name": "IL-76 Ilyushin (Candid)", "confidence": 0.74680119752883911 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.581272304144619, 29.548566021959253 ], [ 52.581272304144619, 29.548728243009364 ], [ 52.580771078474172, 29.548728243009364 ], [ 52.580771078474172, 29.548566021959253 ], [ 52.581272304144619, 29.548566021959253 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 11.0, "class_name": "Fokker F27 / 50", "confidence": 0.90742361545562744 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.58683114117386, 29.545563771428643 ], [ 52.58683114117386, 29.545786513547871 ], [ 52.586537534116502, 29.545786513547871 ], [ 52.586537534116502, 29.545563771428643 ], [ 52.58683114117386, 29.545563771428643 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 11.0, "class_name": "Fokker F27 / 50", "confidence": 0.90512639284133911 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.586854142355968, 29.545704190776306 ], [ 52.586854142355968, 29.545953185167626 ], [ 52.586593755702971, 29.545953185167626 ], [ 52.586593755702971, 29.545704190776306 ], [ 52.586854142355968, 29.545704190776306 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 11.0, "class_name": "Fokker F27 / 50", "confidence": 0.82333898544311523 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.586928559047209, 29.545406326649246 ], [ 52.586928559047209, 29.545560182852807 ], [ 52.586665465519587, 29.545560182852807 ], [ 52.586665465519587, 29.545406326649246 ], [ 52.586928559047209, 29.545406326649246 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 7.0, "class_name": "MIL Mi-8 / 17 Hip", "confidence": 0.64573365449905396 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.588822928144623, 29.544927538044561 ], [ 52.588822928144623, 29.545172495995693 ], [ 52.588659065232122, 29.545172495995693 ], [ 52.588659065232122, 29.544927538044561 ], [ 52.588822928144623, 29.544927538044561 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 11.0, "class_name": "Fokker F27 / 50", "confidence": 0.33307898044586182 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.586732123289224, 29.545254315911968 ], [ 52.586732123289224, 29.545489312470821 ], [ 52.586492876728713, 29.545489312470821 ], [ 52.586492876728713, 29.545254315911968 ], [ 52.586732123289224, 29.545254315911968 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 6.0, "class_name": "Fokker 100", "confidence": 0.79359817504882812 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.590715023080556, 29.544634204076946 ], [ 52.590715023080556, 29.544953219585526 ], [ 52.590474674189331, 29.544953219585526 ], [ 52.590474674189331, 29.544634204076946 ], [ 52.590715023080556, 29.544634204076946 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 2.0, "class_name": "IL-76 Ilyushin (Candid)", "confidence": 0.46966639161109924 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.562195091246423, 29.539224302640541 ], [ 52.562195091246423, 29.539626852487309 ], [ 52.561533026216438, 29.539626852487309 ], [ 52.561533026216438, 29.539224302640541 ], [ 52.562195091246423, 29.539224302640541 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 1.0, "class_name": "Su-24 (Fencer)", "confidence": 0.83935332298278809 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.56685188053266, 29.540818066935273 ], [ 52.56685188053266, 29.541045990129774 ], [ 52.566688281531377, 29.541045990129774 ], [ 52.566688281531377, 29.540818066935273 ], [ 52.56685188053266, 29.540818066935273 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 1.0, "class_name": "Su-24 (Fencer)", "confidence": 0.82452762126922607 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.56905548412535, 29.539309420068271 ], [ 52.56905548412535, 29.539505979415424 ], [ 52.568907720740988, 29.539505979415424 ], [ 52.568907720740988, 29.539309420068271 ], [ 52.56905548412535, 29.539309420068271 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 1.0, "class_name": "Su-24 (Fencer)", "confidence": 0.81110638380050659 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.568838645881584, 29.539393130724989 ], [ 52.568838645881584, 29.539593301655692 ], [ 52.568691739462665, 29.539593301655692 ], [ 52.568691739462665, 29.539393130724989 ], [ 52.568838645881584, 29.539393130724989 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 1.0, "class_name": "Su-24 (Fencer)", "confidence": 0.78622317314147949 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.568622210435748, 29.539483975709658 ], [ 52.568622210435748, 29.539671386437021 ], [ 52.568481365022762, 29.539671386437021 ], [ 52.568481365022762, 29.539483975709658 ], [ 52.568622210435748, 29.539483975709658 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 1.0, "class_name": "Su-24 (Fencer)", "confidence": 0.73251652717590332 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.568398611653251, 29.5395713517369 ], [ 52.568398611653251, 29.539754830576275 ], [ 52.568260690680162, 29.539754830576275 ], [ 52.568260690680162, 29.5395713517369 ], [ 52.568398611653251, 29.5395713517369 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 1.0, "class_name": "Su-24 (Fencer)", "confidence": 0.55785703659057617 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.569322724235583, 29.539228065764348 ], [ 52.569322724235583, 29.539417833448852 ], [ 52.569169978908612, 29.539417833448852 ], [ 52.569169978908612, 29.539228065764348 ], [ 52.569322724235583, 29.539228065764348 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 2.0, "class_name": "IL-76 Ilyushin (Candid)", "confidence": 0.25795233249664307 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.605955960980189, 29.539224303093796 ], [ 52.605955960980189, 29.53943741069746 ], [ 52.605320161215779, 29.53943741069746 ], [ 52.605320161215779, 29.539224303093796 ], [ 52.605955960980189, 29.539224303093796 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 9.0, "class_name": "Harbin Y-12", "confidence": 0.83835107088088989 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.584769116729262, 29.533300936504929 ], [ 52.584769116729262, 29.533447396008519 ], [ 52.584590550936824, 29.533447396008519 ], [ 52.584590550936824, 29.533300936504929 ], [ 52.584769116729262, 29.533300936504929 ] ] ] } },
    { "type": "Feature", "properties": { "class_id": 2.0, "class_name": "IL-76 Ilyushin (Candid)", "confidence": 0.2795727550983429 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 52.563980854818361, 29.526550477697185 ], [ 52.563980854818361, 29.526940147377537 ], [ 52.563476217225819, 29.526940147377537 ], [ 52.563476217225819, 29.526550477697185 ], [ 52.563980854818361, 29.526550477697185 ] ] ] } }
    ]
    }