import React, { useEffect } from 'react';
import useGainsQCApi from '../../services/useGainsQCApi';
import exportConfig from '../../config';
import Skeleton from 'react-loading-skeleton';

export const CaptureModal = ({ currentImage, setCurrentImage, siteType }) => {
	const {
		data: imageData,
		loading: loadingImageData,
		error: errorImageData,
		makeRequest: makeRequestImageData,
		resetData: resetImageData
	} = useGainsQCApi();

	useEffect(() => {
		console.log(currentImage);

		let siteTypeStr = exportConfig.siteTypesIds.filter(
			(site) => site.id === siteType
		)[0].name;

		if (
			currentImage &&
			currentImage.hasOwnProperty('stac_item_id') &&
			currentImage['stac_item_id'] !== null
		) {
			if (siteTypeStr === 'stockpiles') {
				makeRequestImageData(
					`${exportConfig.baseUrl}/${siteTypeStr}/capture_image?stacItemId=${currentImage.stac_item_id}&sensor=${currentImage.stac_collection}&siteId=${currentImage.site_id}&siteName=${currentImage.name}`,
					'GET',
					null,
					`capture ${currentImage.id}`
				);
			} else if (siteTypeStr === 'smelters') {
				makeRequestImageData(
					`${exportConfig.baseUrl}/${siteTypeStr}/capture_image?stacItemId=${currentImage.stac_item_id}&sensor=${currentImage.sensor}&siteId=${currentImage.site_id}`,
					'GET',
					null,
					`capture ${currentImage.id}`
				);
			}
		} else {
			console.log('Image does not have stac item id');
		}
	}, [currentImage]);

	console.log(imageData);

	return (
		<div
			className="modal modal-xl fade"
			id="captureModal"
			tabindex="-1"
			aria-labelledby="captureModalLabel"
			aria-hidden="true"
			style={{ marginTop: '80px' }}
		>
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						{currentImage ? (
							<h5 className="modal-title" id="exitQCModalLabel">
								Capture {currentImage.id}
							</h5>
						) : (
							<Skeleton width={30} />
						)}
					</div>

					<div
						class="modal-body"
						style={{ maxHeight: '800px', overflowY: 'auto' }}
					>
						{siteType === 1 ? (
							<>
								<div className="row">
									<div className="col" style={{ height: '400px' }}>
										{currentImage ? (
											<div
												style={{
													width: '100%',
													height: '100%',
													border: 'solid black 1px'
												}}
											></div>
										) : (
											<Skeleton width={100} height={100} />
										)}
									</div>
									<div className="col" style={{ height: '400px' }}>
										{currentImage ? (
											<div
												style={{
													width: '100%',
													height: '100%',
													border: 'solid black 1px'
												}}
											></div>
										) : (
											<Skeleton width={100} height={100} />
										)}
									</div>
									<div className="col" style={{ height: '400px' }}>
										{currentImage ? (
											<div
												style={{
													width: '100%',
													height: '100%',
													border: 'solid black 1px'
												}}
											></div>
										) : (
											<Skeleton width={100} height={100} />
										)}
									</div>
								</div>

								<div className="row my-3">
									<div className="col p-3" style={{ height: 'auto' }}>
										<h5 className="text-center">Site Info</h5>
										<table className="capture-id-table">
											<tbody>
												<tr>
													<td>Site ID</td>
													<td>
														{currentImage ? currentImage.site_id : <Skeleton />}
													</td>
												</tr>

												<tr>
													<td>Site Name</td>
													<td>
														{currentImage ? (
															currentImage.site_name
														) : (
															<Skeleton />
														)}
													</td>
												</tr>

												<tr>
													<td>Capacity</td>
													<td>56000</td>
												</tr>

												<tr>
													<td>Country</td>
													<td>Afghanistan</td>
												</tr>

												<tr>
													<td>Region</td>
													<td>Middle East</td>
												</tr>

												<tr>
													<td>Group</td>
													<td>-</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div className="col p-3" style={{ height: 'auto' }}>
										<h5 className="text-center">Capture Info</h5>
										<table className="capture-id-table">
											<tbody>
												<tr>
													<td>Capture ID</td>
													<td>
														{currentImage ? currentImage.id : <Skeleton />}
													</td>
												</tr>

												<tr>
													<td>Sensor</td>
													<td>
														{currentImage ? currentImage.sensor : <Skeleton />}
													</td>
												</tr>

												<tr>
													<td>Initial State</td>
													<td>
														{currentImage ? (
															<>
																{
																	exportConfig.qcStates.filter(
																		(state) =>
																			state.stateInt ===
																			currentImage.initial_state
																	)[0].stateString
																}
															</>
														) : (
															<Skeleton />
														)}
													</td>
												</tr>

												<tr>
													<td>Final State</td>
													<td>
														{currentImage ? (
															<>
																{
																	exportConfig.qcStates.filter(
																		(state) =>
																			state.stateInt === currentImage.state
																	)[0].stateString
																}
															</>
														) : (
															<Skeleton />
														)}
													</td>
												</tr>

												<tr>
													<td>Hotspot Count</td>
													<td>
														{currentImage ? (
															currentImage.hotspots
														) : (
															<Skeleton />
														)}
													</td>
												</tr>

												<tr>
													<td>Hotspot Pixel Count</td>
													<td>-</td>
												</tr>

												<tr>
													<td>Max Band Ratio</td>
													<td>
														{currentImage && currentImage.max_band_ratio ? (
															currentImage.max_band_ratio.toFixed(2)
														) : (
															<Skeleton />
														)}
													</td>
												</tr>

												<tr>
													<td>Invalid Data Frac</td>
													<td>
														{currentImage ? (
															currentImage.invalid_data_frac
														) : (
															<Skeleton />
														)}
													</td>
												</tr>
											</tbody>
										</table>
									</div>

									<div className="col p-3" style={{ height: 'auto' }}>
										<h5 className="text-center">QC Info</h5>
										<table className="capture-id-table">
											<tbody>
												<tr>
													<td>QC'd</td>
													{currentImage ? (
														<>
															{exportConfig.qcStages.filter(
																(stage) => stage.id === currentImage.qc_state
															)[0].qcd ? (
																<td>
																	<i className="fa-regular fa-check"></i>
																</td>
															) : (
																<td>
																	<i className="fa-regular fa-circle-xmark"></i>
																</td>
															)}
														</>
													) : (
														<td>
															<Skeleton />
														</td>
													)}
												</tr>

												<tr>
													<td>QC Stage</td>

													<td>
														{currentImage ? (
															<>
																{
																	exportConfig.qcStages.filter(
																		(stage) =>
																			stage.id === currentImage.qc_state
																	)[0].descript
																}
															</>
														) : (
															<Skeleton />
														)}
													</td>
												</tr>

												<tr>
													<td>QC Date</td>
													<td>
														{currentImage ? (
															<>
																{' '}
																{exportConfig.qcStages.filter(
																	(stage) => stage.id === currentImage.qc_state
																)[0].qcd
																	? currentImage.qc_date
																	: '-'}
															</>
														) : (
															<Skeleton />
														)}
													</td>
												</tr>

												<tr>
													<td>QC User</td>
													<td>
														{currentImage ? (
															<>
																{exportConfig.qcStages.filter(
																	(stage) => stage.id === currentImage.qc_state
																)[0].qcd
																	? currentImage.qc_user
																	: '-'}
															</>
														) : (
															<Skeleton />
														)}
													</td>
												</tr>

												<tr>
													<td>Uploaded (dev)</td>
													<td>
														{currentImage ? (
															<>
																{exportConfig.qcStages.filter(
																	(stage) => stage.id === currentImage.qc_state
																)[0].qcd
																	? 'uploaded'
																	: '-'}
															</>
														) : (
															<Skeleton />
														)}
													</td>
												</tr>

												<tr>
													<td>Uploaded (prod)</td>
													<td>
														{currentImage ? (
															<>
																{exportConfig.qcStages.filter(
																	(stage) => stage.id === currentImage.qc_state
																)[0].qcd
																	? 'uploaded'
																	: '-'}
															</>
														) : (
															<Skeleton />
														)}
													</td>
												</tr>

												<tr>
													<td>QC Report</td>
													<td>
														{currentImage ? (
															<>
																{exportConfig.qcStages.filter(
																	(stage) => stage.id === currentImage.qc_state
																)[0].qcd ? (
																	<i
																		id="captureImageHover"
																		className="fa-regular fa-file ms-2"
																	></i>
																) : (
																	'-'
																)}{' '}
															</>
														) : (
															<Skeleton />
														)}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>

								<div className="row my-3">
									<div className="col p-3">
										<h5 className="text-center">Capture Comments</h5>
										<table className="capture-id-table">
											<tbody>
												<tr>
													<td>Date</td>
													<td>Comment</td>
													<td>User</td>
												</tr>
												<tr>
													<td style={{ maxWidth: '60px' }}>2024-09-04</td>
													<td
														style={{
															whiteSpace: 'nowrap',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
															maxWidth: '350px'
														}}
													>
														Lorem ipsum dolor sit amet, consectetur adipiscing
														elit. Integer nec odio. Praesent libero. Sed cursus
														ante dapibus diam. Sed nisi.
													</td>
													<td>lucy.colley</td>
												</tr>

												<tr>
													<td style={{ maxWidth: '60px' }}>2024-09-04</td>
													<td
														style={{
															whiteSpace: 'nowrap',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
															maxWidth: '300px'
														}}
													>
														Curabitur sodales ligula in libero. Sed dignissim
														lacinia nunc. Curabitur tortor.
													</td>
													<td>george.begkas</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</>
						) : siteType === 3 ? (
							<div></div>
						) : (
							<div></div>
						)}
					</div>
					<div class="modal-footer">
						<button
							type="button"
							className="btn btn-dark"
							data-bs-dismiss="modal"
							onClick={() => setCurrentImage(null)}
						>
							Close
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
