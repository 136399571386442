import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Bar, Line } from 'react-chartjs-2';

import Plot from 'react-plotly.js';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	TimeScale,
	registerables
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import ReactSlider from 'react-slider';
import Skeleton from 'react-loading-skeleton';
import { aircraftPlotData } from '../../../helpers/aircraftFiles/aircraftPlotData';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	...registerables
);

const AircraftPlot = ({
	pixelPlotData,
	captureDate,
	aircraftClasses,
	selectedClass
}) => {
	const [data, setData] = useState({
		labels: [],
		datasets: [
			{
				label: 'Select an aircraft class to view data',
				data: [],
				fill: false,
				backgroundColor: 'rgba(75,192,192,0.2)',
				borderColor: 'rgba(75,192,192,1)',
				tension: 0.1
			}
		]
	});

	useEffect(() => {
		if (selectedClass) {
			let aircraftDates = aircraftPlotData.filter(
				(aircraft) => aircraft.class_name === selectedClass.class_name
			)[0].dates;
			let aircraftValues = aircraftPlotData.filter(
				(aircraft) => aircraft.class_name === selectedClass.class_name
			)[0].values;

			const average =
				aircraftValues.reduce((acc, num) => acc + num, 0) /
				aircraftValues.length;

			let avgLine = new Array(aircraftValues.length).fill(average);

			if (aircraftDates && aircraftValues) {
				setData({
					labels: aircraftDates,
					datasets: [
						{
							label: selectedClass.class_name,
							data: aircraftValues,
							fill: false,
							backgroundColor: 'rgba(75,192,192,0.2)',
							borderColor: 'rgba(75,192,192,1)',
							tension: 0.1
						},
						{
							label: `Average: ${average}`,
							data: avgLine,
							fill: false,
							backgroundColor: 'rgba(75,128,128,0.2)',
							borderColor: 'rgba(75,128,128,1)',
							borderWidth: 2,
							tension: 0.1,
							pointRadius: 0
						}
					]
				});
			}
		}
	}, [aircraftPlotData, selectedClass]);

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				title: {
					display: true,
					text: 'Date'
				}
			},
			y: {
				title: {
					display: true,
					text: 'Count'
				},
				min: 0,
				max: 25
			}
		},
		plugins: {
			legend: {
				labels: {
					// Set the point style to 'line'
					pointStyle: 'line', // Change to 'line'
					boxWidth: 2 // Adjust width if necessary
				}
			}
		}
	};

	if (!data) {
		return (
			<fieldset
				className="form-group p-0 p-md-3 border rounded-3"
				style={{ height: '95%', height: '250px' }}
			>
				<legend className="float-none w-auto px-3 my-0 fs-6">
					Pixel Count Data{' '}
					<button style={{ backgroundColor: '#f8f9fa', border: 'none' }}>
						<i
							data-tooltip-id="historic-plot-data"
							className="fa-solid fa-arrows-rotate ms-1 fa-sm"
							style={{ color: 'grey' }}
						></i>
					</button>
				</legend>

				<div style={{ position: 'relative', width: '100%' }}>
					<Skeleton
						style={{
							position: 'absolute',
							width: '100%',
							height: '100%'
						}}
					/>
				</div>
			</fieldset>
		);
	} else {
		return (
			<>
				<fieldset
					className="form-group p-3 border rounded-3"
					style={{ height: '95%' }}
				>
					<legend className="float-none w-auto px-3 my-0 fs-6">
						Aircraft Count Data{' '}
						<button style={{ backgroundColor: '#f8f9fa', border: 'none' }}>
							<i
								data-tooltip-id="historic-plot-data"
								className="fa-solid fa-arrows-rotate ms-1 fa-sm"
								style={{ color: 'rgb(98, 152, 64)' }}
							></i>
						</button>
					</legend>

					<div
						style={{ height: '100%', width: '100%' }}
						className="d-flex justify-content-center"
					>
						<Line
							data={data}
							options={options}
							// ref={chartRef}
						/>
					</div>
				</fieldset>
			</>
		);
	}
};

export default AircraftPlot;
