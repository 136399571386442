let smelterFields = [
	{
		header: 'ID',
		data: 'id'
	},
	{
		header: 'Site',
		data: 'site_id'
	},
	{
		header: 'Site Name',
		data: 'site_name'
	},
	{
		header: 'Capture Date',
		data: 'captured'
	},
	{
		header: 'Capture Time',
		data: 'captured'
	},
	{
		header: 'Sensor',
		data: 'sensor'
	},
	{
		header: 'QC User',
		data: 'qc_user'
	}
];

let stockpileFields = [
	{
		header: 'ID',
		data: 'id'
	},
	{
		header: 'Site',
		data: 'site_id'
	},
	{
		header: 'Site Name',
		data: 'site_name'
	},
	{
		header: 'Capture Date',
		data: 'captured'
	},
	{
		header: 'Capture Time',
		data: 'captured'
	},
	{
		header: 'Model',
		data: 'model_name'
	},
	{
		header: 'QC User',
		data: 'qc_user'
	}
];

let smeltersPostQCFields = [
	{
		header: 'ID',
		data: 'id'
	},
	{
		header: 'Site',
		data: 'site_id'
	},
	{
		header: 'Capacity',
		data: 'capacity'
	},
	{
		header: 'Capture Date',
		data: 'captured'
	},
	{
		header: 'Sensor',
		data: 'sensor'
	},
	{
		header: 'State',
		data: 'state'
	},
	{
		header: 'Cloud Fraction',
		data: 'cloud_frac'
	},
	{
		header: 'Hotspots',
		data: 'hotspots'
	},
	{
		header: 'Dispersion Index',
		data: 'dispersion_index'
	},
	{
		header: 'Fill',
		data: 'fill'
	},
	{
		header: 'High Count',
		data: 'high_count'
	},
	{
		header: 'Medium Count',
		data: 'medium_count'
	},
	{
		header: 'Low Count',
		data: 'low_count'
	},
	{
		header: 'Raw Index',
		data: 'raw_index'
	},
	{
		header: 'Perm Regions',
		data: 'perm_regions'
	}
];

let smeltersPrePostQCFields = [
	{
		header: 'ID',
		data: 'id'
	},
	{
		header: 'Site',
		data: 'site_id'
	},
	{
		header: 'Capacity',
		data: 'capacity'
	},
	{
		header: 'Capture Date',
		data: 'captured'
	},
	{
		header: 'Sensor',
		data: 'sensor'
	},
	{
		header: 'State',
		data: 'state'
	},
	{
		header: 'Cloud Fraction',
		data: 'cloud_frac'
	},
	{
		header: 'Hotspots',
		data: 'hotspots'
	},
	{
        header: 'Post QC',
        data: 'post_qc'
    },
    {
        header: 'Uploaded to Dev',
        data: 'upload_to_dev'
    },
    {
        header: 'Uploaded to Prod',
        data: 'upload_to_prod'
    },
];


export { smelterFields, stockpileFields, smeltersPostQCFields, smeltersPrePostQCFields };
