import React, { useEffect, useState } from 'react';
import Breadcrumb from '../Breadcrumb';
import { Navigate } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';
import exportConfig from '../../config';
import useGainsQCApi from '../../services/useGainsQCApi';
import Skeleton from 'react-loading-skeleton';
import { Tooltip } from 'react-tooltip';
import { CaptureModal } from '../Modals/CaptureModal';

export default function Captures(props) {
	let formInitialValues = {
		siteType: 1,
		siteComm: 3,
		siteProdState: [-2, -1, 0, 1, 2, 5],
		siteIds: [],
		captureIds: [],
		qcStates: [],
		sensors: [],
		models: [],
		origins: [],
		allowPixels: false,
		pixelsMin: 0,
		pixelsMax: 10000,
		allowHotspots: false,
		hotspotsMin: 0,
		hotspotsMax: 20,
		allowCloudFrac: false,
		cloudFracMin: 0,
		cloudFracMax: 1,
		captureStartDate: '2024-01-01',
		captureEndDate: new Date(new Date().setMonth(new Date().getMonth() - 3))
			.toISOString()
			.split('T')[0],
		qcInitialState: [],
		qcFinalState: [],
		qcStartDate: '',
		qcEndDate: ''
	};

	const [formValue, setFormValue] = useState(formInitialValues);
	const [currentImage, setCurrentImage] = useState(null);

	let steelDistinctUsers = [
		'Auto_QC',
		'Kaia',
		'PH',
		'Guy',
		'Chetan',
		'Josh',
		'Chetan Pradhan',
		'Historic Subsite Auto_QC',
		'ma',
		'lucy',
		'george.begkas',
		'qurratul.khan',
		'lucy.colley',
		'joshua.large'
	];

	const [formValid, setFormValid] = useState(true);
	const [commsForSiteType, setCommsForSiteType] = useState(
		exportConfig.commodities.filter((comm) => comm.siteTypeId === 1)
	);
	const [selectedSiteOptions, setSelectedSiteOptions] = useState([]);
	const [selectedUserOptions, setSelectedUserOptions] = useState([]);
	const [formUpdated, setFormUpdated] = useState(false);

	const {
		data: sites,
		loading: loadingSites,
		error: errorSites,
		makeRequest: makeRequestSites
	} = useGainsQCApi();

	const {
		data: captures,
		loading: loadingCaptures,
		error: errorCapture,
		makeRequest: makeRequestCaptures,
		resetData: resetCapturesData
	} = useGainsQCApi();

	const [siteOptions, setSiteOptions] = useState([]);
	const [userOptions, setUserOptions] = useState([]);

	useEffect(() => {
		// console.log(loadingCaptures);
		// console.log(errorCapture);
		// console.log(captures);
	}, [captures]);

	useEffect(() => {
		setFormUpdated(true);

		if (formValue.siteComm.length === 0) {
			setFormValid(false);
		} else {
			setFormValid(true);
		}
	}, [formValue]);

	useEffect(() => {
		/**
		 * when site type is changed, get commodities for site type
		 * and fetch sites for site type,
		 * reset selectedSites
		 */

		setSelectedSiteOptions([]);

		setCommsForSiteType(
			exportConfig.commodities.filter(
				(comm) => comm.siteTypeId === formValue['siteType']
			)
		);

		setFormValue({
			...formValue,
			siteComm: [],
			siteProdState: [],
			qcFinalState: [],
			qcInitialState: [],
			hotspotsMin: 0,
			hotspotsMax: 20,
			cloudFracMin: 0,
			cloudFracMax: 1
		});

		makeRequestSites(
			`${exportConfig.baseUrl}/${
				exportConfig.siteTypesIds.filter(
					(siteType) => siteType.id === formValue['siteType']
				)[0].name
			}/sites?hasCaptures=true`,
			'GET',
			null,
			'',
			1000
		);
	}, [formValue['siteType']]);

	useEffect(() => {
		/**
		 * when commodity or prod_state is selected,
		 * filter sites to only relevant commodities and production states
		 */

		let sitesFiltered;
		if (sites && sites.data) {
			if (formValue.siteType === 1) {
				sitesFiltered = sites.data.filter(
					(site) =>
						site.commodity_id === formValue.siteComm &&
						formValue.siteProdState.includes(parseInt(site.prod_state))
				);
				// formValue.siteComm.length > 0
				// 	? sites.data.filter(
				// 			(site) =>
				// 				formValue.siteComm.includes(site.commodity_id) &&
				// 				formValue.siteProdState.includes(parseInt(site.prod_state))
				// 	  )
				// 	: sites.data.filter(
				// 			(site) => site.commodity_id === formValue.siteComm
				// 	  );
			} else {
				sitesFiltered = sites.data.filter(
					(site) => site.commodity_id === formValue.siteComm
				);
				// formValue.siteComm.length > 0
				// 	? sites.data.filter((site) =>
				// 			formValue.siteComm.includes(site.commodity_id)
				// 	  )
				// 	: sites.data.filter(
				// 			(site) => site.commodity_id === formValue.siteComm
				// 	  );
			}

			// --> filter prod states too
			console.log(sitesFiltered);

			setSiteOptions(
				sitesFiltered.map(({ id, name, commodity_id }) => ({
					value: id,
					label: `${id}: ${name}`,
					comm: commodity_id
				}))
			);
		}
	}, [sites, formValue.siteComm, formValue.siteProdState]);

	// useEffect(() => {
	// 	setFormValue({
	// 		...formValue,
	// 		siteComm: commsForSiteType.map((comm) => comm.index)
	// 	});
	// }, [commsForSiteType]);

	const handleSelectedSiteOptions = (selectedValues) => {
		setSelectedSiteOptions(selectedValues);
		setFormValue({
			...formValue,
			siteIds: selectedValues.map((site) => site.value)
		});
	};

	const handleSelectedUserOptions = (selectedValues) => {
		setSelectedUserOptions(selectedValues);
	};

	const handleCaptureIDChange = (e) => {
		/**
		 * as capture IDs are added, tidy list and add to form values
		 */

		let input = e.target.value;

		// Normalize the input, replacing commas and spaces as per your logic
		const normalizedInput = input
			.replace(/\s+(?=\d)/g, ',')
			.replace(/,\s*/g, ' ');

		// Split the input by spaces or commas, map to numbers, and filter out NaN values
		const result = normalizedInput
			.split(/[\s,]+/)
			.filter((item) => item !== '') // Avoid empty strings
			.map((item) => Number(item)) // Convert to number
			.filter((num) => !isNaN(num)); // Filter out NaN values

		// Update the form value
		setFormValue({ ...formValue, captureIds: result });
	};

	let captureProps = [
		{
			title: 'Home',
			currentPage: false,
			link: '/',
			afterCurrent: false,
			warning: false
		},
		{
			title: 'Captures',
			currentPage: false,
			link: '/capture',
			afterCurrent: false,
			warning: false
		},
		{
			title: 'Capture 5060',
			currentPage: true,
			link: '/capture/5060',
			afterCurrent: false,
			warning: false
		}
	];

	useEffect(() => {
		document.title = 'GAINS | Capture';

		setFormValue(formInitialValues);

		setUserOptions(
			steelDistinctUsers.map((user, idx) => ({
				value: idx,
				label: user
			}))
		);
	}, []);

	const handleSelectAll = (event, stateKey) => {
		const { value, checked } = event.target;
	};

	const handleStateChange = (event, stateKey, inputCat) => {
		/**
		 * handle change for checkboxes to add/remove values
		 * to formValue as they are clicked
		 *
		 * used for commodity, production state, sensor, qc stage
		 */

		const { value, checked } = event.target;
		const parsedValue = isNaN(parseInt(value)) ? value : parseInt(value);

		if (parsedValue === 'all') {
			// If the "All" option is selected/deselected
			setFormValue((prevState) => ({
				...prevState,
				[stateKey]: checked ? inputCat : [] // Select all or clear all
			}));
		} else {
			// For individual checkbox changes
			setFormValue((prevState) => {
				const stateArray = prevState[stateKey];

				if (checked) {
					return {
						...prevState,
						[stateKey]: [...stateArray, parsedValue]
					};
				} else {
					return {
						...prevState,
						[stateKey]: stateArray.filter((item) => item !== parsedValue)
					};
				}
			});
		}
	};

	const fetchCaptures = (e) => {
		/**
		 * upon form submit, fetch captures relevant to form data
		 */

		resetCapturesData();
		setFormUpdated(false);
		let apiString = '';

		let siteIdObj = {
			1: [],
			2: [],
			3: [],
			4: [],
			5: [],
			6: [],
			7: []
		};

		let captureIdObj = {
			1: [],
			2: [],
			3: [],
			4: [],
			5: [],
			6: [],
			7: []
		};

		if (formValue.siteComm.length > 0) {
			apiString = apiString + `commodities=${formValue.siteComm.join(',')}`;
		}

		let siteIdsString = '';
		if (formValue.siteIds.length > 0) {
			for (let id in formValue.siteIds) {
				let comm = sites.data.filter(
					(site) => site.id === parseInt(formValue.siteIds[id])
				)[0].commodity_id;

				if (siteIdObj.hasOwnProperty(comm)) {
					siteIdObj[comm].push(formValue.siteIds[id]);
				} else {
					console.log(`Key ${comm} does not exist in siteIdObj`);
				}
			}

			Object.keys(siteIdObj).forEach((key) => {
				if (siteIdObj[key].length > 0) {
					siteIdsString =
						'&' +
						siteIdsString +
						'siteIds[' +
						key +
						']=' +
						siteIdObj[key] +
						',';
				}
			});
		}

		if (siteIdsString) {
			apiString = apiString + siteIdsString.slice(0, -1);
		}

		if (formValue.siteProdState.length > 0) {
			apiString =
				apiString + '&' + `prodStates=${formValue.siteProdState.join(',')}`;
		}

		for (let i in formValue.siteComm) {
			if (captureIdObj.hasOwnProperty(formValue.siteComm[i])) {
				captureIdObj[formValue.siteComm[i]] = formValue.captureIds;
			} else {
				console.log(`Key ${formValue.siteComm[i]} does not exist in siteIdObj`);
			}
		}

		let captureIdsString = '';
		const keys = Object.keys(captureIdObj);
		keys.forEach((key, index) => {
			if (captureIdObj[key].length > 0) {
				captureIdsString =
					captureIdsString +
					'&' +
					'captureIds[' +
					key +
					']=' +
					captureIdObj[key];

				// Add a comma if it's not the last key
				if (index < keys.length - 1) {
					captureIdsString += ',';
				}
			}
		});

		if (captureIdObj) {
			apiString = apiString + captureIdsString.slice(0, -1);
		}

		if (formValue.captureStartDate) {
			apiString = apiString + '&' + `startDate=${formValue.captureStartDate}`;
		} else {
			apiString = apiString + '&' + `startDate=2016-01-01`;
		}

		if (formValue.captureEndDate) {
			apiString = apiString + '&' + `endDate=${formValue.captureEndDate}`;
		} else {
			apiString =
				apiString + '&' + `endDate=${new Date().toISOString().split('T')[0]}`;
		}

		if (formValue.sensors.length > 0) {
			apiString = apiString + '&' + `sensors=${formValue.sensors}`;
		}

		if (formValue.origins.length > 0) {
			apiString = apiString + '&' + `origins=${formValue.origins}`;
		}

		if (formValue.models.length > 0) {
			apiString = apiString + '&' + `models=${formValue.models}`;
		}

		if (formValue.allowHotspots) {
			apiString =
				apiString +
				'&' +
				`hotspots=${formValue.hotspotsMin},${formValue.hotspotsMax}`;
		}

		if (formValue.allowCloudFrac) {
			apiString =
				apiString +
				'&' +
				`cloudFrac=${formValue.cloudFracMin},${formValue.cloudFracMax}`;
		}

		if (formValue.allowMaxPixels) {
			apiString =
				apiString +
				'&' +
				`totalPixels=${formValue.pixelsMin},${formValue.pixelsMax}`;
		}

		if (formValue.qcStates.length > 0) {
			apiString = apiString + '&' + `qcState=${formValue.qcStates.join(',')}`;
		}

		if (formValue.qcInitialState.length > 0) {
			apiString =
				apiString + '&' + `initialState=${formValue.qcInitialState.join(',')}`;
		}

		if (formValue.qcFinalState.length > 0) {
			apiString = apiString + '&' + `state=${formValue.qcFinalState.join(',')}`;
		}

		if (formValue.qcStartDate) {
			apiString = apiString + '&' + `qcDateStart=${formValue.qcStartDate}`;
		}

		if (formValue.qcEndDate) {
			apiString = apiString + '&' + `qcDateEnd=${formValue.qcEndDate}`;
		}

		// console.log(apiString);
		// console.log(
		// 	`${exportConfig.baseUrl}/${
		// 		exportConfig.siteTypesIds.filter(
		// 			(siteType) => siteType.id === formValue['siteType']
		// 		)[0].name
		// 	}/captures?${apiString}`
		// );

		makeRequestCaptures(
			`${exportConfig.baseUrl}/${
				exportConfig.siteTypesIds.filter(
					(siteType) => siteType.id === formValue['siteType']
				)[0].name
			}/captures?${apiString}`,
			'GET',
			null,
			'captures..'
		);
	};

	const renderSkeletonTable = (style) => {
		console.log(style);
		return (
			<div className="table-responsive my-4" style={{ maxHeight: '400px' }}>
				<table className="table">
					<thead className="table-light">
						<tr>
							<th scope="col">ID</th>
							<th scope="col">Capture Date</th>
							<th scope="col">Site</th>
							<th scope="col">Sensor</th>
							<th scope="col">Site ID</th>

							<th scope="col">Initial State</th>
							<th scope="col">State</th>
							<th scope="col">QC State</th>
							<th scope="col">QC Date</th>
							<th scope="col">QC User</th>
							<th scope="col">
								View image
								{/* <i className="fa-regular fa-image"></i> */}
							</th>
						</tr>
					</thead>
					<tbody className="table-group-divider">
						{Array.from({ length: 5 }, (_, rowIndex) => (
							<tr key={rowIndex} style={{ height: '30px' }}>
								{Array.from({ length: 11 }, (_, rowIndex) => (
									<td className="table-light">
										{style === 'skeleton' ? <Skeleton /> : null}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	};

	console.log(formValue);

	return (
		<>
			{props.authProps.isAuthenticated ? (
				<Col className="px-0">
					<div className="contentContainer">
						<CaptureModal
							currentImage={currentImage}
							setCurrentImage={setCurrentImage}
							siteType={formValue.siteType}
						/>

						<hr style={{ marginTop: '58px' }}></hr>
						<h2>Captures</h2>
						<h5>Use this page to fetch captures within given criteria</h5>

						<div
							className="fetch-capture-form"
							style={{
								// overflowY: 'scroll',
								maxHeight: '72vh',
								padding: '5px'
							}}
						>
							<div className="row">
								<div className="col">
									<fieldset
										className="form-group p-3 border rounded-3"
										style={{ height: '100%' }}
									>
										<legend className="float-none w-auto px-3 my-0 fs-6 fw-bold">
											Site Info
										</legend>

										<div className="row mb-4">
											<label
												for="captures-site-type"
												className={
													formValue.siteType ? 'text-dark' : 'text-secondary'
												}
											>
												*Site Type
											</label>
											<p className="py-0 my-0 fst-italic">select one option</p>

											<div className="d-flex flex-row mt-1">
												<div className="form-check-dashboard-qcinfo">
													<input
														className="form-check-input-dashboard-qcinfo"
														type="radio"
														name="captures-site-type-radios"
														id="captures-site-type-radios-smelters"
														defaultChecked={true}
														onClick={(e) =>
															setFormValue({ ...formValue, siteType: 1 })
														}
													></input>
													<label
														className="form-check-label-dashboard-qcinfo"
														htmlFor="captures-site-type-radios-smelters"
														data-tooltip-id="captures-sitetype-radio-smelters"
													>
														<i className="fa-solid fa-city"></i>
													</label>
												</div>
												<div className="form-check-dashboard-qcinfo">
													<input
														className="form-check-input-dashboard-qcinfo"
														type="radio"
														name="captures-site-type-radios"
														id="captures-site-type-radios-stockpiles"
														onClick={(e) =>
															setFormValue({ ...formValue, siteType: 3 })
														}
													></input>
													<label
														className="form-check-label-dashboard-qcinfo"
														htmlFor="captures-site-type-radios-stockpiles"
														data-tooltip-id="captures-sitetype-radio-stockpiles"
													>
														<i className="fa-solid fa-cubes-stacked"></i>
													</label>
												</div>
												<div
													className="form-check-dashboard-qcinfo"
													data-tooltip-id="dashboard-qcinfo-airfields"
												>
													<input
														className="form-check-input-dashboard-qcinfo"
														type="radio"
														name="captures-site-type-radios"
														id="captures-site-type-radios-airfield"
														disabled
														onClick={(e) =>
															setFormValue({ ...formValue, siteType: 2 })
														}
													></input>
													<label
														className="form-check-label-dashboard-qcinfo"
														htmlFor="captures-site-type-radios-airfield"
														data-tooltip-id="captures-sitetype-radio-airfield"
													>
														<i className="fa-solid fa-tower-broadcast"></i>
													</label>
												</div>
											</div>

											<Tooltip id="captures-sitetype-radio-smelters">
												Smelters
											</Tooltip>
											<Tooltip id="captures-sitetype-radio-stockpiles">
												Stockpiles
											</Tooltip>
											<Tooltip id="captures-sitetype-radio-airfield">
												Airfield
											</Tooltip>
										</div>

										<div className="row my-4">
											<label
												className='label'
												for="captures-site-id"
											>
												*Commodity
											</label>
											<p className="py-0 mt-0 mb-2 fst-italic">
												select one option
											</p>

											<div className="d-flex flex-row">
												{commsForSiteType.map((comm, idx) => (
													<>
														<input
															className="form-check-input-dashboard-qcinfo form-check-input"
															type="radio"
															name="captures-site-commodity"
															id={`comm-${comm.id}`}
															value={comm.index}
															checked={formValue.siteComm === comm.index}
															onChange={(e) =>
																setFormValue({
																	...formValue,
																	siteComm: parseInt(comm.index)
																})
															}
														></input>
														<label
															className="form-check-label-dashboard-qcinfo mx-1"
															htmlFor={`comm-${comm.id}`}
															data-tooltip-id="dashboard-qcinfo-smelters"
														>
															{comm.commodity}
														</label>
													</>
												))}
											</div>
										</div>

										{formValue.siteType === 1 && (
											<div className="row my-4">
												<label
													className={
														formValue.siteProdState.length > 0
															? 'label'
															: 'label fw-light'
													}
													for="captures-site-id"
												>
													Production State
												</label>
												<p className="py-0 mt-0 mb-2 fst-italic">
													select at least one option
												</p>

												<div className="ms-3">
													<div className="d-flex form-check">
														<input
															className="form-check-input me-2"
															type="checkbox"
															name="captures-site-production-state"
															id={`captures-site-prod-state-all`}
															checked={
																formValue.siteProdState.length ===
																exportConfig.production_states.length
															}
															value="all"
															onChange={(e) =>
																handleStateChange(
																	e,
																	'siteProdState',
																	exportConfig.production_states.map(
																		(state) => state.state
																	)
																)
															}
														></input>
														<label
															className="form-check-label me-5"
															htmlFor={`captures-site-prod-state-all`}
															data-tooltip-id={`captures-site-prod-state-all`}
														>
															All
														</label>
													</div>
													{exportConfig.production_states.map((state, idx) => (
														<div key={idx} className="d-flex form-check">
															<input
																className="form-check-input me-2"
																type="checkbox"
																name="captures-site-production-state"
																id={`captures-site-prod-state-${state.state}`}
																value={state.state}
																checked={formValue.siteProdState.includes(
																	state.state
																)}
																onChange={(e) =>
																	handleStateChange(e, 'siteProdState')
																}
															></input>

															<label
																className="form-check-label me-5"
																htmlFor={`captures-site-prod-state-${state.state}`}
																data-tooltip-id={`captures-site-prod-state-${state.state}`}
															>
																{state.state}
															</label>

															<Tooltip
																id={`captures-site-prod-state-${state.state}`}
															>
																Production state {state.state}
																<br></br> {state.descript}
															</Tooltip>
														</div>
													))}
												</div>
											</div>
										)}

										<div className="row my-4">
											<label
												className={
													selectedSiteOptions.length > 0
														? 'fw-bold label'
														: 'label '
												}
												for="captures-site-id"
											>
												Site Name
											</label>

											{formValue.siteIds.length === 0 ? (
												<p className="py-0 mt-0 mb-2 fst-italic">
													fetch captures for all sites
												</p>
											) : (
												<p className="py-0 mt-0 mb-2 fst-italic">
													fetch captures for sites:{' '}
													{formValue.siteIds.map((site, idx) => (
														<span key={idx}>{site}, </span>
													))}
												</p>
											)}

											{loadingSites && siteOptions !== null ? (
												<Skeleton height={30} />
											) : siteOptions !== null && siteOptions.length === 0 ? (
												<Select
													isMulti
													isDisabled
													placeholder="No sites for given criteria"
												/>
											) : (
												<Select
													isMulti
													options={siteOptions}
													value={selectedSiteOptions}
													onChange={handleSelectedSiteOptions}
													isSearchable
													placeholder={`Search sites (${siteOptions.length})...`}
												/>
											)}
										</div>
									</fieldset>
								</div>
								<div className="col">
									<fieldset
										className="form-group p-3 border rounded-3"
										style={{ height: '100%' }}
									>
										<legend className="float-none w-auto px-3 my-0 fs-6 fw-bold">
											Capture Info
										</legend>

										<div className="row my-3">
											<label
												for="captures-capture-id"
												className={
													formValue.captureIds.length > 0
														? 'fw-bold form-label'
														: 'form-label '
												}
											>
												Capture ID
											</label>

											<div className="row">
												<input
													type="text"
													className="form-control mx-3"
													value={formValue.captureIds}
													onChange={(e) => handleCaptureIDChange(e)}
												></input>
											</div>

											<div className="row d-flex align-items-center">
												<p>
													{formValue.captureIds.map((cap) => (
														<span className="mx-1 py-0 my-0">{cap},</span>
													))}
												</p>
											</div>
										</div>

										<div className="row my-3">
											<label
												for="captures-capture-qc-state"
												className={
													formValue.captureStartDate || formValue.captureEndDate
														? 'fw-bold form-label'
														: 'form-label '
												}
											>
												Capture Date
											</label>

											<div className="row">
												<div className="col d-flex justify-content-center">
													<p className="my-0 py-0">between</p>
													<input
														type="date"
														value={formValue.captureStartDate}
														onChange={(e) =>
															setFormValue({
																...formValue,
																captureStartDate: e.target.value
															})
														}
														className="form-control ms-1 me-2"
													></input>
												</div>
												<div className="col d-flex justify-content-center">
													<p className="my-0 py-0">and</p>
													<input
														type="date"
														value={formValue.captureEndDate}
														onChange={(e) =>
															setFormValue({
																...formValue,
																captureEndDate: e.target.value
															})
														}
														className="form-control  ms-1 me-2"
													></input>
												</div>
											</div>
										</div>

										{formValue.siteType === 1 && (
											<div className="row my-3">
												<label
													for="captures-capture-sensors"
													className={
														formValue.sensors.length > 0
															? 'fw-bold form-label'
															: 'form-label '
													}
												>
													Sensor
												</label>
												<div className="d-flex flex-column align-items-start">
													{exportConfig.sensors.map((sensor, idx) => (
														<div className="form-check d-flex justify-content-end">
															<input
																className="form-check-input me-4"
																type="checkbox"
																id={sensor}
																name="formSensors"
																value={sensor}
																onChange={(e) =>
																	handleStateChange(e, 'sensors')
																}
																checked={formValue.sensors.includes(sensor)}
															></input>
															<label
																for={sensor}
																className="form-check-label me-5"
															>
																{sensor}
															</label>
														</div>
													))}
												</div>
											</div>
										)}

										{formValue.siteType === 3 && (
											<div className="row my-3">
												<div className="col-3">
													<label
														for="captures-capture-models"
														className={
															formValue.models.length > 0
																? 'fw-bold form-label'
																: 'form-label '
														}
													>
														Model
													</label>
												</div>
												<div className="col-9 d-flex flex-column align-items-end">
													{exportConfig.models.map((model, idx) => (
														<div className="form-check d-flex justify-content-end">
															<label
																for={model}
																className="form-check-label me-5"
															>
																{model}
															</label>
															<input
																className="form-check-input me-4"
																type="checkbox"
																id={model}
																name="formSensors"
																value={model}
																onChange={(e) => handleStateChange(e, 'models')}
																checked={formValue.models.includes(model)}
															></input>
														</div>
													))}
												</div>
											</div>
										)}

										{formValue.siteType === 3 && (
											<div className="row my-3">
												<div className="col-4 d-flex align-items-center">
													<input
														type="checkbox"
														className="form-check-input me-2"
														defaultValue={false}
														value={formValue.allowPixels}
														onChange={(e) =>
															setFormValue({
																...formValue,
																allowPixels: !formValue.allowPixels
															})
														}
													></input>
													<label
														for="captures-capture-pixels"
														className={
															formValue.allowPixels
																? 'fw-bold form-label py-0 my-0'
																: 'form-label py-0 my-0'
														}
													>
														Pixel Count
													</label>
												</div>
												<div className="col-8 d-flex justify-control-center align-items-center">
													<p className="my-0 py-0">from</p>
													<input
														className="form-control mx-3"
														type="number"
														value={formValue.pixelsMin}
														onChange={(e) =>
															setFormValue({
																...formValue,
																pixelsMin: e.target.value
															})
														}
														disabled={!formValue.allowPixels}
													></input>
													<p className="my-0 py-0">to</p>
													<input
														className="form-control mx-3"
														type="number"
														value={formValue.pixelsMax}
														onChange={(e) =>
															setFormValue({
																...formValue,
																pixelsMax: e.target.value
															})
														}
														disabled={!formValue.allowPixels}
													></input>
												</div>
											</div>
										)}

										{formValue.siteType === 1 && (
											<>
												<div className="row my-3">
													<div className="col-4 d-flex align-items-center">
														<input
															type="checkbox"
															className="form-check-input me-2"
															defaultValue={false}
															value={formValue.allowHotspots}
															onChange={(e) =>
																setFormValue({
																	...formValue,
																	allowHotspots: !formValue.allowHotspots
																})
															}
														></input>
														<label
															for="captures-capture-sensors"
															className={
																formValue.allowHotspots
																	? 'fw-bold form-label py-0 my-0'
																	: 'form-label py-0 my-0'
															}
														>
															Hotspots
														</label>
													</div>
													<div className="col-8 d-flex justify-control-center align-items-center">
														<p className="my-0 py-0">from</p>
														<input
															className="form-control mx-3"
															type="number"
															value={formValue.hotspotsMin}
															onChange={(e) =>
																setFormValue({
																	...formValue,
																	hotspotsMin: e.target.value
																})
															}
															disabled={!formValue.allowHotspots}
														></input>
														<p className="my-0 py-0">to</p>
														<input
															className="form-control mx-3"
															type="number"
															value={formValue.hotspotsMax}
															onChange={(e) =>
																setFormValue({
																	...formValue,
																	hotspotsMax: e.target.value
																})
															}
															disabled={!formValue.allowHotspots}
														></input>
													</div>
												</div>

												<div className="row my-3">
													<div className="col-4 d-flex align-items-center">
														<input
															type="checkbox"
															className="form-check-input me-2"
															defaultValue={false}
															value={formValue.allowCloudFrac}
															onChange={(e) =>
																setFormValue({
																	...formValue,
																	allowCloudFrac: !formValue.allowCloudFrac
																})
															}
														></input>
														<label
															for="captures-capture-sensors"
															className={
																formValue.allowCloudFrac
																	? 'fw-bold form-label py-0 my-0'
																	: 'form-label py-0 my-0'
															}
														>
															Cloud Fraction
														</label>
													</div>
													<div className="col-8 d-flex justify-control-center align-items-center">
														<p className="my-0 py-0">from</p>
														<input
															className="form-control mx-3"
															type="number"
															min={0}
															max={1}
															value={formValue.cloudFracMin}
															onChange={(e) =>
																setFormValue({
																	...formValue,
																	cloudFracMin: e.target.value
																})
															}
															disabled={!formValue.allowCloudFrac}
														></input>
														<p className="my-0 py-0">to</p>
														<input
															className="form-control mx-3"
															type="number"
															max={1}
															value={formValue.cloudFracMax}
															onChange={(e) =>
																setFormValue({
																	...formValue,
																	cloudFracMax: e.target.value
																})
															}
															disabled={!formValue.allowCloudFrac}
														></input>
													</div>
												</div>
											</>
										)}
									</fieldset>
								</div>
								<div className="col">
									<fieldset
										className="form-group p-3 border rounded-3"
										style={{ height: '100%' }}
									>
										<legend className="float-none w-auto px-3 my-0 fs-6 fw-bold">
											QC Info
										</legend>

										<div
											className="row my-3"
											data-tooltip-id="captures-capture-qc-state"
										>
											<div className="col-3">
												<label
													for="captures-capture-qc-state"
													className={
														formValue.qcStates.length > 0
															? 'fw-bold form-label py-0 my-0'
															: 'form-label py-0 my-0'
													}
												>
													QC Stage
												</label>
											</div>
											<div className="col-9 d-flex flex-column">
												{exportConfig.qcStages.map((stage, idx) => (
													<div key={idx} className="d-flex justify-content-end">
														<label
															className="form-check-label ms-1 me-4"
															htmlFor={`captures-capture-qc-state-${stage.id}`}
														>
															{stage.descript}
														</label>
														<input
															className="form-check-input me-3"
															type="checkbox"
															name="captures-capture-qc-state"
															id={`captures-capture-qc-state-${stage.id}`}
															value={stage.id}
															checked={formValue.qcStates.includes(stage.id)}
															onChange={(e) => handleStateChange(e, 'qcStates')}
														></input>
													</div>
												))}
											</div>
										</div>

										{formValue.qcStates.includes(4) ||
										formValue.qcStates.includes(1) ? (
											<>
												<div className="row my-3">
													<div className="col-3 d-flex align-items-center">
														<label
															for="captures-capture-qc-date"
															className={
																!formValue.qcStates.includes(4) &&
																!formValue.qcStates.includes(1)
																	? 'fst-italic'
																	: selectedUserOptions.length > 0
																	? 'fw-bold form-label py-0 my-0'
																	: 'form-label py-0 my-0'
															}
															s
														>
															QC Date
														</label>
													</div>
													<div className="col-9 d-flex justify-control-center align-items-center">
														<p className="my-0 py-0">between</p>
														<input
															style={{ width: '37%' }}
															type="date"
															className="form-control ms-1 me-2"
															value={formValue.qcStartDate}
															disabled={
																!formValue.qcStates.includes(4) &&
																!formValue.qcStates.includes(1)
															}
															onChange={(e) =>
																setFormValue({
																	...formValue,
																	qcStartDate: e.target.value
																})
															}
														></input>
														<p className="my-0 py-0">and</p>
														<input
															style={{ width: '37%' }}
															type="date"
															className="form-control  ms-1 me-2"
															disabled={
																!formValue.qcStates.includes(4) &&
																!formValue.qcStates.includes(1)
															}
															value={formValue.qcEndDate}
															onChange={(e) =>
																setFormValue({
																	...formValue,
																	qcEndDate: e.target.value
																})
															}
														></input>
													</div>
												</div>

												<div
													className="row my-3"
													data-tooltip-id="captures-capture-qc-user"
												>
													<div className="col-3">
														<label
															for="captures-capture-qc-user"
															className={
																!formValue.qcStates.includes(4) &&
																!formValue.qcStates.includes(1)
																	? 'fst-italic'
																	: selectedUserOptions.length > 0
																	? 'fw-bold form-label py-0 my-0'
																	: 'form-label py-0 my-0'
															}
														>
															QC User
														</label>
													</div>
													<div className="col-9 d-flex justify-control-center">
														<Select
															styles={{
																container: (provided) => ({
																	...provided,
																	width: '95%'
																})
															}}
															isMulti
															options={userOptions}
															value={selectedUserOptions}
															onChange={handleSelectedUserOptions}
															isSearchable
															menuPlacement="top"
															placeholder="Search and select users..."
															isDisabled={
																!formValue.qcStates.includes(4) &&
																!formValue.qcStates.includes(1)
															}
														/>
													</div>
												</div>

												<Tooltip id="captures-capture-qc-state">
													Filter captures by QC stage:<br></br>0: Not QC'd
													<br></br>
													3: Currently in QC<br></br>
													4: QC'd but not uploaded<br></br>1: QC'd and uploaded
												</Tooltip>

												{formValue.siteType === 1 && (
													<>
														<div
															className="row my-3"
															data-tooltip-id="captures-capture-qc-final-state"
														>
															<div className="col-6">
																<div className="row">
																	<div className="col-6">
																		<label
																			for="captures-capture-qc-final-state"
																			className={
																				!formValue.qcStates.includes(4) &&
																				!formValue.qcStates.includes(1)
																					? 'fst-italic'
																					: formValue.qcFinalState.length > 0
																					? 'fw-bold'
																					: 'form-label'
																			}
																		>
																			QC Final State
																		</label>
																	</div>
																	<div className="col-6 d-flex flex-column">
																		{exportConfig.qcStates.map((state, idx) => (
																			<div
																				key={idx}
																				className="d-flex justify-content-end"
																			>
																				<label
																					for={`captures-qc-final-state-${state.stateInt}`}
																					className="form-label ms-1 me-3"
																				>
																					{state.stateString}
																				</label>
																				<input
																					className="form-check-input"
																					type="checkbox"
																					name="captures-qc-final-state"
																					value={state.stateInt}
																					id={`captures-qc-final-state-${state.stateInt}`}
																					disabled={
																						!formValue.qcStates.includes(4) &&
																						!formValue.qcStates.includes(1)
																					}
																					onChange={(e) =>
																						handleStateChange(e, 'qcFinalState')
																					}
																				></input>
																			</div>
																		))}
																	</div>
																</div>
															</div>
															<div className="col-6">
																<div className="row">
																	<div className="col-6">
																		<label
																			for="captures-capture-qc-state"
																			className={
																				!formValue.qcStates.includes(4) &&
																				!formValue.qcStates.includes(1)
																					? 'fst-italic'
																					: formValue.qcInitialState.length > 0
																					? 'fw-bold'
																					: 'form-label'
																			}
																		>
																			QC Initial State
																		</label>
																	</div>
																	<div className="col-6 d-flex flex-column">
																		{exportConfig.qcStates.map((state, idx) => (
																			<div
																				key={idx}
																				className="d-flex justify-content-end"
																			>
																				<label
																					for={`captures-qc-initial-state-${state.stateInt}`}
																					className="form-label ms-1 me-3"
																				>
																					{state.stateString}
																				</label>
																				<input
																					className="form-check-input"
																					type="checkbox"
																					name="captures-qc-initial-state"
																					value={state.stateInt}
																					id={`captures-qc-final-state-${state.stateInt}`}
																					disabled={
																						!formValue.qcStates.includes(4) &&
																						!formValue.qcStates.includes(1)
																					}
																					onChange={(e) =>
																						handleStateChange(
																							e,
																							'qcInitialState'
																						)
																					}
																				></input>
																			</div>
																		))}
																	</div>
																</div>
															</div>
														</div>

														<Tooltip id="captures-capture-qc-final-state">
															If the capture has been QC'd, filter by the final
															state the capture was set to
														</Tooltip>
													</>
												)}
											</>
										) : null}
									</fieldset>
								</div>
							</div>
						</div>

						<div className="row mt-4">
							{captures && captures.hasOwnProperty('data') ? (
								<h4 className="text-center">
									Captures ({captures.data.length})
								</h4>
							) : (
								<h4 className="text-center">Captures </h4>
							)}
							{loadingCaptures ? (
								<>{renderSkeletonTable('skeleton')}</>
							) : captures &&
							  captures.hasOwnProperty('data') &&
							  captures.data.length > 0 ? (
								<div
									className="table my-4"
									style={{ maxHeight: '75vh', overflowY: 'scroll' }}
								>
									<table className="captureTable" style={{ width: '100%' }}>
										<thead className="thead-dark">
											<tr>
												<th scope="col">ID</th>
												<th scope="col">Capture Date</th>
												{/* <th scope="col">Site</th> */}
												<th scope="col">Sensor</th>

												{/* <th scope="col">Initial State</th>
													<th scope="col">State</th> */}
												<th scope="col">QC'd</th>
												<th scope="col">QC State</th>
												{/* <th scope="col">QC Date</th>
													<th scope="col">QC User</th> */}
												<th scope="col">View image</th>
											</tr>
										</thead>

										<tbody>
											{captures.data.map((capture, idx) => (
												<tr key={idx}>
													<td>{capture.id}</td>
													{capture.captured ? (
														<td>{capture.captured.split(' ')[0]}</td>
													) : (
														<td>-</td>
													)}
													{/* <td
															title={capture.site_name}
															style={{
																maxWidth: '150px',
																overflow: 'hidden',
																textOverflow: 'ellipsis',
																whiteSpace: 'nowrap'
															}}
														>
															{capture.site_name}
														</td> */}
													<td>{capture.sensor}</td>
													{/* <td>
															{
																exportConfig.qcStates.filter(
																	(qstate) =>
																		qstate.stateInt === capture.initial_state
																)[0].stateString
															}
														</td> */}
													{/* <td>
															{
																exportConfig.qcStates.filter(
																	(qstate) => qstate.stateInt === capture.state
																)[0].stateString
															}
														</td> */}
													<td>
														{exportConfig.qcStages.filter(
															(qstate) => qstate.id === capture.qc_state
														)[0].qcd === false ? (
															<i className="fa-regular fa-circle-xmark"></i>
														) : (
															<i className="fa-regular fa-circle-check"></i>
														)}
													</td>
													<td>
														{
															exportConfig.qcStages.filter(
																(qstate) => qstate.id === capture.qc_state
															)[0].descript
														}
													</td>

													{/* {capture.qc_date ? (
															<td>
																{capture.qc_date.split(' ')[0]}
															</td>
														) : (
															<td>-</td>
														)}

														<td>{capture.qc_user}</td> */}
													<td>
														{capture['stac_item_id'] !== null ? (
															<i
																id="captureImageHover"
																data-bs-toggle="modal"
																data-bs-target="#captureModal"
																className="fa-regular fa-image"
																onClick={(e) => setCurrentImage(capture)}
															></i>
														) : (
															<i
																id="captureImageHover"
																className="fa-regular fa-image"
																style={{ color: 'grey' }}
															></i>
														)}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							) : (
								<>
									<h4 className="text-secondary text-center">0 captures</h4>
								</>
							)}
						</div>
					</div>
				</Col>
			) : (
				<Navigate to="/login" />
			)}
		</>
	);
}
