export const aircraftPlotData = [
	{
		class_name: 'C130 Hercules',
		dates: [
			'2024-08-23',
			'2024-08-22',
			'2024-08-21',
			'2024-08-20',
			'2024-08-19',
			'2024-08-18',
			'2024-08-17',
			'2024-08-16',
			'2024-08-15',
			'2024-08-14',
			'2024-08-13',
			'2024-08-12',
			'2024-08-11',
			'2024-08-10',
			'2024-08-09',
			'2024-08-08',
			'2024-08-07',
			'2024-08-06',
			'2024-08-05',
			'2024-08-04',
			'2024-08-03',
			'2024-08-02',
			'2024-08-01',
			'2024-07-31',
			'2024-07-30',
			'2024-07-29',
			'2024-07-28',
			'2024-07-27',
			'2024-07-26',
			'2024-07-25',
			'2024-07-24',
			'2024-07-23',
			'2024-07-22',
			'2024-07-21',
			'2024-07-20',
			'2024-07-19',
			'2024-07-18',
			'2024-07-17',
			'2024-07-16',
			'2024-07-15',
			'2024-07-14',
			'2024-07-13',
			'2024-07-12',
			'2024-07-11',
			'2024-07-10',
			'2024-07-09',
			'2024-07-08',
			'2024-07-07',
			'2024-07-06',
			'2024-07-05',
			'2024-07-04',
			'2024-07-03',
			'2024-07-02',
			'2024-07-01',
			'2024-06-30',
			'2024-06-29',
			'2024-06-28',
			'2024-06-27',
			'2024-06-26',
			'2024-06-25',
			'2024-06-24',
			'2024-06-23',
			'2024-06-22',
			'2024-06-21',
			'2024-06-20',
			'2024-06-19',
			'2024-06-18',
			'2024-06-17',
			'2024-06-16',
			'2024-06-15',
			'2024-06-14',
			'2024-06-13',
			'2024-06-12',
			'2024-06-11',
			'2024-06-10',
			'2024-06-09',
			'2024-06-08',
			'2024-06-07',
			'2024-06-06',
			'2024-06-05',
			'2024-06-04',
			'2024-06-03',
			'2024-06-02',
			'2024-06-01',
			'2024-05-31',
			'2024-05-30',
			'2024-05-29',
			'2024-05-28',
			'2024-05-27',
			'2024-05-26',
			'2024-05-25',
			'2024-05-24',
			'2024-05-23',
			'2024-05-22',
			'2024-05-21',
			'2024-05-20',
			'2024-05-19',
			'2024-05-18',
			'2024-05-17',
			'2024-05-16'
		],
		values: [
			13, 18, 6, 19, 19, 7, 11, 8, 10, 1, 11, 18, 10, 0, 18, 17, 17, 8, 14, 16,
			4, 2, 16, 16, 6, 5, 2, 1, 0, 17, 6, 5, 6, 12, 7, 4, 12, 14, 11, 10, 15, 0,
			7, 16, 19, 19, 13, 18, 15, 4, 14, 0, 10, 9, 4, 0, 10, 10, 13, 13, 9, 7, 4,
			5, 2, 10, 14, 10, 15, 7, 1, 9, 1, 9, 15, 0, 17, 7, 14, 13, 6, 6, 9, 8, 8,
			12, 15, 6, 3, 15, 17, 18, 11, 17, 19, 17, 3, 15, 13, 9
		]
	},
	{
		class_name: 'Su-24 (Fencer)',
		dates: [
			'2024-08-23',
			'2024-08-22',
			'2024-08-21',
			'2024-08-20',
			'2024-08-19',
			'2024-08-18',
			'2024-08-17',
			'2024-08-16',
			'2024-08-15',
			'2024-08-14',
			'2024-08-13',
			'2024-08-12',
			'2024-08-11',
			'2024-08-10',
			'2024-08-09',
			'2024-08-08',
			'2024-08-07',
			'2024-08-06',
			'2024-08-05',
			'2024-08-04',
			'2024-08-03',
			'2024-08-02',
			'2024-08-01',
			'2024-07-31',
			'2024-07-30',
			'2024-07-29',
			'2024-07-28',
			'2024-07-27',
			'2024-07-26',
			'2024-07-25',
			'2024-07-24',
			'2024-07-23',
			'2024-07-22',
			'2024-07-21',
			'2024-07-20',
			'2024-07-19',
			'2024-07-18',
			'2024-07-17',
			'2024-07-16',
			'2024-07-15',
			'2024-07-14',
			'2024-07-13',
			'2024-07-12',
			'2024-07-11',
			'2024-07-10',
			'2024-07-09',
			'2024-07-08',
			'2024-07-07',
			'2024-07-06',
			'2024-07-05',
			'2024-07-04',
			'2024-07-03',
			'2024-07-02',
			'2024-07-01',
			'2024-06-30',
			'2024-06-29',
			'2024-06-28',
			'2024-06-27',
			'2024-06-26',
			'2024-06-25',
			'2024-06-24',
			'2024-06-23',
			'2024-06-22',
			'2024-06-21',
			'2024-06-20',
			'2024-06-19',
			'2024-06-18',
			'2024-06-17',
			'2024-06-16',
			'2024-06-15',
			'2024-06-14',
			'2024-06-13',
			'2024-06-12',
			'2024-06-11',
			'2024-06-10',
			'2024-06-09',
			'2024-06-08',
			'2024-06-07',
			'2024-06-06',
			'2024-06-05',
			'2024-06-04',
			'2024-06-03',
			'2024-06-02',
			'2024-06-01',
			'2024-05-31',
			'2024-05-30',
			'2024-05-29',
			'2024-05-28',
			'2024-05-27',
			'2024-05-26',
			'2024-05-25',
			'2024-05-24',
			'2024-05-23',
			'2024-05-22',
			'2024-05-21',
			'2024-05-20',
			'2024-05-19',
			'2024-05-18',
			'2024-05-17',
			'2024-05-16'
		],
		values: [
			11, 4, 8, 3, 7, 13, 0, 17, 1, 4, 6, 15, 12, 8, 10, 2, 6, 8, 8, 7, 17, 10,
			11, 2, 2, 7, 14, 14, 7, 17, 19, 0, 3, 17, 18, 6, 14, 17, 2, 11, 9, 8, 13,
			1, 9, 9, 6, 18, 6, 14, 10, 12, 10, 10, 13, 7, 2, 0, 3, 19, 13, 16, 13, 18,
			13, 19, 3, 13, 14, 3, 9, 6, 5, 15, 17, 5, 18, 17, 11, 0, 2, 3, 8, 13, 12,
			7, 0, 13, 14, 9, 13, 18, 11, 19, 11, 0, 6, 11, 17, 4
		]
	},
	{
		class_name: 'IL-76 Ilyushin (Candid)',
		dates: [
			'2024-08-23',
			'2024-08-22',
			'2024-08-21',
			'2024-08-20',
			'2024-08-19',
			'2024-08-18',
			'2024-08-17',
			'2024-08-16',
			'2024-08-15',
			'2024-08-14',
			'2024-08-13',
			'2024-08-12',
			'2024-08-11',
			'2024-08-10',
			'2024-08-09',
			'2024-08-08',
			'2024-08-07',
			'2024-08-06',
			'2024-08-05',
			'2024-08-04',
			'2024-08-03',
			'2024-08-02',
			'2024-08-01',
			'2024-07-31',
			'2024-07-30',
			'2024-07-29',
			'2024-07-28',
			'2024-07-27',
			'2024-07-26',
			'2024-07-25',
			'2024-07-24',
			'2024-07-23',
			'2024-07-22',
			'2024-07-21',
			'2024-07-20',
			'2024-07-19',
			'2024-07-18',
			'2024-07-17',
			'2024-07-16',
			'2024-07-15',
			'2024-07-14',
			'2024-07-13',
			'2024-07-12',
			'2024-07-11',
			'2024-07-10',
			'2024-07-09',
			'2024-07-08',
			'2024-07-07',
			'2024-07-06',
			'2024-07-05',
			'2024-07-04',
			'2024-07-03',
			'2024-07-02',
			'2024-07-01',
			'2024-06-30',
			'2024-06-29',
			'2024-06-28',
			'2024-06-27',
			'2024-06-26',
			'2024-06-25',
			'2024-06-24',
			'2024-06-23',
			'2024-06-22',
			'2024-06-21',
			'2024-06-20',
			'2024-06-19',
			'2024-06-18',
			'2024-06-17',
			'2024-06-16',
			'2024-06-15',
			'2024-06-14',
			'2024-06-13',
			'2024-06-12',
			'2024-06-11',
			'2024-06-10',
			'2024-06-09',
			'2024-06-08',
			'2024-06-07',
			'2024-06-06',
			'2024-06-05',
			'2024-06-04',
			'2024-06-03',
			'2024-06-02',
			'2024-06-01',
			'2024-05-31',
			'2024-05-30',
			'2024-05-29',
			'2024-05-28',
			'2024-05-27',
			'2024-05-26',
			'2024-05-25',
			'2024-05-24',
			'2024-05-23',
			'2024-05-22',
			'2024-05-21',
			'2024-05-20',
			'2024-05-19',
			'2024-05-18',
			'2024-05-17',
			'2024-05-16'
		],
		values: [
			5, 0, 1, 18, 12, 18, 2, 0, 18, 15, 17, 16, 2, 3, 11, 5, 13, 2, 15, 19, 15,
			14, 4, 1, 0, 5, 11, 11, 12, 1, 10, 16, 6, 0, 11, 2, 8, 9, 14, 0, 4, 13,
			13, 16, 2, 3, 13, 1, 17, 16, 6, 5, 3, 8, 18, 16, 15, 8, 5, 4, 16, 18, 5,
			11, 9, 15, 14, 10, 8, 8, 11, 14, 0, 4, 18, 15, 19, 4, 2, 6, 1, 13, 18, 12,
			13, 17, 2, 11, 16, 5, 16, 19, 2, 1, 12, 11, 17, 16, 1, 14
		]
	},
	{
		class_name: 'Pilatus PC-6 porter',
		dates: [
			'2024-08-23',
			'2024-08-22',
			'2024-08-21',
			'2024-08-20',
			'2024-08-19',
			'2024-08-18',
			'2024-08-17',
			'2024-08-16',
			'2024-08-15',
			'2024-08-14',
			'2024-08-13',
			'2024-08-12',
			'2024-08-11',
			'2024-08-10',
			'2024-08-09',
			'2024-08-08',
			'2024-08-07',
			'2024-08-06',
			'2024-08-05',
			'2024-08-04',
			'2024-08-03',
			'2024-08-02',
			'2024-08-01',
			'2024-07-31',
			'2024-07-30',
			'2024-07-29',
			'2024-07-28',
			'2024-07-27',
			'2024-07-26',
			'2024-07-25',
			'2024-07-24',
			'2024-07-23',
			'2024-07-22',
			'2024-07-21',
			'2024-07-20',
			'2024-07-19',
			'2024-07-18',
			'2024-07-17',
			'2024-07-16',
			'2024-07-15',
			'2024-07-14',
			'2024-07-13',
			'2024-07-12',
			'2024-07-11',
			'2024-07-10',
			'2024-07-09',
			'2024-07-08',
			'2024-07-07',
			'2024-07-06',
			'2024-07-05',
			'2024-07-04',
			'2024-07-03',
			'2024-07-02',
			'2024-07-01',
			'2024-06-30',
			'2024-06-29',
			'2024-06-28',
			'2024-06-27',
			'2024-06-26',
			'2024-06-25',
			'2024-06-24',
			'2024-06-23',
			'2024-06-22',
			'2024-06-21',
			'2024-06-20',
			'2024-06-19',
			'2024-06-18',
			'2024-06-17',
			'2024-06-16',
			'2024-06-15',
			'2024-06-14',
			'2024-06-13',
			'2024-06-12',
			'2024-06-11',
			'2024-06-10',
			'2024-06-09',
			'2024-06-08',
			'2024-06-07',
			'2024-06-06',
			'2024-06-05',
			'2024-06-04',
			'2024-06-03',
			'2024-06-02',
			'2024-06-01',
			'2024-05-31',
			'2024-05-30',
			'2024-05-29',
			'2024-05-28',
			'2024-05-27',
			'2024-05-26',
			'2024-05-25',
			'2024-05-24',
			'2024-05-23',
			'2024-05-22',
			'2024-05-21',
			'2024-05-20',
			'2024-05-19',
			'2024-05-18',
			'2024-05-17',
			'2024-05-16'
		],
		values: [
			18, 16, 19, 7, 3, 8, 17, 13, 17, 17, 7, 12, 13, 6, 1, 9, 13, 10, 17, 6, 5,
			13, 14, 8, 13, 13, 11, 8, 1, 0, 12, 15, 2, 11, 14, 15, 12, 18, 16, 18, 6,
			14, 7, 1, 18, 0, 14, 10, 0, 17, 0, 5, 18, 8, 15, 18, 8, 6, 6, 19, 9, 8,
			19, 9, 15, 7, 18, 2, 14, 9, 17, 0, 17, 17, 11, 12, 11, 4, 10, 4, 1, 8, 5,
			17, 17, 16, 5, 6, 14, 11, 14, 11, 12, 9, 16, 3, 6, 0, 14, 10
		]
	},
	{
		class_name: 'Lockheed P3 Orion',
		dates: [
			'2024-08-23',
			'2024-08-22',
			'2024-08-21',
			'2024-08-20',
			'2024-08-19',
			'2024-08-18',
			'2024-08-17',
			'2024-08-16',
			'2024-08-15',
			'2024-08-14',
			'2024-08-13',
			'2024-08-12',
			'2024-08-11',
			'2024-08-10',
			'2024-08-09',
			'2024-08-08',
			'2024-08-07',
			'2024-08-06',
			'2024-08-05',
			'2024-08-04',
			'2024-08-03',
			'2024-08-02',
			'2024-08-01',
			'2024-07-31',
			'2024-07-30',
			'2024-07-29',
			'2024-07-28',
			'2024-07-27',
			'2024-07-26',
			'2024-07-25',
			'2024-07-24',
			'2024-07-23',
			'2024-07-22',
			'2024-07-21',
			'2024-07-20',
			'2024-07-19',
			'2024-07-18',
			'2024-07-17',
			'2024-07-16',
			'2024-07-15',
			'2024-07-14',
			'2024-07-13',
			'2024-07-12',
			'2024-07-11',
			'2024-07-10',
			'2024-07-09',
			'2024-07-08',
			'2024-07-07',
			'2024-07-06',
			'2024-07-05',
			'2024-07-04',
			'2024-07-03',
			'2024-07-02',
			'2024-07-01',
			'2024-06-30',
			'2024-06-29',
			'2024-06-28',
			'2024-06-27',
			'2024-06-26',
			'2024-06-25',
			'2024-06-24',
			'2024-06-23',
			'2024-06-22',
			'2024-06-21',
			'2024-06-20',
			'2024-06-19',
			'2024-06-18',
			'2024-06-17',
			'2024-06-16',
			'2024-06-15',
			'2024-06-14',
			'2024-06-13',
			'2024-06-12',
			'2024-06-11',
			'2024-06-10',
			'2024-06-09',
			'2024-06-08',
			'2024-06-07',
			'2024-06-06',
			'2024-06-05',
			'2024-06-04',
			'2024-06-03',
			'2024-06-02',
			'2024-06-01',
			'2024-05-31',
			'2024-05-30',
			'2024-05-29',
			'2024-05-28',
			'2024-05-27',
			'2024-05-26',
			'2024-05-25',
			'2024-05-24',
			'2024-05-23',
			'2024-05-22',
			'2024-05-21',
			'2024-05-20',
			'2024-05-19',
			'2024-05-18',
			'2024-05-17',
			'2024-05-16'
		],
		values: [
			4, 10, 2, 16, 15, 11, 9, 4, 19, 5, 6, 18, 19, 17, 13, 5, 18, 15, 1, 17,
			13, 15, 3, 0, 12, 14, 15, 12, 16, 4, 18, 7, 0, 9, 1, 16, 15, 13, 18, 12,
			11, 12, 4, 19, 14, 19, 0, 1, 14, 4, 0, 1, 5, 12, 7, 9, 11, 4, 13, 0, 2, 1,
			2, 6, 19, 13, 2, 9, 12, 12, 14, 1, 14, 3, 3, 6, 13, 2, 17, 14, 6, 13, 1,
			5, 0, 18, 11, 7, 15, 19, 4, 3, 14, 13, 2, 5, 12, 19, 17, 1
		]
	},
	{
		class_name: 'Sukhoi 22 Fitter',
		dates: [
			'2024-08-23',
			'2024-08-22',
			'2024-08-21',
			'2024-08-20',
			'2024-08-19',
			'2024-08-18',
			'2024-08-17',
			'2024-08-16',
			'2024-08-15',
			'2024-08-14',
			'2024-08-13',
			'2024-08-12',
			'2024-08-11',
			'2024-08-10',
			'2024-08-09',
			'2024-08-08',
			'2024-08-07',
			'2024-08-06',
			'2024-08-05',
			'2024-08-04',
			'2024-08-03',
			'2024-08-02',
			'2024-08-01',
			'2024-07-31',
			'2024-07-30',
			'2024-07-29',
			'2024-07-28',
			'2024-07-27',
			'2024-07-26',
			'2024-07-25',
			'2024-07-24',
			'2024-07-23',
			'2024-07-22',
			'2024-07-21',
			'2024-07-20',
			'2024-07-19',
			'2024-07-18',
			'2024-07-17',
			'2024-07-16',
			'2024-07-15',
			'2024-07-14',
			'2024-07-13',
			'2024-07-12',
			'2024-07-11',
			'2024-07-10',
			'2024-07-09',
			'2024-07-08',
			'2024-07-07',
			'2024-07-06',
			'2024-07-05',
			'2024-07-04',
			'2024-07-03',
			'2024-07-02',
			'2024-07-01',
			'2024-06-30',
			'2024-06-29',
			'2024-06-28',
			'2024-06-27',
			'2024-06-26',
			'2024-06-25',
			'2024-06-24',
			'2024-06-23',
			'2024-06-22',
			'2024-06-21',
			'2024-06-20',
			'2024-06-19',
			'2024-06-18',
			'2024-06-17',
			'2024-06-16',
			'2024-06-15',
			'2024-06-14',
			'2024-06-13',
			'2024-06-12',
			'2024-06-11',
			'2024-06-10',
			'2024-06-09',
			'2024-06-08',
			'2024-06-07',
			'2024-06-06',
			'2024-06-05',
			'2024-06-04',
			'2024-06-03',
			'2024-06-02',
			'2024-06-01',
			'2024-05-31',
			'2024-05-30',
			'2024-05-29',
			'2024-05-28',
			'2024-05-27',
			'2024-05-26',
			'2024-05-25',
			'2024-05-24',
			'2024-05-23',
			'2024-05-22',
			'2024-05-21',
			'2024-05-20',
			'2024-05-19',
			'2024-05-18',
			'2024-05-17',
			'2024-05-16'
		],
		values: [
			6, 11, 12, 5, 13, 10, 16, 15, 13, 0, 4, 16, 8, 19, 1, 7, 7, 14, 13, 17, 5,
			9, 18, 1, 8, 15, 1, 0, 18, 15, 2, 14, 3, 18, 2, 8, 1, 1, 2, 18, 11, 14, 1,
			8, 1, 19, 3, 6, 9, 11, 18, 10, 1, 11, 17, 12, 11, 17, 6, 7, 18, 7, 12, 7,
			9, 19, 17, 12, 15, 14, 17, 3, 15, 0, 1, 16, 3, 2, 0, 8, 18, 4, 10, 15, 0,
			12, 19, 18, 3, 4, 18, 0, 3, 11, 14, 15, 7, 9, 18, 6
		]
	},
	{
		class_name: 'Fokker 100',
		dates: [
			'2024-08-23',
			'2024-08-22',
			'2024-08-21',
			'2024-08-20',
			'2024-08-19',
			'2024-08-18',
			'2024-08-17',
			'2024-08-16',
			'2024-08-15',
			'2024-08-14',
			'2024-08-13',
			'2024-08-12',
			'2024-08-11',
			'2024-08-10',
			'2024-08-09',
			'2024-08-08',
			'2024-08-07',
			'2024-08-06',
			'2024-08-05',
			'2024-08-04',
			'2024-08-03',
			'2024-08-02',
			'2024-08-01',
			'2024-07-31',
			'2024-07-30',
			'2024-07-29',
			'2024-07-28',
			'2024-07-27',
			'2024-07-26',
			'2024-07-25',
			'2024-07-24',
			'2024-07-23',
			'2024-07-22',
			'2024-07-21',
			'2024-07-20',
			'2024-07-19',
			'2024-07-18',
			'2024-07-17',
			'2024-07-16',
			'2024-07-15',
			'2024-07-14',
			'2024-07-13',
			'2024-07-12',
			'2024-07-11',
			'2024-07-10',
			'2024-07-09',
			'2024-07-08',
			'2024-07-07',
			'2024-07-06',
			'2024-07-05',
			'2024-07-04',
			'2024-07-03',
			'2024-07-02',
			'2024-07-01',
			'2024-06-30',
			'2024-06-29',
			'2024-06-28',
			'2024-06-27',
			'2024-06-26',
			'2024-06-25',
			'2024-06-24',
			'2024-06-23',
			'2024-06-22',
			'2024-06-21',
			'2024-06-20',
			'2024-06-19',
			'2024-06-18',
			'2024-06-17',
			'2024-06-16',
			'2024-06-15',
			'2024-06-14',
			'2024-06-13',
			'2024-06-12',
			'2024-06-11',
			'2024-06-10',
			'2024-06-09',
			'2024-06-08',
			'2024-06-07',
			'2024-06-06',
			'2024-06-05',
			'2024-06-04',
			'2024-06-03',
			'2024-06-02',
			'2024-06-01',
			'2024-05-31',
			'2024-05-30',
			'2024-05-29',
			'2024-05-28',
			'2024-05-27',
			'2024-05-26',
			'2024-05-25',
			'2024-05-24',
			'2024-05-23',
			'2024-05-22',
			'2024-05-21',
			'2024-05-20',
			'2024-05-19',
			'2024-05-18',
			'2024-05-17',
			'2024-05-16'
		],
		values: [
			0, 15, 9, 2, 11, 11, 13, 18, 2, 1, 12, 13, 19, 18, 15, 3, 11, 16, 0, 15,
			9, 12, 15, 0, 9, 6, 13, 13, 6, 7, 0, 9, 17, 11, 5, 19, 16, 16, 14, 6, 7,
			9, 9, 3, 1, 15, 17, 0, 18, 18, 0, 2, 19, 15, 15, 6, 8, 3, 7, 18, 5, 12,
			14, 7, 18, 16, 13, 19, 12, 0, 14, 18, 17, 0, 10, 10, 17, 18, 15, 17, 8,
			19, 3, 5, 17, 4, 5, 3, 13, 19, 6, 8, 16, 10, 10, 2, 15, 8, 0, 14
		]
	},
	{
		class_name: 'MIL Mi-8 / 17 Hip',
		dates: [
			'2024-08-23',
			'2024-08-22',
			'2024-08-21',
			'2024-08-20',
			'2024-08-19',
			'2024-08-18',
			'2024-08-17',
			'2024-08-16',
			'2024-08-15',
			'2024-08-14',
			'2024-08-13',
			'2024-08-12',
			'2024-08-11',
			'2024-08-10',
			'2024-08-09',
			'2024-08-08',
			'2024-08-07',
			'2024-08-06',
			'2024-08-05',
			'2024-08-04',
			'2024-08-03',
			'2024-08-02',
			'2024-08-01',
			'2024-07-31',
			'2024-07-30',
			'2024-07-29',
			'2024-07-28',
			'2024-07-27',
			'2024-07-26',
			'2024-07-25',
			'2024-07-24',
			'2024-07-23',
			'2024-07-22',
			'2024-07-21',
			'2024-07-20',
			'2024-07-19',
			'2024-07-18',
			'2024-07-17',
			'2024-07-16',
			'2024-07-15',
			'2024-07-14',
			'2024-07-13',
			'2024-07-12',
			'2024-07-11',
			'2024-07-10',
			'2024-07-09',
			'2024-07-08',
			'2024-07-07',
			'2024-07-06',
			'2024-07-05',
			'2024-07-04',
			'2024-07-03',
			'2024-07-02',
			'2024-07-01',
			'2024-06-30',
			'2024-06-29',
			'2024-06-28',
			'2024-06-27',
			'2024-06-26',
			'2024-06-25',
			'2024-06-24',
			'2024-06-23',
			'2024-06-22',
			'2024-06-21',
			'2024-06-20',
			'2024-06-19',
			'2024-06-18',
			'2024-06-17',
			'2024-06-16',
			'2024-06-15',
			'2024-06-14',
			'2024-06-13',
			'2024-06-12',
			'2024-06-11',
			'2024-06-10',
			'2024-06-09',
			'2024-06-08',
			'2024-06-07',
			'2024-06-06',
			'2024-06-05',
			'2024-06-04',
			'2024-06-03',
			'2024-06-02',
			'2024-06-01',
			'2024-05-31',
			'2024-05-30',
			'2024-05-29',
			'2024-05-28',
			'2024-05-27',
			'2024-05-26',
			'2024-05-25',
			'2024-05-24',
			'2024-05-23',
			'2024-05-22',
			'2024-05-21',
			'2024-05-20',
			'2024-05-19',
			'2024-05-18',
			'2024-05-17',
			'2024-05-16'
		],
		values: [
			13, 16, 5, 12, 3, 7, 18, 9, 10, 8, 13, 12, 19, 7, 11, 9, 9, 15, 19, 16,
			17, 15, 9, 17, 10, 3, 3, 18, 3, 11, 18, 16, 4, 18, 2, 15, 12, 16, 13, 15,
			9, 13, 11, 9, 5, 4, 2, 8, 7, 15, 2, 4, 2, 10, 3, 9, 6, 9, 2, 10, 4, 12,
			16, 13, 5, 11, 17, 5, 15, 18, 11, 8, 14, 17, 11, 4, 10, 5, 1, 14, 10, 7,
			8, 0, 15, 9, 2, 14, 2, 7, 2, 17, 13, 17, 4, 1, 16, 13, 7, 18
		]
	},
	{
		class_name: 'BAE 146',
		dates: [
			'2024-08-23',
			'2024-08-22',
			'2024-08-21',
			'2024-08-20',
			'2024-08-19',
			'2024-08-18',
			'2024-08-17',
			'2024-08-16',
			'2024-08-15',
			'2024-08-14',
			'2024-08-13',
			'2024-08-12',
			'2024-08-11',
			'2024-08-10',
			'2024-08-09',
			'2024-08-08',
			'2024-08-07',
			'2024-08-06',
			'2024-08-05',
			'2024-08-04',
			'2024-08-03',
			'2024-08-02',
			'2024-08-01',
			'2024-07-31',
			'2024-07-30',
			'2024-07-29',
			'2024-07-28',
			'2024-07-27',
			'2024-07-26',
			'2024-07-25',
			'2024-07-24',
			'2024-07-23',
			'2024-07-22',
			'2024-07-21',
			'2024-07-20',
			'2024-07-19',
			'2024-07-18',
			'2024-07-17',
			'2024-07-16',
			'2024-07-15',
			'2024-07-14',
			'2024-07-13',
			'2024-07-12',
			'2024-07-11',
			'2024-07-10',
			'2024-07-09',
			'2024-07-08',
			'2024-07-07',
			'2024-07-06',
			'2024-07-05',
			'2024-07-04',
			'2024-07-03',
			'2024-07-02',
			'2024-07-01',
			'2024-06-30',
			'2024-06-29',
			'2024-06-28',
			'2024-06-27',
			'2024-06-26',
			'2024-06-25',
			'2024-06-24',
			'2024-06-23',
			'2024-06-22',
			'2024-06-21',
			'2024-06-20',
			'2024-06-19',
			'2024-06-18',
			'2024-06-17',
			'2024-06-16',
			'2024-06-15',
			'2024-06-14',
			'2024-06-13',
			'2024-06-12',
			'2024-06-11',
			'2024-06-10',
			'2024-06-09',
			'2024-06-08',
			'2024-06-07',
			'2024-06-06',
			'2024-06-05',
			'2024-06-04',
			'2024-06-03',
			'2024-06-02',
			'2024-06-01',
			'2024-05-31',
			'2024-05-30',
			'2024-05-29',
			'2024-05-28',
			'2024-05-27',
			'2024-05-26',
			'2024-05-25',
			'2024-05-24',
			'2024-05-23',
			'2024-05-22',
			'2024-05-21',
			'2024-05-20',
			'2024-05-19',
			'2024-05-18',
			'2024-05-17',
			'2024-05-16'
		],
		values: [
			10, 17, 10, 12, 16, 10, 14, 6, 0, 3, 9, 18, 15, 5, 18, 9, 11, 10, 6, 7, 1,
			0, 14, 4, 15, 9, 9, 5, 15, 19, 4, 16, 0, 1, 18, 12, 18, 19, 9, 8, 9, 5, 2,
			8, 17, 19, 5, 17, 1, 13, 15, 10, 2, 10, 5, 16, 3, 12, 1, 8, 14, 7, 14, 17,
			7, 7, 16, 4, 1, 13, 6, 15, 0, 10, 13, 8, 12, 6, 1, 14, 0, 15, 18, 10, 9,
			3, 5, 17, 13, 2, 0, 6, 1, 1, 16, 7, 5, 5, 14, 9
		]
	},
	{
		class_name: 'Harbin Y-12',
		dates: [
			'2024-08-23',
			'2024-08-22',
			'2024-08-21',
			'2024-08-20',
			'2024-08-19',
			'2024-08-18',
			'2024-08-17',
			'2024-08-16',
			'2024-08-15',
			'2024-08-14',
			'2024-08-13',
			'2024-08-12',
			'2024-08-11',
			'2024-08-10',
			'2024-08-09',
			'2024-08-08',
			'2024-08-07',
			'2024-08-06',
			'2024-08-05',
			'2024-08-04',
			'2024-08-03',
			'2024-08-02',
			'2024-08-01',
			'2024-07-31',
			'2024-07-30',
			'2024-07-29',
			'2024-07-28',
			'2024-07-27',
			'2024-07-26',
			'2024-07-25',
			'2024-07-24',
			'2024-07-23',
			'2024-07-22',
			'2024-07-21',
			'2024-07-20',
			'2024-07-19',
			'2024-07-18',
			'2024-07-17',
			'2024-07-16',
			'2024-07-15',
			'2024-07-14',
			'2024-07-13',
			'2024-07-12',
			'2024-07-11',
			'2024-07-10',
			'2024-07-09',
			'2024-07-08',
			'2024-07-07',
			'2024-07-06',
			'2024-07-05',
			'2024-07-04',
			'2024-07-03',
			'2024-07-02',
			'2024-07-01',
			'2024-06-30',
			'2024-06-29',
			'2024-06-28',
			'2024-06-27',
			'2024-06-26',
			'2024-06-25',
			'2024-06-24',
			'2024-06-23',
			'2024-06-22',
			'2024-06-21',
			'2024-06-20',
			'2024-06-19',
			'2024-06-18',
			'2024-06-17',
			'2024-06-16',
			'2024-06-15',
			'2024-06-14',
			'2024-06-13',
			'2024-06-12',
			'2024-06-11',
			'2024-06-10',
			'2024-06-09',
			'2024-06-08',
			'2024-06-07',
			'2024-06-06',
			'2024-06-05',
			'2024-06-04',
			'2024-06-03',
			'2024-06-02',
			'2024-06-01',
			'2024-05-31',
			'2024-05-30',
			'2024-05-29',
			'2024-05-28',
			'2024-05-27',
			'2024-05-26',
			'2024-05-25',
			'2024-05-24',
			'2024-05-23',
			'2024-05-22',
			'2024-05-21',
			'2024-05-20',
			'2024-05-19',
			'2024-05-18',
			'2024-05-17',
			'2024-05-16'
		],
		values: [
			9, 16, 13, 10, 4, 15, 9, 6, 9, 14, 10, 12, 9, 2, 5, 10, 3, 5, 10, 7, 9, 9,
			1, 4, 15, 3, 10, 9, 4, 5, 14, 17, 5, 16, 9, 11, 2, 15, 4, 18, 18, 7, 1,
			12, 18, 19, 13, 10, 3, 12, 11, 14, 12, 3, 19, 11, 5, 2, 15, 17, 16, 13,
			18, 13, 6, 14, 8, 8, 3, 10, 5, 7, 14, 9, 1, 18, 19, 14, 15, 15, 8, 14, 7,
			0, 12, 4, 3, 16, 19, 2, 3, 14, 2, 13, 0, 11, 1, 17, 3, 18
		]
	},
	{
		class_name: 'Boeing 737',
		dates: [
			'2024-08-23',
			'2024-08-22',
			'2024-08-21',
			'2024-08-20',
			'2024-08-19',
			'2024-08-18',
			'2024-08-17',
			'2024-08-16',
			'2024-08-15',
			'2024-08-14',
			'2024-08-13',
			'2024-08-12',
			'2024-08-11',
			'2024-08-10',
			'2024-08-09',
			'2024-08-08',
			'2024-08-07',
			'2024-08-06',
			'2024-08-05',
			'2024-08-04',
			'2024-08-03',
			'2024-08-02',
			'2024-08-01',
			'2024-07-31',
			'2024-07-30',
			'2024-07-29',
			'2024-07-28',
			'2024-07-27',
			'2024-07-26',
			'2024-07-25',
			'2024-07-24',
			'2024-07-23',
			'2024-07-22',
			'2024-07-21',
			'2024-07-20',
			'2024-07-19',
			'2024-07-18',
			'2024-07-17',
			'2024-07-16',
			'2024-07-15',
			'2024-07-14',
			'2024-07-13',
			'2024-07-12',
			'2024-07-11',
			'2024-07-10',
			'2024-07-09',
			'2024-07-08',
			'2024-07-07',
			'2024-07-06',
			'2024-07-05',
			'2024-07-04',
			'2024-07-03',
			'2024-07-02',
			'2024-07-01',
			'2024-06-30',
			'2024-06-29',
			'2024-06-28',
			'2024-06-27',
			'2024-06-26',
			'2024-06-25',
			'2024-06-24',
			'2024-06-23',
			'2024-06-22',
			'2024-06-21',
			'2024-06-20',
			'2024-06-19',
			'2024-06-18',
			'2024-06-17',
			'2024-06-16',
			'2024-06-15',
			'2024-06-14',
			'2024-06-13',
			'2024-06-12',
			'2024-06-11',
			'2024-06-10',
			'2024-06-09',
			'2024-06-08',
			'2024-06-07',
			'2024-06-06',
			'2024-06-05',
			'2024-06-04',
			'2024-06-03',
			'2024-06-02',
			'2024-06-01',
			'2024-05-31',
			'2024-05-30',
			'2024-05-29',
			'2024-05-28',
			'2024-05-27',
			'2024-05-26',
			'2024-05-25',
			'2024-05-24',
			'2024-05-23',
			'2024-05-22',
			'2024-05-21',
			'2024-05-20',
			'2024-05-19',
			'2024-05-18',
			'2024-05-17',
			'2024-05-16'
		],
		values: [
			9, 11, 12, 8, 5, 0, 1, 3, 7, 3, 14, 18, 17, 18, 18, 16, 6, 15, 4, 6, 3,
			12, 4, 7, 18, 14, 2, 17, 14, 16, 10, 12, 9, 15, 17, 9, 15, 16, 11, 5, 17,
			12, 13, 15, 19, 7, 19, 16, 10, 16, 1, 3, 2, 9, 9, 6, 0, 14, 10, 0, 19, 11,
			9, 5, 19, 7, 18, 10, 18, 2, 1, 17, 12, 1, 8, 11, 10, 2, 6, 16, 13, 12, 5,
			2, 19, 10, 4, 4, 5, 14, 16, 16, 15, 18, 5, 5, 1, 6, 2, 11
		]
	},
	{
		class_name: 'Fokker F27 / 50',
		dates: [
			'2024-08-23',
			'2024-08-22',
			'2024-08-21',
			'2024-08-20',
			'2024-08-19',
			'2024-08-18',
			'2024-08-17',
			'2024-08-16',
			'2024-08-15',
			'2024-08-14',
			'2024-08-13',
			'2024-08-12',
			'2024-08-11',
			'2024-08-10',
			'2024-08-09',
			'2024-08-08',
			'2024-08-07',
			'2024-08-06',
			'2024-08-05',
			'2024-08-04',
			'2024-08-03',
			'2024-08-02',
			'2024-08-01',
			'2024-07-31',
			'2024-07-30',
			'2024-07-29',
			'2024-07-28',
			'2024-07-27',
			'2024-07-26',
			'2024-07-25',
			'2024-07-24',
			'2024-07-23',
			'2024-07-22',
			'2024-07-21',
			'2024-07-20',
			'2024-07-19',
			'2024-07-18',
			'2024-07-17',
			'2024-07-16',
			'2024-07-15',
			'2024-07-14',
			'2024-07-13',
			'2024-07-12',
			'2024-07-11',
			'2024-07-10',
			'2024-07-09',
			'2024-07-08',
			'2024-07-07',
			'2024-07-06',
			'2024-07-05',
			'2024-07-04',
			'2024-07-03',
			'2024-07-02',
			'2024-07-01',
			'2024-06-30',
			'2024-06-29',
			'2024-06-28',
			'2024-06-27',
			'2024-06-26',
			'2024-06-25',
			'2024-06-24',
			'2024-06-23',
			'2024-06-22',
			'2024-06-21',
			'2024-06-20',
			'2024-06-19',
			'2024-06-18',
			'2024-06-17',
			'2024-06-16',
			'2024-06-15',
			'2024-06-14',
			'2024-06-13',
			'2024-06-12',
			'2024-06-11',
			'2024-06-10',
			'2024-06-09',
			'2024-06-08',
			'2024-06-07',
			'2024-06-06',
			'2024-06-05',
			'2024-06-04',
			'2024-06-03',
			'2024-06-02',
			'2024-06-01',
			'2024-05-31',
			'2024-05-30',
			'2024-05-29',
			'2024-05-28',
			'2024-05-27',
			'2024-05-26',
			'2024-05-25',
			'2024-05-24',
			'2024-05-23',
			'2024-05-22',
			'2024-05-21',
			'2024-05-20',
			'2024-05-19',
			'2024-05-18',
			'2024-05-17',
			'2024-05-16'
		],
		values: [
			7, 3, 0, 16, 8, 3, 8, 11, 3, 8, 6, 5, 10, 17, 16, 3, 17, 3, 14, 11, 11,
			15, 8, 3, 14, 8, 12, 14, 9, 13, 3, 0, 13, 18, 13, 17, 4, 10, 3, 3, 15, 5,
			6, 5, 6, 5, 6, 9, 10, 13, 3, 18, 17, 6, 11, 0, 5, 9, 10, 9, 9, 2, 13, 8,
			2, 10, 13, 2, 9, 1, 12, 14, 10, 7, 9, 9, 0, 0, 14, 1, 11, 17, 12, 3, 7,
			14, 5, 0, 12, 16, 4, 16, 8, 9, 3, 13, 4, 10, 7, 16
		]
	},
	{
		class_name: 'Bombardier Canadair CRJ100/200',
		dates: [
			'2024-08-23',
			'2024-08-22',
			'2024-08-21',
			'2024-08-20',
			'2024-08-19',
			'2024-08-18',
			'2024-08-17',
			'2024-08-16',
			'2024-08-15',
			'2024-08-14',
			'2024-08-13',
			'2024-08-12',
			'2024-08-11',
			'2024-08-10',
			'2024-08-09',
			'2024-08-08',
			'2024-08-07',
			'2024-08-06',
			'2024-08-05',
			'2024-08-04',
			'2024-08-03',
			'2024-08-02',
			'2024-08-01',
			'2024-07-31',
			'2024-07-30',
			'2024-07-29',
			'2024-07-28',
			'2024-07-27',
			'2024-07-26',
			'2024-07-25',
			'2024-07-24',
			'2024-07-23',
			'2024-07-22',
			'2024-07-21',
			'2024-07-20',
			'2024-07-19',
			'2024-07-18',
			'2024-07-17',
			'2024-07-16',
			'2024-07-15',
			'2024-07-14',
			'2024-07-13',
			'2024-07-12',
			'2024-07-11',
			'2024-07-10',
			'2024-07-09',
			'2024-07-08',
			'2024-07-07',
			'2024-07-06',
			'2024-07-05',
			'2024-07-04',
			'2024-07-03',
			'2024-07-02',
			'2024-07-01',
			'2024-06-30',
			'2024-06-29',
			'2024-06-28',
			'2024-06-27',
			'2024-06-26',
			'2024-06-25',
			'2024-06-24',
			'2024-06-23',
			'2024-06-22',
			'2024-06-21',
			'2024-06-20',
			'2024-06-19',
			'2024-06-18',
			'2024-06-17',
			'2024-06-16',
			'2024-06-15',
			'2024-06-14',
			'2024-06-13',
			'2024-06-12',
			'2024-06-11',
			'2024-06-10',
			'2024-06-09',
			'2024-06-08',
			'2024-06-07',
			'2024-06-06',
			'2024-06-05',
			'2024-06-04',
			'2024-06-03',
			'2024-06-02',
			'2024-06-01',
			'2024-05-31',
			'2024-05-30',
			'2024-05-29',
			'2024-05-28',
			'2024-05-27',
			'2024-05-26',
			'2024-05-25',
			'2024-05-24',
			'2024-05-23',
			'2024-05-22',
			'2024-05-21',
			'2024-05-20',
			'2024-05-19',
			'2024-05-18',
			'2024-05-17',
			'2024-05-16'
		],
		values: [
			8, 8, 3, 12, 15, 1, 11, 3, 16, 19, 10, 7, 1, 2, 8, 1, 13, 1, 17, 9, 0, 2,
			9, 2, 15, 10, 16, 0, 9, 1, 18, 18, 17, 1, 0, 16, 5, 5, 16, 13, 17, 4, 10,
			18, 10, 12, 8, 11, 15, 14, 8, 16, 16, 5, 18, 6, 11, 0, 15, 18, 7, 4, 8,
			11, 16, 10, 0, 5, 18, 1, 2, 9, 12, 18, 12, 18, 10, 18, 10, 2, 1, 9, 1, 2,
			9, 15, 11, 16, 6, 8, 15, 5, 16, 10, 2, 8, 15, 4, 11, 12
		]
	},
	{
		class_name: 'Airbus A300',
		dates: [
			'2024-08-23',
			'2024-08-22',
			'2024-08-21',
			'2024-08-20',
			'2024-08-19',
			'2024-08-18',
			'2024-08-17',
			'2024-08-16',
			'2024-08-15',
			'2024-08-14',
			'2024-08-13',
			'2024-08-12',
			'2024-08-11',
			'2024-08-10',
			'2024-08-09',
			'2024-08-08',
			'2024-08-07',
			'2024-08-06',
			'2024-08-05',
			'2024-08-04',
			'2024-08-03',
			'2024-08-02',
			'2024-08-01',
			'2024-07-31',
			'2024-07-30',
			'2024-07-29',
			'2024-07-28',
			'2024-07-27',
			'2024-07-26',
			'2024-07-25',
			'2024-07-24',
			'2024-07-23',
			'2024-07-22',
			'2024-07-21',
			'2024-07-20',
			'2024-07-19',
			'2024-07-18',
			'2024-07-17',
			'2024-07-16',
			'2024-07-15',
			'2024-07-14',
			'2024-07-13',
			'2024-07-12',
			'2024-07-11',
			'2024-07-10',
			'2024-07-09',
			'2024-07-08',
			'2024-07-07',
			'2024-07-06',
			'2024-07-05',
			'2024-07-04',
			'2024-07-03',
			'2024-07-02',
			'2024-07-01',
			'2024-06-30',
			'2024-06-29',
			'2024-06-28',
			'2024-06-27',
			'2024-06-26',
			'2024-06-25',
			'2024-06-24',
			'2024-06-23',
			'2024-06-22',
			'2024-06-21',
			'2024-06-20',
			'2024-06-19',
			'2024-06-18',
			'2024-06-17',
			'2024-06-16',
			'2024-06-15',
			'2024-06-14',
			'2024-06-13',
			'2024-06-12',
			'2024-06-11',
			'2024-06-10',
			'2024-06-09',
			'2024-06-08',
			'2024-06-07',
			'2024-06-06',
			'2024-06-05',
			'2024-06-04',
			'2024-06-03',
			'2024-06-02',
			'2024-06-01',
			'2024-05-31',
			'2024-05-30',
			'2024-05-29',
			'2024-05-28',
			'2024-05-27',
			'2024-05-26',
			'2024-05-25',
			'2024-05-24',
			'2024-05-23',
			'2024-05-22',
			'2024-05-21',
			'2024-05-20',
			'2024-05-19',
			'2024-05-18',
			'2024-05-17',
			'2024-05-16'
		],
		values: [
			18, 3, 2, 1, 16, 14, 15, 13, 0, 0, 18, 13, 0, 15, 5, 14, 16, 17, 16, 13,
			12, 7, 7, 3, 17, 11, 11, 19, 4, 18, 6, 18, 2, 14, 2, 14, 16, 9, 6, 2, 0,
			3, 18, 19, 11, 16, 11, 3, 10, 0, 11, 1, 11, 1, 8, 19, 5, 19, 18, 5, 11, 4,
			7, 9, 16, 19, 3, 5, 3, 13, 15, 6, 8, 14, 15, 13, 13, 8, 2, 12, 8, 13, 0,
			13, 0, 0, 6, 8, 18, 8, 3, 3, 12, 14, 3, 1, 1, 16, 11, 10
		]
	},
	{
		class_name: 'Unknown',
		dates: [
			'2024-08-23',
			'2024-08-22',
			'2024-08-21',
			'2024-08-20',
			'2024-08-19',
			'2024-08-18',
			'2024-08-17',
			'2024-08-16',
			'2024-08-15',
			'2024-08-14',
			'2024-08-13',
			'2024-08-12',
			'2024-08-11',
			'2024-08-10',
			'2024-08-09',
			'2024-08-08',
			'2024-08-07',
			'2024-08-06',
			'2024-08-05',
			'2024-08-04',
			'2024-08-03',
			'2024-08-02',
			'2024-08-01',
			'2024-07-31',
			'2024-07-30',
			'2024-07-29',
			'2024-07-28',
			'2024-07-27',
			'2024-07-26',
			'2024-07-25',
			'2024-07-24',
			'2024-07-23',
			'2024-07-22',
			'2024-07-21',
			'2024-07-20',
			'2024-07-19',
			'2024-07-18',
			'2024-07-17',
			'2024-07-16',
			'2024-07-15',
			'2024-07-14',
			'2024-07-13',
			'2024-07-12',
			'2024-07-11',
			'2024-07-10',
			'2024-07-09',
			'2024-07-08',
			'2024-07-07',
			'2024-07-06',
			'2024-07-05',
			'2024-07-04',
			'2024-07-03',
			'2024-07-02',
			'2024-07-01',
			'2024-06-30',
			'2024-06-29',
			'2024-06-28',
			'2024-06-27',
			'2024-06-26',
			'2024-06-25',
			'2024-06-24',
			'2024-06-23',
			'2024-06-22',
			'2024-06-21',
			'2024-06-20',
			'2024-06-19',
			'2024-06-18',
			'2024-06-17',
			'2024-06-16',
			'2024-06-15',
			'2024-06-14',
			'2024-06-13',
			'2024-06-12',
			'2024-06-11',
			'2024-06-10',
			'2024-06-09',
			'2024-06-08',
			'2024-06-07',
			'2024-06-06',
			'2024-06-05',
			'2024-06-04',
			'2024-06-03',
			'2024-06-02',
			'2024-06-01',
			'2024-05-31',
			'2024-05-30',
			'2024-05-29',
			'2024-05-28',
			'2024-05-27',
			'2024-05-26',
			'2024-05-25',
			'2024-05-24',
			'2024-05-23',
			'2024-05-22',
			'2024-05-21',
			'2024-05-20',
			'2024-05-19',
			'2024-05-18',
			'2024-05-17',
			'2024-05-16'
		],
		values: [
			2, 12, 16, 18, 12, 16, 4, 5, 11, 16, 7, 7, 8, 19, 6, 0, 13, 1, 12, 0, 14,
			8, 19, 7, 3, 10, 1, 15, 7, 12, 8, 3, 5, 18, 8, 1, 7, 2, 17, 19, 0, 11, 0,
			18, 16, 15, 14, 3, 3, 17, 10, 14, 19, 11, 9, 10, 12, 4, 0, 9, 19, 16, 6,
			7, 9, 3, 3, 11, 15, 2, 1, 1, 6, 14, 16, 18, 15, 14, 16, 10, 0, 0, 19, 18,
			19, 9, 8, 9, 0, 13, 4, 11, 18, 6, 2, 9, 8, 15, 19, 8
		]
	},
	{
		class_name: 'AN-74 Coaler',
		dates: [
			'2024-08-23',
			'2024-08-22',
			'2024-08-21',
			'2024-08-20',
			'2024-08-19',
			'2024-08-18',
			'2024-08-17',
			'2024-08-16',
			'2024-08-15',
			'2024-08-14',
			'2024-08-13',
			'2024-08-12',
			'2024-08-11',
			'2024-08-10',
			'2024-08-09',
			'2024-08-08',
			'2024-08-07',
			'2024-08-06',
			'2024-08-05',
			'2024-08-04',
			'2024-08-03',
			'2024-08-02',
			'2024-08-01',
			'2024-07-31',
			'2024-07-30',
			'2024-07-29',
			'2024-07-28',
			'2024-07-27',
			'2024-07-26',
			'2024-07-25',
			'2024-07-24',
			'2024-07-23',
			'2024-07-22',
			'2024-07-21',
			'2024-07-20',
			'2024-07-19',
			'2024-07-18',
			'2024-07-17',
			'2024-07-16',
			'2024-07-15',
			'2024-07-14',
			'2024-07-13',
			'2024-07-12',
			'2024-07-11',
			'2024-07-10',
			'2024-07-09',
			'2024-07-08',
			'2024-07-07',
			'2024-07-06',
			'2024-07-05',
			'2024-07-04',
			'2024-07-03',
			'2024-07-02',
			'2024-07-01',
			'2024-06-30',
			'2024-06-29',
			'2024-06-28',
			'2024-06-27',
			'2024-06-26',
			'2024-06-25',
			'2024-06-24',
			'2024-06-23',
			'2024-06-22',
			'2024-06-21',
			'2024-06-20',
			'2024-06-19',
			'2024-06-18',
			'2024-06-17',
			'2024-06-16',
			'2024-06-15',
			'2024-06-14',
			'2024-06-13',
			'2024-06-12',
			'2024-06-11',
			'2024-06-10',
			'2024-06-09',
			'2024-06-08',
			'2024-06-07',
			'2024-06-06',
			'2024-06-05',
			'2024-06-04',
			'2024-06-03',
			'2024-06-02',
			'2024-06-01',
			'2024-05-31',
			'2024-05-30',
			'2024-05-29',
			'2024-05-28',
			'2024-05-27',
			'2024-05-26',
			'2024-05-25',
			'2024-05-24',
			'2024-05-23',
			'2024-05-22',
			'2024-05-21',
			'2024-05-20',
			'2024-05-19',
			'2024-05-18',
			'2024-05-17',
			'2024-05-16'
		],
		values: [
			3, 3, 7, 0, 11, 19, 3, 12, 16, 12, 18, 9, 15, 9, 11, 18, 8, 11, 2, 15, 10,
			2, 4, 12, 2, 16, 9, 7, 2, 0, 13, 12, 12, 3, 14, 11, 4, 3, 19, 5, 2, 13,
			18, 15, 2, 6, 9, 9, 11, 4, 11, 19, 16, 4, 14, 13, 4, 4, 12, 10, 9, 2, 4,
			16, 12, 9, 19, 15, 0, 9, 12, 14, 3, 5, 5, 2, 6, 1, 0, 11, 18, 13, 2, 17,
			12, 10, 1, 14, 3, 5, 9, 10, 8, 9, 13, 5, 0, 7, 16, 15
		]
	},
	{
		class_name: 'Dassault Falcon 20 (Probable)',
		dates: [
			'2024-08-23',
			'2024-08-22',
			'2024-08-21',
			'2024-08-20',
			'2024-08-19',
			'2024-08-18',
			'2024-08-17',
			'2024-08-16',
			'2024-08-15',
			'2024-08-14',
			'2024-08-13',
			'2024-08-12',
			'2024-08-11',
			'2024-08-10',
			'2024-08-09',
			'2024-08-08',
			'2024-08-07',
			'2024-08-06',
			'2024-08-05',
			'2024-08-04',
			'2024-08-03',
			'2024-08-02',
			'2024-08-01',
			'2024-07-31',
			'2024-07-30',
			'2024-07-29',
			'2024-07-28',
			'2024-07-27',
			'2024-07-26',
			'2024-07-25',
			'2024-07-24',
			'2024-07-23',
			'2024-07-22',
			'2024-07-21',
			'2024-07-20',
			'2024-07-19',
			'2024-07-18',
			'2024-07-17',
			'2024-07-16',
			'2024-07-15',
			'2024-07-14',
			'2024-07-13',
			'2024-07-12',
			'2024-07-11',
			'2024-07-10',
			'2024-07-09',
			'2024-07-08',
			'2024-07-07',
			'2024-07-06',
			'2024-07-05',
			'2024-07-04',
			'2024-07-03',
			'2024-07-02',
			'2024-07-01',
			'2024-06-30',
			'2024-06-29',
			'2024-06-28',
			'2024-06-27',
			'2024-06-26',
			'2024-06-25',
			'2024-06-24',
			'2024-06-23',
			'2024-06-22',
			'2024-06-21',
			'2024-06-20',
			'2024-06-19',
			'2024-06-18',
			'2024-06-17',
			'2024-06-16',
			'2024-06-15',
			'2024-06-14',
			'2024-06-13',
			'2024-06-12',
			'2024-06-11',
			'2024-06-10',
			'2024-06-09',
			'2024-06-08',
			'2024-06-07',
			'2024-06-06',
			'2024-06-05',
			'2024-06-04',
			'2024-06-03',
			'2024-06-02',
			'2024-06-01',
			'2024-05-31',
			'2024-05-30',
			'2024-05-29',
			'2024-05-28',
			'2024-05-27',
			'2024-05-26',
			'2024-05-25',
			'2024-05-24',
			'2024-05-23',
			'2024-05-22',
			'2024-05-21',
			'2024-05-20',
			'2024-05-19',
			'2024-05-18',
			'2024-05-17',
			'2024-05-16'
		],
		values: [
			5, 1, 6, 7, 8, 16, 6, 17, 18, 16, 5, 2, 5, 11, 6, 3, 3, 11, 5, 0, 15, 7,
			0, 2, 18, 18, 19, 19, 11, 10, 4, 6, 8, 5, 5, 1, 16, 13, 4, 15, 12, 14, 11,
			13, 2, 10, 15, 9, 7, 19, 4, 12, 15, 13, 6, 0, 1, 0, 18, 17, 17, 10, 16,
			10, 15, 2, 4, 4, 14, 7, 8, 16, 4, 10, 11, 11, 14, 17, 0, 19, 14, 1, 4, 6,
			18, 12, 11, 14, 4, 6, 15, 1, 18, 13, 8, 17, 13, 1, 14, 17
		]
	},
	{
		class_name: 'Airbus A340',
		dates: [
			'2024-08-23',
			'2024-08-22',
			'2024-08-21',
			'2024-08-20',
			'2024-08-19',
			'2024-08-18',
			'2024-08-17',
			'2024-08-16',
			'2024-08-15',
			'2024-08-14',
			'2024-08-13',
			'2024-08-12',
			'2024-08-11',
			'2024-08-10',
			'2024-08-09',
			'2024-08-08',
			'2024-08-07',
			'2024-08-06',
			'2024-08-05',
			'2024-08-04',
			'2024-08-03',
			'2024-08-02',
			'2024-08-01',
			'2024-07-31',
			'2024-07-30',
			'2024-07-29',
			'2024-07-28',
			'2024-07-27',
			'2024-07-26',
			'2024-07-25',
			'2024-07-24',
			'2024-07-23',
			'2024-07-22',
			'2024-07-21',
			'2024-07-20',
			'2024-07-19',
			'2024-07-18',
			'2024-07-17',
			'2024-07-16',
			'2024-07-15',
			'2024-07-14',
			'2024-07-13',
			'2024-07-12',
			'2024-07-11',
			'2024-07-10',
			'2024-07-09',
			'2024-07-08',
			'2024-07-07',
			'2024-07-06',
			'2024-07-05',
			'2024-07-04',
			'2024-07-03',
			'2024-07-02',
			'2024-07-01',
			'2024-06-30',
			'2024-06-29',
			'2024-06-28',
			'2024-06-27',
			'2024-06-26',
			'2024-06-25',
			'2024-06-24',
			'2024-06-23',
			'2024-06-22',
			'2024-06-21',
			'2024-06-20',
			'2024-06-19',
			'2024-06-18',
			'2024-06-17',
			'2024-06-16',
			'2024-06-15',
			'2024-06-14',
			'2024-06-13',
			'2024-06-12',
			'2024-06-11',
			'2024-06-10',
			'2024-06-09',
			'2024-06-08',
			'2024-06-07',
			'2024-06-06',
			'2024-06-05',
			'2024-06-04',
			'2024-06-03',
			'2024-06-02',
			'2024-06-01',
			'2024-05-31',
			'2024-05-30',
			'2024-05-29',
			'2024-05-28',
			'2024-05-27',
			'2024-05-26',
			'2024-05-25',
			'2024-05-24',
			'2024-05-23',
			'2024-05-22',
			'2024-05-21',
			'2024-05-20',
			'2024-05-19',
			'2024-05-18',
			'2024-05-17',
			'2024-05-16'
		],
		values: [
			9, 14, 6, 1, 18, 8, 15, 9, 15, 1, 3, 16, 0, 15, 15, 1, 3, 11, 17, 12, 0,
			9, 16, 7, 15, 8, 2, 7, 1, 11, 6, 7, 6, 18, 4, 12, 16, 4, 17, 19, 8, 3, 17,
			17, 11, 0, 3, 12, 10, 11, 12, 11, 16, 3, 7, 1, 13, 15, 1, 5, 8, 1, 8, 15,
			16, 13, 12, 2, 16, 7, 3, 2, 2, 10, 10, 2, 18, 10, 15, 16, 16, 19, 7, 12,
			14, 12, 9, 11, 0, 14, 12, 14, 16, 19, 3, 3, 14, 5, 0, 10
		]
	},
	{
		class_name: 'background',
		dates: [
			'2024-08-23',
			'2024-08-22',
			'2024-08-21',
			'2024-08-20',
			'2024-08-19',
			'2024-08-18',
			'2024-08-17',
			'2024-08-16',
			'2024-08-15',
			'2024-08-14',
			'2024-08-13',
			'2024-08-12',
			'2024-08-11',
			'2024-08-10',
			'2024-08-09',
			'2024-08-08',
			'2024-08-07',
			'2024-08-06',
			'2024-08-05',
			'2024-08-04',
			'2024-08-03',
			'2024-08-02',
			'2024-08-01',
			'2024-07-31',
			'2024-07-30',
			'2024-07-29',
			'2024-07-28',
			'2024-07-27',
			'2024-07-26',
			'2024-07-25',
			'2024-07-24',
			'2024-07-23',
			'2024-07-22',
			'2024-07-21',
			'2024-07-20',
			'2024-07-19',
			'2024-07-18',
			'2024-07-17',
			'2024-07-16',
			'2024-07-15',
			'2024-07-14',
			'2024-07-13',
			'2024-07-12',
			'2024-07-11',
			'2024-07-10',
			'2024-07-09',
			'2024-07-08',
			'2024-07-07',
			'2024-07-06',
			'2024-07-05',
			'2024-07-04',
			'2024-07-03',
			'2024-07-02',
			'2024-07-01',
			'2024-06-30',
			'2024-06-29',
			'2024-06-28',
			'2024-06-27',
			'2024-06-26',
			'2024-06-25',
			'2024-06-24',
			'2024-06-23',
			'2024-06-22',
			'2024-06-21',
			'2024-06-20',
			'2024-06-19',
			'2024-06-18',
			'2024-06-17',
			'2024-06-16',
			'2024-06-15',
			'2024-06-14',
			'2024-06-13',
			'2024-06-12',
			'2024-06-11',
			'2024-06-10',
			'2024-06-09',
			'2024-06-08',
			'2024-06-07',
			'2024-06-06',
			'2024-06-05',
			'2024-06-04',
			'2024-06-03',
			'2024-06-02',
			'2024-06-01',
			'2024-05-31',
			'2024-05-30',
			'2024-05-29',
			'2024-05-28',
			'2024-05-27',
			'2024-05-26',
			'2024-05-25',
			'2024-05-24',
			'2024-05-23',
			'2024-05-22',
			'2024-05-21',
			'2024-05-20',
			'2024-05-19',
			'2024-05-18',
			'2024-05-17',
			'2024-05-16'
		],
		values: [
			5, 3, 17, 2, 4, 3, 12, 13, 4, 4, 7, 15, 13, 16, 2, 6, 11, 19, 11, 16, 11,
			17, 18, 15, 9, 7, 18, 18, 2, 17, 5, 10, 14, 4, 16, 18, 11, 15, 13, 5, 14,
			14, 16, 10, 0, 9, 11, 15, 15, 14, 5, 19, 19, 4, 11, 19, 9, 1, 17, 14, 4,
			8, 13, 1, 16, 1, 7, 18, 15, 5, 5, 4, 4, 5, 4, 6, 5, 13, 17, 9, 17, 1, 6,
			13, 7, 18, 2, 9, 5, 10, 15, 1, 2, 13, 5, 8, 19, 17, 10, 2
		]
	}
];
