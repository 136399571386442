import L from 'leaflet';
import * as turf from '@turf/turf';
import config from '../config';
import proj4 from 'proj4';

export const getLatLongCap = (geojson) => {
	let aoi = JSON.parse(geojson);
	var geojsonFeature = {
		type: 'Feature',
		properties: {},
		geometry: {
			type: 'Polygon',
			coordinates: aoi.coordinates[0]
		}
	};

	var aoiLayer = L.geoJSON(geojsonFeature);
	var latlng = aoiLayer.getBounds().getCenter();

	return latlng;
};

export const addLabelToPolygon = (polygon, id) => {
	var bounds = polygon.getBounds();
	var center = bounds.getCenter();

	var labelIcon = L.divIcon({
		className: 'label-icon',
		html: id,
		iconSize: null // Allow the icon size to adjust based on content
	});
	var marker = L.marker(center, { icon: labelIcon });
	polygon.labelMarker = marker; // Store the marker reference in the polygon object
	return marker;
};

export const getCenterPoint = (geojson) => {
	const layer = L.geoJSON(geojson);

	const bounds = layer.getBounds();

	const center = bounds.getCenter();

	return center;
};

export const findCollectionCentroid = (featureCollection) => {
	const centroids = featureCollection.features.map(
		(feature) => turf.centroid(feature).geometry.coordinates
	);

	// Calculate the average of the centroids
	const numCentroids = centroids.length;
	const avgX =
		centroids.reduce((sum, coord) => sum + coord[0], 0) / numCentroids;
	const avgY =
		centroids.reduce((sum, coord) => sum + coord[1], 0) / numCentroids;

	return [avgY,avgX];
};

// Define EPSG:3857 and EPSG:4326 projections
proj4.defs('EPSG:3857', '+proj=merc +datum=WGS84 +units=m +no_defs');
proj4.defs('EPSG:4326', '+proj=latlong +datum=WGS84 +no_defs');

// Function to convert coordinates in a FeatureCollection
export const convertFeatureCollectionToLatLong = (featureCollection) => {
	// Define the projections
	const EPSG4326 = 'EPSG:4326'; // WGS 84 - latitude/longitude
	const EPSG3857 = 'EPSG:3857'; // Web Mercator

	return {
        ...featureCollection,
        features: featureCollection.features.map((feature) => {
            const convertedCoordinates = feature.geometry.coordinates.map((polygon) =>
                polygon.map((coord) => {
                    // Ensure coord is an array with two values [x, y]
                    if (coord.length !== 2) {
                        throw new Error('Coordinate must be an array with two elements');
                    }
                    
                    // Convert the coordinates from EPSG:3857 to EPSG:4326
                    const [longitude, latitude] = proj4('EPSG:3857', 'EPSG:4326', coord);
                    
                    // Return in [latitude, longitude] format
                    return [longitude, latitude];
                })
            );

            return {
                ...feature,
                geometry: {
                    ...feature.geometry,
                    coordinates: convertedCoordinates
                }
            };
        })
    };
};

export const getCenterOfFeatureCollection = (featureCollection) => {
	let bounds = getBoundingBox(featureCollection);
	return bounds.getCenter();
};

export const getBoundingBox = (featureCollection) => {
	let bounds = L.geoJSON(featureCollection).getBounds();
	return bounds;
};

function hexToRgb(hex) {
	let r = 0,
		g = 0,
		b = 0;
	// 3 digits
	if (hex.length == 4) {
		r = parseInt(hex[1] + hex[1], 16);
		g = parseInt(hex[2] + hex[2], 16);
		b = parseInt(hex[3] + hex[3], 16);
	}
	// 6 digits
	else if (hex.length == 7) {
		r = parseInt(hex[1] + hex[2], 16);
		g = parseInt(hex[3] + hex[4], 16);
		b = parseInt(hex[5] + hex[6], 16);
	}
	return { r, g, b };
}

function getLuminance({ r, g, b }) {
	const [R, G, B] = [r, g, b].map((v) => {
		v /= 255;
		return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
	});
	return 0.2126 * R + 0.7152 * G + 0.0722 * B;
}

function getContrastRatio(luminance1, luminance2) {
	const lighter = Math.max(luminance1, luminance2);
	const darker = Math.min(luminance1, luminance2);
	return (lighter + 0.05) / (darker + 0.05);
}

export const getBestTextColor = (backgroundColor) => {
	const rgb = hexToRgb(backgroundColor);
	const luminance = getLuminance(rgb);
	const whiteLuminance = getLuminance({ r: 255, g: 255, b: 255 });
	const blackLuminance = getLuminance({ r: 0, g: 0, b: 0 });

	const contrastWithWhite = getContrastRatio(luminance, whiteLuminance);
	const contrastWithBlack = getContrastRatio(luminance, blackLuminance);

	return contrastWithWhite > contrastWithBlack ? 'white' : 'black';
};

export const validatePolygon = (polygon, aoi) => {
	let valid = true;

	// Create a Turf.js polygon from the provided polygon coordinates
	const drawingPolygon = turf.polygon(polygon.geometry.coordinates);

	let polygon1 = turf.polygon(polygon.geometry.coordinates);
	let polygon2 = turf.polygon(aoi.coordinates[0]);

	const result = isAnyPointOutside(polygon1, polygon2);

	if (result) {
		return false;
	} else {
		const kinks = turf.kinks(drawingPolygon);

		if (kinks.features.length > 0) {
			valid = false;
			return false;
		} else {
			return true;
		}
	}
};

const isAnyPointOutside = (poly1, poly2) => {
	let isOutside = false;

	turf.coordEach(poly1, (coord) => {
		const point = turf.point(coord);
		if (!turf.booleanContains(poly2, point)) {
			isOutside = true;
		}
	});

	return isOutside;
};

export const getSentinelHubUrl = (imageData, currentCap) => {

	console.log(imageData)
	console.log(currentCap)

	if (imageData.hasOwnProperty('aoi')) {
		let sens;
		let sensEval;

		let startDateTime = currentCap.captured;
		let endDateTime = currentCap.captured.slice(0, 10) + ' 23:59:59.999';

		if (currentCap.sensor === 'landsat-8-l1tp') {
			sensEval = config.landsatEval;
			sens = 'AWS_LOTL1';
		} else {
			sensEval = config.sentinelEval;
			sens = 'S2L1C';
		}

		var latlong = getLatLongCap(imageData.aoi);
		let url = `https://apps.sentinel-hub.com/eo-browser/?zoom=14&lat=${latlong.lat}&lng=${latlong.lng}&themeId=DEFAULT-THEME&visualizationUrl=https%3A%2F%2Fservices.sentinel-hub.com%2Fogc%2Fwms%2Fe35192fe-33a1-41f3-b798-b755e771c5a5&evalscript=${sensEval}&datasetId=${sens}&fromTime=${startDateTime}&toTime=${endDateTime}&upsampling=BICUBIC&demSource3D=\"MAPZEN\"#custom-composite`;

		return url;
	}
};
