import React, { useEffect, useState, useRef } from 'react';
import { Bar, Line } from 'react-chartjs-2';

import Plot from 'react-plotly.js';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	TimeScale,
	registerables
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import ReactSlider from 'react-slider';
import Skeleton from 'react-loading-skeleton';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	...registerables
);

const PixelCountPlot = ({ pixelPlotData, captureDate }) => {
	const chartRef = useRef(null);
	let data = {
		labels: [],
		datasets: [
			{
				fill: true,
				tension: 0.2,
				backgroundColor: 'transparent',
				borderColor: 'orange',
				borderWidth: 2,
				data: []
			}
		]
	};

	try {
		function formatDate(dateString) {
			const date = new Date(dateString);
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			return `${year}-${month}-${day}`;
		}

		const formattedDates = JSON.parse(pixelPlotData).data.dates.map(
			(dateString) => formatDate(dateString)
		);

		data = {
			labels: formattedDates,
			datasets: [
				{
					fill: true,
					tension: 0.2,
					backgroundColor: 'transparent',
					borderColor: 'orange',
					borderWidth: 2,
					data: JSON.parse(pixelPlotData).data['pixel_count']
				}
			]
		};
	} catch (e) {
		//console.log(e);
	}

	// var options = {
	// 	responsive: true,
	// 	maintainAspectRatio: false,
	// 	plugins: {
	// 		title: {
	// 			display: true,
	// 			text: 'Pixel Count'
	// 		}
	// 	},
	// 	legend: { display: false },
	// 	elements: {
	// 		line: {
	// 			tension: 0.2
	// 		}
	// 	},
	// 	scales: {
	// 		x: {
	// 			type: 'time',
	// 			time: {
	// 				parser: 'yyyy-MM-dd',
	// 				unit: 'day',
	// 				displayFormats: {
	// 					day: 'MMM d'
	// 				}
	// 			},
	// 			ticks: {
	// 				source: 'auto',
	// 				autoSkip: false,
	// 				maxTicksLimit: 100,
	// 				callback: function (value, index, values) {
	// 					try {
	// 						const date = new Date(value);
	// 						return date.toLocaleDateString('en-US', {
	// 							month: 'short',
	// 							day: 'numeric'
	// 						});
	// 					} catch (e) {
	// 						console.log(e);
	// 					}
	// 				}
	// 			},
	// 			grid: {
	// 				display: false
	// 			}
	// 		},
	// 		y: {
	// 			beginAtZero: true,

	// 			grid: {
	// 				//display: false
	// 			}
	// 		}
	// 	}
	// };

	const options = {
		responsive: true,
		maintainAspectRatio: false, // Ensure this is within the options object
		plugins: {
			title: {
				display: true,
				text: 'Pixel Count Over Time'
			},
			legend: {
				display: false // Disable legend if needed
			}
		},
		scales: {
			x: {
				type: 'time',
				time: {
					unit: 'day'
				}
			},
			y: {
				beginAtZero: true
			}
		}
	};

	if (!pixelPlotData) {
		return (
			<fieldset
				className="form-group p-0 p-md-3 border rounded-3"
				style={{ height: '95%' }}
			>
				<legend className="float-none w-auto px-3 my-0 fs-6">
					Pixel Count Data{' '}
					<button style={{ backgroundColor: '#f8f9fa', border: 'none' }}>
						<i
							data-tooltip-id="historic-plot-data"
							//onClick={(e) => fetchHistoricData()}
							className="fa-solid fa-arrows-rotate ms-1 fa-sm"
							style={{ color: 'grey' }}
						></i>
					</button>
				</legend>

				<div style={{ position: 'relative', width: '100%', height: '280px' }}>
					<Skeleton
						style={{
							position: 'absolute',
							width: '100%',
							height: '100%'
						}}
					/>
				</div>
			</fieldset>
		);
	} else {
		return (
			<>
				<fieldset
					className="form-group p-3 border rounded-3"
					style={{ height: '95%' }}
				>
					<legend className="float-none w-auto px-3 my-0 fs-6">
						Pixel Count Data{' '}
						<button style={{ backgroundColor: '#f8f9fa', border: 'none' }}>
							<i
								data-tooltip-id="historic-plot-data"
								//onClick={(e) => fetchHistoricData()}
								className="fa-solid fa-arrows-rotate ms-1 fa-sm"
								style={{ color: 'rgb(98, 152, 64)' }}
							></i>
						</button>
					</legend>
					{/* <Tooltip id="historic-plot-data" place="right" effect="solid">
									reload Historic Data
								</Tooltip> */}

					<div
						style={{ height: '100%', width: '100%' }}
						className="d-flex justify-content-center"
					>
						<Line
							data={data}
							options={options}
							//options={{ options, maintainAspectRatio: false }}
							ref={chartRef}
						/>
					</div>
				</fieldset>
			</>
		);
	}
};

export default PixelCountPlot;
