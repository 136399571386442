import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const SkeletonTable = ({ numRows, cols, height }) => {
	return (
		<div className="table-responsive" style={{ maxHeight: height }}>
			<table className="table">
				<thead className="table-light">
					<tr>
						{cols.map((col, idx) => (
							<th scope="col" key={idx}>
								{col}
							</th>
						))}
					</tr>
				</thead>
				<tbody className="table-group-divider">
					{Array.from({ length: numRows }, (_, rowIndex) => (
						<tr key={rowIndex}>
							{Array.from({ length: cols.length }, (_, rowIndex) => (
								<td>
									<Skeleton />
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
