import React, {useState, useEffect} from 'react';
import Skeleton from 'react-loading-skeleton';
import { Tooltip } from 'react-tooltip';
import Plot from 'react-plotly.js';
import hotspotsSig from '../../pixelPlot';

const HotspotsProfile = ({
	hotspotsProfile,
	fetchHotspotsData,
	hotspotsLoading,
	hotspotsError,
	currentCapture
}) => {
	// error
	// error
	// no hotspots
	// hotspots successful

	const [plotData, setPlotData] = useState(null)
	const [plotLayout, setPlotLayout] = useState(null)

	useEffect(() => {
		if (hotspotsProfile && hotspotsProfile.data && hotspotsProfile.data.data && hotspotsProfile.data.layout) {
			// Only set plotData if hotspotsProfile is defined and has the necessary structure
			setPlotData(hotspotsProfile.data.data);
			setPlotLayout(hotspotsProfile.data.layout)
		}

	}, [hotspotsProfile, hotspotsLoading, currentCapture]);


	return (
		<div style={{ height: '100%' }}>
			{hotspotsLoading ? (
				<fieldset
					className="form-group p-3 border rounded-3"
					style={{ height: '100%', border: 'solid orange 1px' }}
				>
					<legend className="float-none w-auto px-3 my-0 fs-6">
						Hotspots Data{' '}
						<button
							
							style={{
								backgroundColor: '#f8f9fa',
								border: 'none',
								color: '#ffc107s'
							}}
						>
							<i
								data-tooltip-id="hotspots-plot-data"
								//onClick={(e) => fetchHotspotsData()}
								className="fa-solid fa-arrows-rotate ms-1 fa-sm"
								style={{ color: '#ffc107s' }}
							></i>
						</button>
					</legend>

					<div
						style={{
							position: 'relative',
							width: '100%',
							height: 'inherit'
						}}
					>
						<Skeleton
							style={{
								position: 'absolute',
								width: '100%',
								height: '100%'
							}}
						/>
					</div>

					<Tooltip id="hotspots-plot-data" place="right" effect="solid">
						Hotspots profile not fetched successfully; click to re-fetch
					</Tooltip>
				</fieldset>
			) : currentCapture && currentCapture.hotspots === 0 ? (
				<fieldset
					className="form-group p-3 border rounded-3"
					style={{ height: '100%', border: 'solid pink 1px' }}
				>
					<legend className="float-none w-auto px-3 my-0 fs-6">
						Hotspots Data{' '}
						<button style={{ backgroundColor: '#f8f9fa', border: 'none' }}>
							<i
								data-tooltip-id="hotspots-plot-data"
								//onClick={(e) => fetchHotspotsData()}
								className="fa-solid fa-arrows-rotate ms-1 fa-sm"
								style={{ color: 'grey' }}
							></i>
						</button>
					</legend>

					<Plot
						data={[]} // Use an empty array if data is falsy
						useResizeHandler
						layout={hotspotsSig.layout}
						style={{ width: '100%', height: '100%' }}
					/>

					<Tooltip id="hotspots-plot-data" place="right" effect="solid">
						No hotspots for this capture
					</Tooltip>
				</fieldset>
			) : hotspotsError ? (
				<fieldset
					className="form-group p-3 border rounded-3"
					style={{ height: '100%', border: 'solid orange 1px' }}
				>
					<legend className="float-none w-auto px-3 my-0 fs-6 text-warning">
						Hotspots Data{' '}
						<button
							id="sentinel-hub-icon"
							style={{
								backgroundColor: '#f8f9fa',
								border: 'none',
								color: '#ffc107s'
							}}
						>
							<i
								data-tooltip-id="hotspots-plot-data"
								onClick={(e) => fetchHotspotsData()}
								className="fa-solid fa-arrows-rotate ms-1 fa-sm text-warning"
								style={{ color: '#ffc107s' }}
							></i>
						</button>
					</legend>

					<div
						style={{
							position: 'relative',
							width: '100%',
							height: 'inherit'
						}}
					>
						<Skeleton
							style={{
								position: 'absolute',
								width: '100%',
								height: '100%'
							}}
						/>
					</div>

					<Tooltip id="hotspots-plot-data" place="right" effect="solid">
						Hotspots profile not fetched successfully; click to re-fetch
					</Tooltip>
				</fieldset>
			) : plotData && plotLayout ? (
				<fieldset
					className="form-group p-3 border rounded-3"
					style={{ height: '100%' }}
				>
					<legend className="float-none w-auto px-3 my-0 fs-6">
						Hotspots Data{' '}
						<button style={{ backgroundColor: '#f8f9fa', border: 'none' }}>
							<i
							    id="sentinel-hub-icon"
								data-tooltip-id="hotspots-plot-data"
								onClick={(e) => fetchHotspotsData()}
								className="fa-solid fa-arrows-rotate ms-1 fa-sm"
								style={{ color: 'rgb(98, 152, 64)' }}
							></i>
						</button>
					</legend>

					<Tooltip id="hotspots-plot-data" place="right" effect="solid">
						Re-fetch hotspots data
					</Tooltip>

					{/* AQUI */}
					<Plot
						data={plotData}
						//layout={plotData.layout}
						useResizeHandler
						layout={plotLayout}
						style={{ width: '100%', height: '100%' }}
					/>
				</fieldset>
			) : <fieldset
			className="form-group p-3 border rounded-3"
			style={{ height: '100%', border: 'solid pink 1px' }}
		>
			<legend className="float-none w-auto px-3 my-0 fs-6">
				Hotspots Data{' '}
				<button style={{ backgroundColor: '#f8f9fa', border: 'none' }}>
					<i
						data-tooltip-id="hotspots-plot-data"
						onClick={(e) => fetchHotspotsData()}
						className="fa-solid fa-arrows-rotate ms-1 fa-sm"
						style={{ color: 'grey' }}
					></i>
				</button>
			</legend>

			<Plot
				data={[]} // Use an empty array if data is falsy
				useResizeHandler
				layout={hotspotsSig.layout}
				style={{ width: '100%', height: '100%' }}
			/>

			<Tooltip id="hotspots-plot-data" place="right" effect="solid">
				Hotspots profile not fetched successfully; click to re-fetch
			</Tooltip>
		</fieldset>}
		</div>
	);
};

export default HotspotsProfile;
