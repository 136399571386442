import React from 'react';
import { Tooltip } from 'react-tooltip';
import { Row, Col } from 'reactstrap';

export const QCingFormAircraft = (
	onAircraftQCSubmit,
	currentCapture,
	imageData,
	skipCapture,
	drawnItemsValid,
	aircraftFormValue,
	setAircraftFormValue,
	aircraftCase
) => {
	return (
		<div
			style={{
				width: '100%',
				
			}}
			className="d-flex flex-column "
		>
			<form
				//onSubmit={(e) => onStockpilesQCSubmit(e)}
				className="mt-auto"
				id="aircraft-form"
			>
				
					<div
						style={{
							position: 'relative'
							//border: 'solid red 1px'
						}}
					>
						{/* BUTTONS */}
						{currentCapture && (
							<div
								//className="position-absolute bottom-0 end-0"
								style={{ width: '100%' }}
							>
								<Row>
									{aircraftCase === 1 && (
										<div>
											<p>
												Decide whether you{' '}
												<strong>
													<span className="text-success">accept</span>
												</strong>{' '}
												or{' '}
												<strong>
													<span className="text-danger">reject</span>
												</strong>{' '}
												this image. <br></br> If you find at least 90% of the
												aircraft locations to be accurate, you can{' '}
												<strong>
													<span className="text-success">accept</span>
												</strong>{' '}
												this capture.
											</p>
										</div>
									)}
									{aircraftCase === 2 && (
										<div>
											<p>
												Adjust the geometry of the aircraft or re-label the
												aircraft type as required using the map funcionality,
												then{' '}
												<strong>
													<span className="text-success">accept</span>
												</strong>{' '}
												the image and move to the next capture.
											</p>
										</div>
									)}
									{aircraftCase === 1 && (
										<div className="row mb-3">
											<div className="col d-flex justify-content-center">
												<input
													type="radio"
													className="form-check-input me-2"
													name="QC-image-aircraft-form-radios "
													//onClick={(e) => setStockpilesFormValue(0)}
													id="QC-image-aircraft-form-radios-reject"
													value={0}
													// disabled={
													// 	pipelineContextQCFormStockpiles.capsInPipeline.filter(
													// 		(cap) => cap.hasOwnProperty('beenQCd')
													// 	).length ===
													// 	pipelineContextQCFormStockpiles.capsInPipeline
													// 		.length
													// }
												></input>
												<label
													className="text-danger fw-bold"
													for="QC-image-aircraft-form-radios-reject"
												>
													Reject
												</label>
											</div>
											<div className="col d-flex justify-content-center">
												<input
													type="radio"
													className="form-check-input me-2"
													name="QC-image-stockpiles-form-radios "
													//onClick={(e) => setStockpilesFormValue(1)}
													id="QC-image-aircraft-form-radios-accept"
													value={1}
													defaultChecked={true}
													// disabled={
													// 	pipelineContextQCFormStockpiles.capsInPipeline.filter(
													// 		(cap) => cap.hasOwnProperty('beenQCd')
													// 	).length ===
													// 	pipelineContextQCFormStockpiles.capsInPipeline
													// 		.length
													// }
												></input>
												<label
													className="text-success fw-bold"
													for="QC-image-aircraft-form-radios-accept"
												>
													Accept
												</label>
											</div>
										</div>
									)}
									<Col className="d-flex justify-content-end pt-1">
										<div style={{ width: '100%' }}>
											{/* if data did not arrive */}
											{!imageData ? (
												<div className="row">
													<div className="col-3 col-lg-12 d-flex order-lg-3 order-xl-1 justify-content-lg-center d-flex align-items-center">
														<div className="col-3 d-flex align-items-center">
															<p className="my-0 py-0">
																<small>
																	1/10
																	{/* (
																	{pipelineContextQCFormStockpiles.currentCaptureIndex +
																		1}
																	/
																	{
																		pipelineContextQCFormStockpiles
																			.capsInPipeline.length
																	}
																	) */}
																</small>{' '}
																<i
																	type="button"
																	data-bs-toggle="modal"
																	data-bs-target="#exampleModal"
																	className="interact ms-1 fa-solid fa-bars-progress fa-md"
																></i>
															</p>
														</div>
													</div>
													<div className="col-3 col-lg-12 d-flex order-lg-2 order-xl-2 justify-content-lg-center">
														<button
															className="btn interactButton mt-2 mx-0 my-0"
															style={{ width: '100%' }}
															//onClick={(e) => skipCapture(e)}
															data-tooltip-id="skipCapTT"
														>
															Skip
														</button>
														<Tooltip id="skipCapTT" place="top" effect="solid">
															Error collecting data from database; skip capture
														</Tooltip>
													</div>
													<div className="col-6 col-lg-12 d-flex order-lg-1 order-xl-3 justify-content-lg-center align-items-lg-center d-flex flex-column align-items-end ">
														<button
															className="btn interactButtonSecondary mt-2 mx-0 my-0"
															style={{ width: '160px' }}
															disabled
														>
															{' '}
															<i class="fa-solid fa-arrow-right fa-xs ps-2"></i>
														</button>
													</div>
												</div>
											) : (
												<>
													<div className="row">
														<div className="col-3 col-lg-12 d-flex order-lg-3 order-xl-1 justify-content-lg-center d-flex align-items-center">
															<p className="my-0 py-0">
																<small>
																	1/10
																	{/* (
																	{pipelineContextQCFormStockpiles.currentCaptureIndex +
																		1}
																	/
																	{
																		pipelineContextQCFormStockpiles
																			.capsInPipeline.length
																	}
																	) */}
																</small>{' '}
																<i
																	type="button"
																	data-bs-toggle="modal"
																	data-bs-target="#exampleModal"
																	className="interact ms-1 fa-solid fa-bars-progress fa-md"
																></i>
															</p>
														</div>

														<div className="col-3 col-lg-12 d-flex order-lg-2 order-xl-2 justify-content-lg-center  ">
															{/* {pipelineContextQCFormStockpiles.capsInPipeline.filter(
																(cap) => cap.hasOwnProperty('beenQCd')
															).length !==
															pipelineContextQCFormStockpiles.capsInPipeline
																.length ? (
																<button
																	className="btn interactButtonSecondary mt-2 mx-0 my-0"
																	style={{ width: '100%' }}
																	onClick={(e) => skipCapture(e)}
																>
																	Skip
																</button>
															) : ( */}
															<button
																className="btn interactButtonSecondary mt-2 mx-0 my-0"
																style={{ width: '100%' }}
																disabled
															>
																Skip
															</button>
															{/* )} */}
														</div>

														<div className="col-6 col-lg-12 d-flex order-lg-1 order-xl-3 justify-content-lg-center align-items-lg-center d-flex flex-column align-items-end ">
															{!drawnItemsValid ? (
																<>
																	<button
																		className="btn interactButton mt-2 mx-0 my-0"
																		style={{ width: '160px' }}
																		key="disabled-button"
																		data-tooltip-id="geomNotAvailTT"
																		//data-bs-toggle="modal"
																		//data-bs-target="#moveToUploadModal"
																		//onClick={(e) => handleButtonClick(e,true)}
																		disabled={true}
																	>
																		Accept{' '}
																		<i class="ms-2 fa-solid fa-circle-check"></i>
																	</button>
																	<Tooltip
																		id="geomNotAvailTT"
																		place="top"
																		effect="solid"
																	>
																		Geometry not valid
																	</Tooltip>
																</>
															) : (
																<>
																	{aircraftFormValue === 0 && (
																		<button
																			className="btn interactButton mt-2 mx-2 my-0"
																			key="accept-button"
																			style={{ width: '150px' }}
																			data-tooltip-id="QC-sp-form-reject"
																			//data-bs-toggle="modal"
																			///data-bs-target="#moveToUploadModal"
																		>
																			Next capture{' '}
																			<i class="ms-2 fa-solid fa-arrow-right"></i>
																		</button>
																	)}
																	{aircraftFormValue === 1 && (
																		<button
																			className="btn interactButton mt-2 mx-2 my-0"
																			key="accept-button"
																			style={{ width: '150px' }}
																			data-tooltip-id="QC-sp-form-accept"
																			//data-bs-toggle="modal"
																			///data-bs-target="#moveToUploadModal"
																		>
																			Next capture{' '}
																			<i class="ms-2 fa-solid fa-arrow-right"></i>
																		</button>
																	)}
																	<Tooltip
																		id="QC-sp-form-accept"
																		place="top"
																		effect="solid"
																	>
																		<strong>
																			<span className="text-success">
																				Accept
																			</span>
																		</strong>{' '}
																		geometry and move to next capture
																	</Tooltip>

																	<Tooltip
																		id="QC-sp-form-reject"
																		place="top"
																		effect="solid"
																	>
																		<strong>
																			<span className="text-danger">
																				Reject
																			</span>
																		</strong>{' '}
																		geometry and move to next capture
																	</Tooltip>
																</>
															)}
														</div>
													</div>
												</>
											)}
										</div>
									</Col>{' '}
								</Row>
							</div>
						)}
					</div>
				
			</form>
		</div>
	);
};
