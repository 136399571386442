import React, { useEffect, useState, useRef, useContext } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-rastercoords';
import 'leaflet-easybutton/src/easy-button.css';
import 'leaflet-easybutton';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';
import {
	getBoundingBox,
	getCenterOfFeatureCollection,
	validatePolygon
} from '../../../helpers/leafletHelpers';
import { Tooltip } from 'react-tooltip';
import { layerColours } from '../../../helpers/mapHelpers';
import { pipelineContext } from '../../../contexts/pipelineContext';

import * as turf from '@turf/turf';

// Override Leaflet deprecated _flat method with the recommended isFlat method
L.Polyline._flat = L.LineUtil.isFlat;

export const QCImageStockpiles = ({
	imageData,
	modalId,
	currentCapture,
	setDrawnItemsValid,
	stockpilesGeojsons,
	setStockpilesGeojsons,
	stockpilesCase,
	activeStockpile,
	setActiveStockpile,
	selectedMapView
}) => {
	const pipelineContextQCImage = useContext(pipelineContext);

	const mapRef = useRef(null);
	const imageLayerRef = useRef(null);
	const aoiLayerRef = useRef(null);
	const originalLayerRef = useRef(new L.FeatureGroup());

	const [showAOILayer, setShowAOILayer] = useState(true);
	const [showEditedLayer, setShowEditedLayer] = useState(true);
	const [showImageLayer, setShowImageLayer] = useState(true);

	const [imageLayerOpacity, setImageLayerOpacity] = useState(1);

	useEffect(() => {
		/**
		 * highlights stockpile on map when hovering over stockpile in list
		 * in StockpilesGeojsonsList
		 */

		if (activeStockpile) {
			try {
				mapRef.current.eachLayer(function (layer) {
					if (layer.customName === 'drawnItemsLayer') {
						layer.eachLayer(function (layer2) {
							if (layer2.hasOwnProperty('feature')) {
								if (layer2.feature.id === activeStockpile) {
									layer2.setStyle({ weight: 2, opacity: 0.8 });
								}
							}
						});
					}
				});
			} catch (e) {}
		} else {
			try {
				mapRef.current.eachLayer(function (layer) {
					if (layer.customName === 'drawnItemsLayer') {
						layer.eachLayer(function (layer2) {
							layer2.setStyle({ weight: 1, opacity: 0.5 });
						});
					}
				});
			} catch (e) {}
		}
	}, [activeStockpile]);

	useEffect(() => {
		/**
		 * zooms to stockpile when click on stockpile in
		 * StockpilesGeojsonsList
		 */

		if (selectedMapView && mapRef.current) {
			var centroid = turf.centerOfMass(selectedMapView);
			mapRef.current.setView(
				[centroid.geometry.coordinates[1], centroid.geometry.coordinates[0]],
				20
			);
		}
	}, [selectedMapView]);

	// STOCKPILES
	useEffect(() => {
		if (pipelineContextQCImage.comm === 'stockpiles') {
			if (stockpilesGeojsons) {
				try {
					const centerPoint = getCenterOfFeatureCollection(
						JSON.parse(imageData.data.aoi)
					);
					const bounds = getBoundingBox(JSON.parse(imageData.data.aoi));
					let currentlyEditingLayer = null;

					// create map ONCE
					if (!mapRef.current) {
						mapRef.current = L.map(`map-${modalId}`, {
							zoomControl: false
						}).setView(centerPoint, 13);
						L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
							lang: 'en',
							opacity: 0.2
						}).addTo(mapRef.current);
						mapRef.current.fitBounds(bounds, { padding: [0, 0] });
						mapRef.current.setMaxZoom(35);

						var recenterBtn = L.easyButton({
							states: [
								{
									stateName: 'Zoom to image',
									icon: 'fa-solid fa-location-crosshairs',
									title: 'Relocate map to image', // Tooltip text
									onClick: function () {
										mapRef.current.setView(centerPoint, 16);
									}
								}
							]
						}).addTo(mapRef.current);

						// if using more complex case, add draw control
						if (stockpilesCase === 2) {
							const drawControl = new L.Control.Draw({
								edit: {
									featureGroup: originalLayerRef.current,
									edit: false, // Disable editing via draw control
									remove: false // Disable removal via draw control
								},
								draw: {
									polygon: true,
									marker: false,
									polyline: false,
									circle: false,
									rectangle: false,
									circlemarker: false
								}
							});
							mapRef.current.addControl(drawControl);
						}
					}

					// image layer ONCE
					if (!imageLayerRef.current) {
						const imgUrl = imageData.data.visual;

						var imageBounds = [
							[imageData.data.bbox[1], imageData.data.bbox[0]],
							[imageData.data.bbox[3], imageData.data.bbox[2]]
						];
						imageLayerRef.current = L.imageOverlay(imgUrl, imageBounds).addTo(
							mapRef.current
						);

						imageLayerRef.current.on('error', (error) => {
							console.error('Error loading image:', error);
						});
						imageLayerRef.current.setOpacity(1);
					}

					// aoi layer ONCE
					if (!aoiLayerRef.current) {
						aoiLayerRef.current = L.geoJSON(
							JSON.parse(imageData.data.aoi)
						).addTo(mapRef.current);
						aoiLayerRef.current.setStyle(layerColours.aoi);
					}

					// stockpiles layers: Case 1 static, Case 2 editable
					const drawnItems = new L.layerGroup();

					drawnItems.customName = 'drawnItemsLayer';
					mapRef.current.addLayer(drawnItems);

					let layerIdCounter = 1;
					let geojsonData = JSON.parse(imageData.data.stockpiles).features;

					if (originalLayerRef.current.getLayers().length === 0) {
						if (stockpilesCase === 2) {
							const calcPolygonArea = (feature, layer) => {
								try {
									var turfPolygon = turf.polygon(feature.geometry.coordinates);
									var area = turf.area(turfPolygon);
									return area;
								} catch (e) {
									console.log(e);
								}
							};

							let currentlyEditingLayer = null;
							// Add each feature from the GeoJSON layer to the FeatureGroup and make it editable
							var geojsonLayer = L.geoJSON(geojsonData, {
								onEachFeature: function (feature, layer) {
									// Add the layer to the drawnItems FeatureGroup
									drawnItems.addLayer(layer);

									// Enable editing on double-click
									layer.on('dblclick', function () {
										currentlyEditingLayer = layer;
										layer.editing.enable();
										enableButton(deleteBtn);
										enableButton(simplifyBtn);
									});

									layer.on('mouseover', () => {
										layer.setStyle({ weight: 3, opacity: 0.8 });
										setActiveStockpile(layer.feature.id);
									});
									layer.on('mouseout', () => {
										layer.setStyle({ weight: 1, opacity: 0.5 });
										setActiveStockpile(null);
									});

									layer.on('dragend', handleLayerEdit);
									layer.on('edit', handleLayerEdit);
									layer.on('update', handleLayerEdit);

									let area = calcPolygonArea(feature, layer);

									// layer.bindTooltip(`Stockpiles ${layerIdCounter}`);
									layer.bindTooltip(
										`Area: ${area.toFixed(0)} m2, Num points: ${
											feature.geometry.coordinates[0].length
										}`
									);
									layerIdCounter++;

									// validate stockpile
									var geojson = layer.toGeoJSON();
									const isValid = validatePolygon(
										geojson,
										JSON.parse(imageData.data.aoi)
									);

									if (!isValid) {
										layer.setStyle({ color: 'orange', opacity: 1 });

										setStockpilesGeojsons((prevState) => {
											// Clone the last list to avoid mutation
											const lastList =
												prevState.length > 0
													? [...prevState[prevState.length - 1]]
													: [];

											// Assuming you know the id of the layerObj you want to edit
											const layerIdToEdit = layer.feature.id;

											// Find the index of the layerObj with the specific id
											const layerIndex = lastList.findIndex(
												(layer) => layer.id === layerIdToEdit
											);

											if (layerIndex !== -1) {
												// Clone the layerObj to avoid mutation
												const updatedLayerObj = {
													...lastList[layerIndex],
													// Modify the properties you need to update
													geometry: {
														...lastList[layerIndex].geometry
													},
													properties: {
														...lastList[layerIndex].properties,
														status: 'invalid'
													}
												};

												// Replace the old layerObj with the updated one
												lastList[layerIndex] = updatedLayerObj;
											}

											// Add the modified list to the state
											return [...prevState, lastList];
										});
									} else {
										layer.setStyle(layerColours.original);
									}
								}
							});
							originalLayerRef.current.addLayer(drawnItems);

							var simplifyBtn = L.easyButton({
								states: [
									{
										stateName: 'Simplify polygon',
										icon: 'fa-solid fa-vector-square',
										title: currentlyEditingLayer
											? 'Simplify polygon'
											: 'Select a stockpile to simplify', // Tooltip text
										onClick: simplifyPolygon
									}
								]
							}).addTo(mapRef.current);
							disableButton(simplifyBtn);

							mapRef.current.on(L.Draw.Event.CREATED, function (e) {
								var type = e.layerType,
									layer = e.layer;
								layer.setStyle(layerColours.created);

								console.log(layer);

								drawnItems.addLayer(layer);

								layer.on('dragend', handleLayerEdit);
								layer.on('edit', handleLayerEdit);
								layer.on('update', handleLayerEdit);

								var geojson = layer.toGeoJSON();
								console.log(geojson);

								let area = calcPolygonArea(geojson, layer);

								//layer.bindTooltip(`${area.toFixed(0)} m2`);
								layer.bindTooltip(
									`Area: ${area.toFixed(0)} m2, Num points: ${
										geojson.geometry.coordinates[0].length
									}`
								);

								const isValid = validatePolygon(
									geojson,
									JSON.parse(imageData.data.aoi)
								);

								console.log(isValid);

								if (!isValid) {
									layer.setStyle(layerColours.invalid);
									setDrawnItemsValid(false);
									mapRef.current.off('click', handleMapClick);
									mapRef.current.removeControl(deleteBtn);
								} else {
									setDrawnItemsValid(true);
									mapRef.current.on('click', handleMapClick);
									layer.setStyle(layerColours.created);
									mapRef.current.addControl(deleteBtn);

									layer.on('mouseover', () => {
										layer.setStyle({ weight: 3, opacity: 0.8 });
									});
									layer.on('mouseout', () => {
										layer.setStyle({ weight: 1, opacity: 0.5 });
									});

									layer.off('dblclick');

									layer.on('dblclick', function (event) {
										L.DomEvent.stopPropagation(event); // Prevent the map click event from firing
										//enableEditing(layer);

										layer.editing.enable();
										enableButton(deleteBtn);
										deleteBtn.addTo(mapRef.current);
										currentlyEditingLayer = layer;
									});

									setStockpilesGeojsons((prevState) => {
										// Clone the last list to avoid mutation
										const lastList =
											prevState.length > 0
												? [...prevState[prevState.length - 1]]
												: [];

										let createdLayerObj = {
											id: lastList.length.toString(),
											geometry: {
												type: 'Polygon',
												coordinates: [geojson.geometry.coordinates[0]]
											},
											properties: {
												status: 'created'
											},
											type: 'Feature'
										};

										layer.feature = createdLayerObj;

										// Add the new layer to the cloned list
										lastList.push(createdLayerObj);

										// Add the new list to the state
										return [...prevState, lastList];
									});
								}
							});

							function handleLayerEdit(event, type) {
								// create new polygon based on edited layer
								const latLngArray = event.sourceTarget.editing.latlngs[0][0];
								const coordinates = latLngArray.map((latLng) => [
									latLng.lng,
									latLng.lat
								]);
								coordinates.push(coordinates[0]);
								let geojson = turf.polygon([coordinates]);

								// create turf polygon & check validity
								const isValid = validatePolygon(
									geojson,
									JSON.parse(imageData.data.aoi)
								);

								if (isValid) {
									setDrawnItemsValid(true);
									mapRef.current.on('click', handleMapClick);
									mapRef.current.addControl(deleteBtn);

									if (type !== 'created') {
										event.sourceTarget.setStyle(layerColours.edited);
									}

									let layer = event.target;
									let area = calcPolygonArea(geojson, layer);
									layer.bindTooltip(
										`Area: ${area.toFixed(0)} m2, Num points: ${
											geojson.geometry.coordinates[0].length
										}`
									);

									setStockpilesGeojsons((prevState) => {
										// Clone the last list to avoid mutation
										const lastList =
											prevState.length > 0
												? [...prevState[prevState.length - 1]]
												: [];

										// Assuming you know the id of the layerObj you want to edit
										const layerIdToEdit = layer.feature.id;

										// Find the index of the layerObj with the specific id
										const layerIndex = lastList.findIndex(
											(layer) => layer.id === layerIdToEdit
										);

										if (layerIndex !== -1) {
											// Clone the layerObj to avoid mutation
											const updatedLayerObj = {
												...lastList[layerIndex],
												// Modify the properties you need to update
												geometry: {
													...lastList[layerIndex].geometry,
													coordinates: geojson.geometry.coordinates
												},
												properties: {
													...lastList[layerIndex].properties,
													status: type !== 'created' ? 'edited' : 'created'
												}
											};

											// Replace the old layerObj with the updated one
											lastList[layerIndex] = updatedLayerObj;
										}

										// Add the modified list to the state
										return [...prevState, lastList];
									});
								} else {
									event.sourceTarget.setStyle(layerColours.deleted);
									setDrawnItemsValid(false);
									mapRef.current.off('click', handleMapClick);
									mapRef.current.removeControl(deleteBtn);
								}
							}

							function simplifyPolygon() {
								if (currentlyEditingLayer) {
									var latLngArray = currentlyEditingLayer.editing.latlngs[0][0];
									const coordinates = latLngArray.map((latLng) => [
										latLng.lng,
										latLng.lat
									]);
									coordinates.push(coordinates[0]);
									let geojson = turf.polygon([coordinates]);
									var options = { tolerance: 0.00001, highQuality: true };
									var simplified = turf.simplify(geojson, options);

									var simplifiedCoordinates =
										simplified.geometry.coordinates[0].map((coord) => [
											coord[1],
											coord[0]
										]);
									var simplifiedPolygon = L.polygon(simplifiedCoordinates, {
										color: 'blue'
									});
									drawnItems.addLayer(simplifiedPolygon);
									simplifiedPolygon.feature = currentlyEditingLayer.feature;

									var simplifiedGeoJSON = {
										type: 'Feature',
										properties: currentlyEditingLayer.feature.properties,
										geometry: simplified.geometry
									};

									console.log(simplifiedGeoJSON);

									console.log(simplifiedPolygon);

									var simplifiedLayer = L.geoJSON(simplifiedGeoJSON, {
										style: function (feature) {
											return { color: 'blue' };
										}
									});

									console.log(simplifiedLayer);

									var geojsonData = {
										type: 'FeatureCollection',
										features: [simplifiedGeoJSON]
									};

									var simplifiedLayers = new L.FeatureGroup();
									originalLayerRef.current.removeLayer(currentlyEditingLayer);
									mapRef.current.removeLayer(currentlyEditingLayer);
									mapRef.current.addLayer(simplifiedLayers);
									// currentlyEditingLayer = simplifiedLayer

									// Enable editing on double-click
									simplifiedPolygon.on('dblclick', function () {
										currentlyEditingLayer = simplifiedPolygon;
										simplifiedPolygon.editing.enable();
										enableButton(deleteBtn);
										enableButton(simplifyBtn);
									});

									simplifiedPolygon.on('mouseover', () => {
										simplifiedPolygon.setStyle({ weight: 3, opacity: 0.8 });
										setActiveStockpile(simplifiedPolygon.feature.id);
									});
									simplifiedPolygon.on('mouseout', () => {
										simplifiedPolygon.setStyle({ weight: 1, opacity: 0.5 });
										setActiveStockpile(null);
									});

									simplifiedPolygon.on('dragend', handleLayerEdit);
									simplifiedPolygon.on('edit', handleLayerEdit);
									simplifiedPolygon.on('update', handleLayerEdit);

									let area = calcPolygonArea(simplified, simplifiedPolygon);

									console.log(area);
									console.log(geojson.geometry.coordinates[0].length);
									//simplifiedPolygon.bindTooltip(`${area.toFixed(0)} m2`);

									simplifiedPolygon.bindTooltip(
										`Area: ${area.toFixed(0)} m2, Num points: ${
											simplified.geometry.coordinates[0].length
										}`
									);
									simplifiedPolygon.feature = currentlyEditingLayer.feature;

									console.log(area);

									console.log(simplifiedPolygon);
									console.log(simplifiedLayer);
									console.log(drawnItems);

									setStockpilesGeojsons((prevState) => {
										// Clone the last list to avoid mutation
										const lastList =
											prevState.length > 0
												? [...prevState[prevState.length - 1]]
												: [];

										// Assuming you know the id of the layerObj you want to edit
										const layerIdToEdit = simplifiedPolygon.feature.id;

										// Find the index of the layerObj with the specific id
										const layerIndex = lastList.findIndex(
											(layer) => layer.id === layerIdToEdit
										);

										if (layerIndex !== -1) {
											// Clone the layerObj to avoid mutation
											const updatedLayerObj = {
												...lastList[layerIndex],
												// Modify the properties you need to update
												geometry: simplified.geometry,
												properties: {
													...lastList[layerIndex].properties,
													status: 'simplified'
												}
											};

											// Replace the old layerObj with the updated one
											lastList[layerIndex] = updatedLayerObj;
										}

										// Add the modified list to the state
										return [...prevState, lastList];
									});
								}
							}

							function disableButton(button) {
								button.state('disabled');
								button.button.setAttribute('disabled', true);
								button.button.classList.add('leaflet-disabled');
								button.button.style.backgroundColor = '#f2f2f2';
							}

							function enableButton(button) {
								button.state('enabled');
								button.button.removeAttribute('disabled');
								button.button.classList.remove('leaflet-disabled');
								button.button.style.backgroundColor = 'white';
							}

							function deleteLayer() {
								if (currentlyEditingLayer) {
									let layer = currentlyEditingLayer;
									layer.setStyle(layerColours.deleted);

									setStockpilesGeojsons((prevState) => {
										// Clone the last list to avoid mutation
										const lastList =
											prevState.length > 0
												? [...prevState[prevState.length - 1]]
												: [];

										// Assuming you know the id of the layerObj you want to edit
										const layerIdToDelete = layer.feature.id;

										// Find the index of the layerObj with the specific id
										const layerIndex = lastList.findIndex(
											(layer) => layer.id === layerIdToDelete
										);

										if (layerIndex !== -1) {
											// Clone the layerObj to avoid mutation
											const updatedLayerObj = {
												...lastList[layerIndex],
												// Modify the properties you need to update
												//geometry: simplified.geometry,
												properties: {
													//...lastList[layerIndex].properties,
													status: 'deleted'
												}
											};

											// Replace the old layerObj with the updated one
											lastList[layerIndex] = updatedLayerObj;
										}

										// Add the modified list to the state
										return [...prevState, lastList];
									});

									// FOR WHEN LAYER HAS BEEN EDITED, THEN DELETING
									try {
										originalLayerRef.current
											.getLayer(layer._leaflet_id)
											.setStyle(layerColours.deleted);
										originalLayerRef.current
											.getLayer(layer._leaflet_id)
											.bindTooltip(
												`Layer ${layer.feature.properties.id} (deleted)`
											);
									} catch (e) {
										console.log(e);
									}

									layer.off('dblclick');
									layer.off('click');
									layer.on('click', function (event) {
										L.DomEvent.stopPropagation(event);
										//restoreBtn.addTo(mapRef.current);
										currentlyEditingLayer = layer;
									});

									disableEditing();
									currentlyEditingLayer = null;
									disableButton(deleteBtn);
								}
							}

							var deleteBtn = L.easyButton({
								states: [
									{
										stateName: 'Delete layer',
										icon: 'fa-solid fa-trash-can',
										title: currentlyEditingLayer
											? 'Delete layer'
											: 'Select a stockpile to delete', // Tooltip text
										onClick: deleteLayer
									}
								]
							}).addTo(mapRef.current);
							disableButton(deleteBtn);

							const handleMapClick = (e) => {
								// if (currentlyEditingLayer) {
								// 	console.log(currentlyEditingLayer);
								// 	try {
								// 		let status = currentlyEditingLayer.feature.status.toLowerCase();
								// 		currentlyEditingLayer.setStyle(layerColours[status]);
								// 	} catch (e) {
								// 		console.log(e);
								// 	}
								// }

								disableEditing();

								currentlyEditingLayer = null;
								//disableButton(deleteBtn);
								//disableButton(simplifyBtn);
							};

							mapRef.current.on('click', handleMapClick);

							function disableEditing() {
								try {
									if (currentlyEditingLayer) {
										currentlyEditingLayer.editing.disable();
										currentlyEditingLayer = null;
									}
								} catch (e) {
									console.log(e);
								}
							}
						} else {
							// const geojsonLayer = L.geoJSON(JSON.parse(imageData.stockpiles));
							// geojsonLayer.setStyle(layerColours.original);
							// originalLayerRef.current = geojsonLayer;
							// geojsonLayer.addTo(mapRef.current);

							if (originalLayerRef.current) {
								const drawnItems = new L.layerGroup();
								mapRef.current.addLayer(drawnItems);
								let layerIdCounter = 1;
								let layers = [];
								for (let sp in JSON.parse(imageData.data.stockpiles).features) {
									const geojsonLayer = L.geoJSON(
										JSON.parse(imageData.data.stockpiles).features[sp]
									);

									geojsonLayer.on('mouseover', () => {
										geojsonLayer.setStyle({ weight: 3, opacity: 0.8 });
									});
									geojsonLayer.on('mouseout', () => {
										geojsonLayer.setStyle({ weight: 1, opacity: 0.5 });
									});

									drawnItems.addLayer(geojsonLayer);

									geojsonLayer.setStyle(layerColours.original);
									geojsonLayer.bindTooltip(`Stockpiles ${layerIdCounter}`);
									layerIdCounter++;
								}

								originalLayerRef.current = drawnItems;
							}
						}
					}

					// Toggle layer visibility
					if (showEditedLayer) {
						mapRef.current.addLayer(originalLayerRef.current);
					} else {
						mapRef.current.removeLayer(originalLayerRef.current);
					}

					if (showImageLayer) {
						mapRef.current.addLayer(imageLayerRef.current);
					} else {
						mapRef.current.removeLayer(imageLayerRef.current);
					}

					if (showAOILayer) {
						mapRef.current.addLayer(aoiLayerRef.current);
						aoiLayerRef.current.bringToBack();
					} else {
						mapRef.current.removeLayer(aoiLayerRef.current);
					}
				} catch (e) {
					console.log(e);
				}
			} else {
			}
		}
	}, [
		showEditedLayer,
		showImageLayer,
		showAOILayer,
		stockpilesGeojsons,
		imageData,
		currentCapture
	]);

	const toggleImageLayer = () => {
		setShowImageLayer(!showImageLayer);
	};
	const toggleEditedLayer = () => {
		setShowEditedLayer(!showEditedLayer);
	};

	const toggleAOI = () => {
		setShowAOILayer(!showAOILayer);
	};

	const handleImageOpacityChange = (event) => {
		setImageLayerOpacity(event.target.value);
		imageLayerRef.current.setOpacity(event.target.value);
	};

	return (
		<div className="QCImage-map-general-div-stockpiles">
			<div className="QCImage-map-absolute-div">
				<div
					className="QCImage-leaflet-map-stockpiles"
					id={`map-${modalId}`}
				></div>
			</div>

			<div
				style={{
					position: 'absolute',
					bottom: '5px',
					left: '10px',
					zIndex: '900'
				}}
			>
				<fieldset
					className="form-group px-3 py-1 border border-dark border-1  rounded-3 "
					style={{ height: '100%' }}
				>
					<legend className="float-none w-auto px-1 my-0 fs-6">
						<i className="fa-solid fa-sliders"></i>
					</legend>

					<div className="row">
						<div className="col mx-0">
							<i
								className="fa-solid fa-eye-slash"
								data-tooltip-id="QCImageMapToggleLayers"
							></i>
						</div>
						<div className="col mx-0 px-0">
							{showImageLayer ? (
								<button
									onClick={toggleImageLayer}
									className="leaflet-map-toggle-btns-on"
									style={{ backgroundColor: 'black' }}
								>
									<p className="m-0 p-0">
										{' '}
										<i class="fa-solid fa-image"></i>
									</p>
								</button>
							) : (
								<button
									onClick={toggleImageLayer}
									className="leaflet-map-toggle-btns-off"
									style={{ backgroundColor: 'black' }}
								>
									<p className="m-0 p-0">
										{' '}
										<i class="fa-solid fa-image"></i>
									</p>
								</button>
							)}
						</div>
						<div className="col mx-0 px-0">
							{showEditedLayer ? (
								<button
									onClick={toggleEditedLayer}
									className="leaflet-map-toggle-btns-on"
									style={{ backgroundColor: layerColours.edited.color }}
								>
									<p className="m-0 p-0">
										{' '}
										<i class="fa-solid fa-pen"></i>
									</p>
								</button>
							) : (
								<button
									onClick={toggleEditedLayer}
									className="leaflet-map-toggle-btns-off"
									style={{ backgroundColor: layerColours.edited.color }}
								>
									<p className="m-0 p-0">
										{' '}
										<i class="fa-solid fa-pen"></i>
									</p>
								</button>
							)}
						</div>
						<div className="col mx-0 px-0">
							{showAOILayer ? (
								<button
									onClick={toggleAOI}
									className="leaflet-map-toggle-btns-on"
									style={{ backgroundColor: layerColours.created.color }}
								>
									<p className="m-0 p-0">
										{' '}
										<i class="fa-solid fa-draw-polygon"></i>
									</p>
								</button>
							) : (
								<button
									onClick={toggleAOI}
									className="leaflet-map-toggle-btns-off"
									style={{ backgroundColor: layerColours.created.color }}
								>
									<p className="m-0 p-0">
										{' '}
										<i class="fa-solid fa-draw-polygon"></i>
									</p>
								</button>
							)}
						</div>
					</div>

					<div className="row">
						<div className="col mx-0">
							<i
								className="fa-solid fa-circle-half-stroke"
								data-tooltip-id="QCImageMapLayerOpacity"
							></i>
						</div>
						<div className="col mx-0">
							{' '}
							<input
								className="form-control p-0 text-center"
								style={{ width: '40px' }}
								type="number"
								value={imageLayerOpacity}
								onChange={handleImageOpacityChange}
								min={0.1}
								max={1}
								step={0.1}
							></input>
						</div>
					</div>

					{/* <div className="row">
							<input
								type="number"
								value={imageLayerOpacity}
								onChange={handleImageOpacityChange}
								min={0.1}
								max={1}
								step={0.1}
							></input>
						</div> */}
				</fieldset>
				<Tooltip id="QCImageMapToggleLayers" place="top" effect="solid">
					Toggle layer visibility
				</Tooltip>
				<Tooltip id="QCImageMapLayerOpacity" place="top" effect="solid">
					Change layer opacity
				</Tooltip>
			</div>
		</div>
	);
};
