import React, { useState } from 'react';
import Sidenav from './components/Sidenav';
import { Row, Col } from 'reactstrap';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';

export const ProtectedRoute = ({
	component: C,
	user,
	children,
	authProps,
}) => {
	let location = useLocation();
	let navigate = useNavigate();

	// if NOT logged in
	if (!authProps.isAuthenticated) {
		return <Navigate to="/login" replace />;

		// if logged in
	} else {
		return (
			<>
				{/* <Row className="mx-0"> */}
				<div className="d-flex flex-column flex-md-row">
					<Sidenav
						// {...user}
						authProps={authProps}
						location={location}
						navigate={navigate}
					/>
					<C authProps={authProps} location={location} navigate={navigate} />
					{/* </Row> */}
				</div>
			</>
		);
	}
};

export default ProtectedRoute;
