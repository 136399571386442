import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Amplify } from 'aws-amplify';
import config from './config';
import { PipelineProvider } from './contexts/pipelineContext';
require('leaflet');

Amplify.configure({
	Auth: {
		mandatorySignIn: false,
		region: config.cognito.REGION,
		userPoolId: config.cognito.USER_POOL_ID,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
		userPoolWebClientId: config.cognito.APP_CLIENT_ID,
		authenticationFlowType: config.cognito.AuthenticationFlowType
	},
	API: {
		endpoints: [
			{
				name: 'gains-admin-api',
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION
			}
		]
	},
	Storage: {
		AWSS3: {
			bucket: 'gains-qc-development',
			region: 'eu-west-2', // e.g., us-east-1
			identityPoolId: config.cognito.IDENTITY_POOL_ID // Reference from aws-exports.js
		}
	}
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<PipelineProvider>
		<App />
	</PipelineProvider>
);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
// 	<Provider store={configureStore()}>
// 		<App />
// 	</Provider>
// );

// serviceWorker.unregister();
