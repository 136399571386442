import { useState, useCallback } from 'react';
import toast from 'react-hot-toast';

export const conditionalToastPromise = (promise, msjString, method) => {
	if (msjString) {
		return toast.promise(
			promise,
			{
				loading:
					method === 'GET'
						? `Fetching ${msjString}...`
						: `Posting ${msjString}...`,
				success:
					method === 'GET'
						? `${
								msjString.charAt(0).toUpperCase() + msjString.slice(1)
						  } fetched successfully!`
						: `${
								msjString.charAt(0).toUpperCase() + msjString.slice(1)
						  } posted successfully!`,
				error: (err) =>
					method === 'GET'
						? `${err.message} failed to fetch ${msjString}`
						: `${err.message} failed to post ${msjString}`
			},
			{
				position: 'top-right',
				style: {
					minWidth: '250px',
					textAlign: 'left',
					background: '#333',
					color: '#fff'
				}
			}
		);
	}
	return promise;
};

export const conditionalToastPromise2 = (url, response) => {
	console.log('here');

	const copyToClipboard = () => {
		navigator.clipboard
			.writeText(response.status)
			.then(() => toast.success('Copied to clipboard!'))
			.catch(() => toast.error('Failed to copy!'));
	};

	return toast.custom(
		<div
			style={{
				minWidth: '250px',
				textAlign: 'left',
				background: '#333',
				color: '#fff',
				display: 'flex',
				justifyContent: 'space-evenly',
				borderRadius: '8px',
				margin: '8px 0',
				fontSize: '14px'
			}}
		>
			<i style={{ color: 'red' }} className="fa-solid fa-circle-xmark"></i>
			<p>{response.status}</p>
			<button
				style={{ background: '#333', color: 'white', border: 'none' }}
				onClick={copyToClipboard}
			>
				<i className="fa-solid fa-copy"></i>
			</button>
		</div>,
		{
			duration: 30000,
			position: 'top-center',

			position: 'top-right'
		}
	);

	// return toast.error(`Error Type: ${response.status} from url ${url}`, {
	// 	duration: 30000,
	// 	position: 'top-center',

	// 	position: 'top-right',
	// 	style: {
	// 		minWidth: '250px',
	// 		textAlign: 'left',
	// 		background: '#333',
	// 		color: '#fff'
	// 	}
	// });
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function useGainsQCApi() {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const makeRequest = useCallback(
		async (url, method, body = null, msjString, fakeDelay, timeout = 8000) => {
			setLoading(true);
			setError(null);

			const options = {
				method,
				headers: {
					accept: 'application/json'
				},
				body: body ? JSON.stringify(body) : null
			};

			// Create an AbortController to cancel the request if it exceeds the timeout
			const controller = new AbortController();
			const { signal } = controller;
			const updatedOptions = { ...options, signal };

			// Timeout promise
			const timeoutPromise = new Promise((_, reject) => {
				setTimeout(() => {
					// Abort the fetch request after timeout
					controller.abort();
					reject(new Error('Request timed out'));
				}, timeout);
			});

			// Fetch promise wrapped with error handling
			const fetchPromise = fetch(url, updatedOptions)
				.then((response) => {
					console.log(response);

					if (!response.ok) {
						setLoading(false);
						console.log(response);

						const error = new Error(
							`Error ${response.status}: ${response.statusText}`
						);
						error.status = response.status; // Attach the status code explicitly
						error.statusText = response.statusText; // Attach statusText if needed
						error.url = url;
						throw error; // Throw the error to be caught later
					}

					return response.json();
				})
				.then(async (data) => {
					await delay(fakeDelay); // Simulating delay
					setData(data);
					setLoading(false);
				});

			// Race between fetchPromise and timeoutPromise
			const racePromise = Promise.race([fetchPromise, timeoutPromise]);

			conditionalToastPromise(racePromise, msjString, method);

			try {
				await racePromise; // Wait for the result (fetch or timeout)
			} catch (err) {
				console.log(err);

				// Handle timeout or network errors
				if (err.name === 'AbortError') {
					setError({
						message: 'Request was aborted due to timeout',
						status: 'Timeout',
						url: url
					});
				} else if (
					err.name === 'TypeError' &&
					err.message.includes('Failed to fetch')
				) {
					setError({
						message:
							'Network error: Failed to fetch. Please check your internet connection or try again later.',
						status: 'Network error',
						url: url
					});
				} else {
					// Handle other types of errors, including the ones manually thrown
					setError({
						message: err.message || 'An unknown error occurred',
						status: err.status || 'Unknown error',
						url: url
					});
				}

				setLoading(false);
			}
		},
		[]
	);

	const resetData = useCallback(() => {
		setData(null);
	}, []);

	const overrideData = (newData) => {
		setData(newData);
	};

	return { data, loading, error, makeRequest, resetData, overrideData };
}

// function useGainsQCApi() {
// 	const [data, setData] = useState(null);
// 	const [loading, setLoading] = useState(false);
// 	const [error, setError] = useState(null);

// 	const makeRequest = useCallback(
// 		async (url, method, body = null, msjString, fakeDelay) => {
// 			setLoading(true);
// 			setError(null);

// 			const options = {
// 				method,
// 				headers: {
// 					accept: 'application/json'
// 				},
// 				body: body ? JSON.stringify(body) : null
// 			};

// 			const fetchPromise = fetch(url, options)
// 				.then((response) => {
// 					console.log(response);

// 					if (!response.ok) {
// 						setLoading(false);
// 						//conditionalToastPromise2(url, response);
// 						console.log(response);

// 						const error = new Error(
// 							`Error ${response.status}: ${response.statusText}`
// 						);
// 						error.status = response.status; // Attach the status code explicitly
// 						error.statusText = response.statusText; // Attach statusText if needed
// 						throw error; // Throw the error to be caught later
// 					}

// 					console.log(response);
// 					return response.json();
// 				})
// 				.then(async (data) => {
// 					await delay(fakeDelay);
// 					setData(data);
// 					setLoading(false);
// 				});

// 			conditionalToastPromise(fetchPromise, msjString, method);

// 			try {
// 				await fetchPromise;
// 			} catch (err) {
// 				console.log(err);
// 				if (
// 					err.name === 'TypeError' &&
// 					err.message.includes('Failed to fetch')
// 				) {
// 					setError({
// 						message:
// 							'Network error: Failed to fetch. Please check your internet connection or try again later.',
// 						status: 'Network error'
// 					});
// 				} else {
// 					// Handle other types of errors, including the ones manually thrown
// 					setError({
// 						message: err.message || 'An unknown error occurred',
// 						status: err.status || 'Unknown error'
// 					});
// 				}
// 				setLoading(false);
// 			}
// 		},
// 		[]
// 	);

// 	const resetData = useCallback(() => {
// 		setData(null);
// 	}, []);

// 	const overrideData = (newData) => {
// 		setData(newData);
// 	};

// 	return { data, loading, error, makeRequest, resetData, overrideData };
// }

export default useGainsQCApi;
