import React, { useContext, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import divideReturnQCList from '../../../helpers/divideReturnQCList';
import StockpilesTableBasic from '../../StockpilesTableBasic';
import SmeltersTableBasic from '../../SmeltersTableBasic';
import config from '../../../config';
import { pipelineContext } from '../../../contexts/pipelineContext';
import useGainsQCApi from '../../../services/useGainsQCApi';
import dummyData from '../../../helpers/postQCDummyData';
import { smeltersPostQCFields, smeltersPrePostQCFields } from '../tableFields';
import CapturesTable from '../../CapturesTable';

const QCUpload = ({ props, formValue }) => {
	const [manCapsUpload, setManCapsUpload] = useState([]);
	const [capsToSendBack, setCapsToSendBack] = useState([]);
	const [postQCPerformed, setPostQCPerformed] = useState(false);
	const [postQCData, setPostQCData] = useState();
	const [overwriteOnPostQC, setOverwriteOnPostQC] = useState(false);
	const [uploaded, setUploaded] = useState(false);
	const [uploadedToDev, setUploadedToDev] = useState(false);
	const [uploadedToProd, setUploadedToProd] = useState(false);
	const [loading, setLoading] = useState(false);
	const [uploadToDatabase, setUploadToDatabase] = useState('dev');
	const [tablesData, setTablesData] = useState(null);
	const [toggleFill, setToggleFill] = useState(true);
	const [uniqueSites, setUniqueSites] = useState(0);
	const [socketUrl, setSocketUrl] = useState(null);
	const [socket, setSocket] = useState(null);
	const [receivedWSMsjs, setReceivedWSMsjs] = useState(null);

	const { error: errorSendingCapsBack, makeRequest: sendCapsBack } =
		useGainsQCApi();
	const runPostQC = useGainsQCApi();
	const uploadToDev = useGainsQCApi();
	const uploadToProd = useGainsQCApi();
	const runIndexCalc = useGainsQCApi();

	const pipelineContextQCUpload = useContext(pipelineContext);

	let navigate = useNavigate();

	console.log(toggleFill);

	useEffect(() => {
		const uniqueSiteIds = [...new Set(dummyData.map((item) => item.site_id))];
		setUniqueSites(uniqueSiteIds.length);

		const capturesGroupedById = [];

		for (let id in uniqueSiteIds) {
			let captureForThisSiteId = dummyData.filter(
				(obj) => obj.site_id === uniqueSiteIds[id]
			);

			if (toggleFill) {
				captureForThisSiteId = captureForThisSiteId.filter(
					(cap) => cap.fill !== 1
				);
			}

			capturesGroupedById.push(captureForThisSiteId);
		}

		setTablesData(capturesGroupedById);
	}, [dummyData, toggleFill]);

	useEffect(() => {
		/**
		 * determine the caps to upload
		 * and caps to remove
		 */

		setManCapsUpload(
			pipelineContextQCUpload.comm === 'stockpiles'
				? pipelineContextQCUpload.capsInPipeline.filter(
						(cap) => cap['qc_state'] === 4
				  )
				: pipelineContextQCUpload.capsInPipeline.filter(
						(cap) => cap.state !== null
				  )
		);
		setCapsToSendBack(
			pipelineContextQCUpload.comm === 'stockpiles'
				? pipelineContextQCUpload.capsInPipeline.filter(
						(cap) => cap['qc_state'] !== 4
				  )
				: pipelineContextQCUpload.capsInPipeline.filter(
						(cap) => cap.state === null
				  )
		);

		// needs to depend on progressBar to retrigger this calc when moving to upload
	}, [
		pipelineContextQCUpload.capsInPipeline,
		pipelineContextQCUpload.progressBar
	]);

	let comms;
	if (pipelineContextQCUpload.comm === 'stockpiles') {
		comms = 7;
	} else {
		comms = '';
	}

	const runPostQCAndUploadToDev = async () => {
		/**
		 * perform index calculation
		 */

		setLoading(true);

		let siteIds1 = manCapsUpload.map((x) => x.site_id);
		const siteIds = [...new Set(siteIds1)];

		// let user = `${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`;
		// let apiString = `post_qc?commodities=${comms}&siteIds=${siteIds}&overwrite=${overwriteOnPostQC}&username=${user}&lookback=-1`;

		// await runPostQC.makeRequest(
		// 	`${config.baseUrl}/${pipelineContextQCUpload.comm}/${apiString}`,
		// 	'POST',
		// 	null,
		// 	'post qc'
		// );

		var indexCalcBody;
		if (pipelineContextQCUpload.qcType === 'daily') {
			indexCalcBody = {
				prod_states: formValue.prod_states,
				commodities: formValue.commodities
			};
		} else {
			indexCalcBody = { site_ids: siteIds };
		}

		//console.log(indexCalcBody);

		// await runIndexCalc.makeRequest(
		// 	`${config.baseUrl}/${pipelineContextQCUpload.comm}/init-post-qc`,
		// 	'POST',
		// 	{},
		// 	'post qc'
		// );
	};

	useEffect(() => {
		/**
		 * upon successful index calc, connect to websocket
		 */

		if (runIndexCalc.data && !runIndexCalc.error) {
			setSocketUrl(runIndexCalc.data.url);
		} else {
			// TO DO: handle index calc error
			console.log(`Index Calc Error: ${runIndexCalc.error}`);
		}
	}, [runIndexCalc.data, runIndexCalc.error]);

	useEffect(() => {
		/**
		 * upon successful index calc, open websock
		 */

		if (!socketUrl) return; // Don't open WebSocket if socketUrl is not provided

		// Step 1: Create a new WebSocket connection when socketUrl is available
		const ws = new WebSocket(socketUrl);
		setSocket(ws);

		// Step 2: Define event listeners for the WebSocket
		ws.onopen = () => {
			console.log('WebSocket connection opened');
		};

		ws.onmessage = (event) => {
			console.log('Received message:', event.data);
			setReceivedWSMsjs((prevMessages) => [...prevMessages, event.data]);
		};

		ws.onerror = (error) => {
			console.error('WebSocket error:', error);
		};

		ws.onclose = () => {
			console.log('WebSocket connection closed');
		};

		// Step 3: Clean up the WebSocket connection when component unmounts or socketUrl changes
		return () => {
			console.log('Cleaning up WebSocket connection');
			ws.close();
		};
	}, [socketUrl]);

	useEffect(() => {
		/**
		 * upon successful post QC
		 * automatically upload data to dev
		 */

		console.log(runPostQC);
		if (runPostQC.data && !runPostQC.error) {
			setPostQCData(runPostQC.data.data);
			setPostQCPerformed(true);

			let apiString = `upload?data=${runPostQC.data.data}&commodity=${comms}&database=dev`;

			uploadToDev.makeRequest(
				`${config.baseUrl}/${pipelineContextQCUpload.comm}/${apiString}`,
				'POST',
				null,
				'upload to dev'
			);
		} else {
			console.log(runPostQC.error);
		}
	}, [runPostQC.data, runPostQC.error]);

	useEffect(() => {
		/**
		 * upon successful upload to dev
		 * re-enable Upload to Production button
		 */

		console.log(uploadToDev);

		if (!uploadToDev.error && uploadToDev.data) {
			if (uploadToDev.data.message === 'Success') {
				setUploadedToDev(true);
				setLoading(false);
			}
		}
	}, [uploadToDev.data, uploadToDev.error]);

	const uploadToProdDB = () => {
		setLoading(true);

		let comms;
		if (pipelineContextQCUpload.comm === 'stockpiles') {
			comms = 7;
		} else {
			comms = '';
		}

		let apiString = `upload?data=${postQCData}&commodity=${comms}&database=prod`;
		console.log(apiString);

		uploadToProd.makeRequest(
			`${config.baseUrl}/${pipelineContextQCUpload.comm}/${apiString}`,
			'POST',
			null,
			'upload to prod'
		);
	};

	useEffect(() => {
		/**
		 * upon successful upload to prod
		 * QC in progress now finished
		 */

		console.log(uploadToProd);

		if (!uploadToProd.error && uploadToProd.data) {
			if (uploadToProd.data.message === 'Success') {
				setLoading(false);
				setUploaded(true);
				setUploadedToProd(true);
				pipelineContextQCUpload.setQCInProgress(false);
			}
		}
	}, [uploadToProd.data, uploadToProd.error]);

	const renderDevUploadTT = () => {
		if (!loading && !postQCPerformed && overwriteOnPostQC) {
			return (
				<Tooltip id="performPostQCTT" place="bottom" effect="solid">
					Perform Post-QC, upload to development and <strong>overwrite</strong>{' '}
					database
				</Tooltip>
			);
		}

		if (!loading && !postQCPerformed && !overwriteOnPostQC) {
			return (
				<Tooltip id="performPostQCTT" place="bottom" effect="solid">
					Perform Post-QC, upload to development but{' '}
					<strong>do not overwrite</strong> database
				</Tooltip>
			);
		}

		if (loading) {
			return (
				<Tooltip id="performPostQCTT" place="bottom" effect="solid">
					Performing Post-QC and uploading to development...
				</Tooltip>
			);
		}

		if (!loading && postQCPerformed) {
			return (
				<Tooltip id="performPostQCTT" place="bottom" effect="solid">
					Post-QC already performed and captures uploaded to development
				</Tooltip>
			);
		}

		return null;
	};

	const renderProdUploadTT = () => {
		if (!postQCPerformed) {
			return (
				<Tooltip id="uploadCapturesTT" place="bottom" effect="solid">
					You cannot upload captures before Post-QC has been performed and they
					have been uploaded to development
				</Tooltip>
			);
		}

		if (!loading && postQCPerformed && !uploaded) {
			return (
				<Tooltip id="uploadCapturesTT" place="bottom" effect="solid">
					Upload captures to the <strong>production</strong> database
				</Tooltip>
			);
		}

		if (postQCPerformed && uploaded) {
			return (
				<Tooltip id="uploadCapturesTT" place="bottom" effect="solid">
					Captures already uploaded to production
				</Tooltip>
			);
		}
	};

	const renderPostQCButton = () => {
		if (!loading && !postQCPerformed) {
			return (
				<button
					style={{ width: '200px' }}
					className="btn interactButton"
					data-tooltip-id="performPostQCTT"
					onClick={(e) => runPostQCAndUploadToDev()}
				>
					Perform Post QC
				</button>
			);
		}

		if (loading && postQCPerformed) {
			return (
				<button
					style={{ width: '200px' }}
					className="btn interactButtonDisabled"
					data-tooltip-id="performPostQCTT"
				>
					<span>Performing Post QC</span>
					<span className="AnimatedEllipsis ps-3"></span>
				</button>
			);
		}

		if (!loading && postQCPerformed) {
			return (
				<button
					style={{ width: '200px' }}
					className="btn interactButtonDisabled"
					data-tooltip-id="performPostQCTT"
				>
					Performed Post QC <i className="ps-1 fa-solid fa-circle-check"></i>
				</button>
			);
		}

		return (
			<button
				style={{ width: '200px' }}
				className="btn interactButtonDisabled"
				data-tooltip-id="performPostQCTT"
				disabled
			>
				Post QC
			</button>
		);
	};

	const renderDevUploadButton = () => {
		if (!loading && !postQCPerformed) {
			return (
				<button
					style={{ width: '200px' }}
					className="btn interactButton"
					data-tooltip-id="performPostQCTT"
					onClick={(e) => runPostQCAndUploadToDev()}
				>
					Upload to development
				</button>
			);
		}

		if (loading && postQCPerformed) {
			return (
				<button
					style={{ width: '200px' }}
					className="btn interactButtonDisabled"
					data-tooltip-id="performPostQCTT"
				>
					<span>Uploading to dev</span>
					<span className="AnimatedEllipsis ps-3"></span>
				</button>
			);
		}

		if (!loading && postQCPerformed) {
			return (
				<button
					style={{ width: '200px' }}
					className="btn interactButtonDisabled"
					data-tooltip-id="performPostQCTT"
				>
					Uploaded to dev <i className="ps-1 fa-solid fa-circle-check"></i>
				</button>
			);
		}

		return null;
	};

	const renderProdUploadButton = () => {
		if (loading || !postQCPerformed) {
			return (
				<button
					style={{ width: '200px' }}
					className="btn interactButtonDisabled"
					data-tooltip-id="uploadCapturesTT"
				>
					Upload to production
				</button>
			);
		}

		if (loading && postQCPerformed && uploadedToDev && !uploadedToProd) {
			return (
				<button
					style={{ width: '200px' }}
					className="btn interactButtonDisabled"
					data-tooltip-id="performPostQCTT"
				>
					<span>Uploading to prod</span>
					<span className="AnimatedEllipsis ps-3"></span>
				</button>
			);
		}

		if (postQCPerformed && !uploadedToProd && uploadedToDev && !loading) {
			return (
				<button
					style={{ width: '200px' }}
					className="btn interactButton"
					data-tooltip-id="uploadCapturesTT"
					onClick={(e) => uploadToProdDB()}
				>
					Upload to production
				</button>
			);
		}

		if (uploadedToProd) {
			return (
				<button
					style={{ width: '200px' }}
					className="btn interactButtonDisabled"
					data-tooltip-id="uploadCapturesTT"
				>
					Uploaded to prod <i className="ps-1 fa-solid fa-circle-check"></i>
				</button>
			);
		}

		return null;
	};

	const renderFinishQCButton = () => {
		if (uploadedToDev && uploadedToProd) {
			return (
				<>
					<button
						className="btn interactButton"
						data-tooltip-id="QCfinishedTT"
						style={{ width: '200px' }}
						onClick={(e) => finishQC()}
					>
						Finish QC
						<i className="ps-2 fa-solid fa-circle-check"></i>
					</button>
					<Tooltip id="QCfinishedTT" place="bottom" effect="solid">
						You can now finish QC and review the report
					</Tooltip>
				</>
			);
		} else {
			return (
				<>
					<button
						className="btn interactButtonDisabled"
						data-tooltip-id="QCNotfinishedTT"
						style={{ width: '200px' }}
					>
						Finish QC
					</button>
					<Tooltip id="QCNotfinishedTT" place="bottom" effect="solid">
						Complete all steps of uploaded before completing QC
					</Tooltip>
				</>
			);
		}
	};

	const finishQC = () => {
		const urls = divideReturnQCList(capsToSendBack);

		for (let i in urls) {
			sendCapsBack(
				`${config.baseUrl}/${
					pipelineContextQCUpload.comm
				}/reset_captures?table=${
					config.tableNames[pipelineContextQCUpload.comm]
				}&captureIds=${urls[i]}`,
				'POST',
				null,
				'sending non-QCd caps back'
			);
		}

		// reset everything else here
		pipelineContextQCUpload.setCapsInPipeline([]);
		pipelineContextQCUpload.setProgressBar({ width: 100, title: 'QC Report' });
		pipelineContextQCUpload.setQCInProgress(false);
	};

	// console.log(manCapsUpload);
	// console.log(postQCData);
	console.log(loading);
	console.log(postQCPerformed);
	// console.log(uploadedToDev);
	// console.log(uploadedToProd);
	// console.log(pipelineContextQCUpload);
	// console.log(tablesData);

	return (
		<>
			<div style={{ padding: '10px' }}>
				<div className="row mb-2">
					<hr></hr>
					<h2>Upload Captures</h2>
					<h5>Use this page to upload QC'd captures to the database</h5>

					{runPostQC.error && (
						<h5 className="text-danger">
							There has been an error performing Post-QC. Upload cannot be
							performed on these captures. Please exit QC and try again later.
						</h5>
					)}
					{uploadToDev.error && (
						<h5 className="text-danger">
							There has been an error uploading to the <strong>dev</strong>{' '}
							database. Upload cannot be performed on these captures. Please
							exit QC and try again later.
						</h5>
					)}
					{uploadToProd.error && (
						<h5 className="text-danger">
							There has been an error uploading to the <strong>prod</strong>{' '}
							database. Upload cannot be performed on these captures. Please
							exit QC and try again later.
						</h5>
					)}
				</div>

				{/* <div className="row">
					<div className="col  d-flex justify-content-center">
						{!postQCPerformed && (
							<td
								className="px-4 py-2 "
								style={{ whiteSpace: 'nowrap' }}
								rowSpan={2}
							>
								<div className="d-flex flex-column bg-light">
									<p>
										Perform post-QC and upload to development<br></br>
										Overwrite existing data in database?
									</p>

									<div>
										<input
											className="form-check-input form-commodity"
											key="overwrite1"
											type="radio"
											id="overwritePostQCFalse"
											name="overwritePostQC"
											value={false}
											onChange={(e) => setOverwriteOnPostQC(false)}
											checked={overwriteOnPostQC === false}
										></input>
										<label
											for="overwritePostQCFalse"
											className="px-2 form-label"
										>
											Do not overwrite
										</label>
									</div>
									<div>
										<input
											className="form-check-input form-commodity"
											key="overwrite2"
											type="radio"
											id="overwritePostQCTrue"
											name="overwritePostQC"
											value={true}
											onChange={(e) => setOverwriteOnPostQC(true)}
											checked={overwriteOnPostQC === true}
										></input>
										<label
											for="overwritePostQCTrue"
											className="px-2 form-label"
										>
											Overwrite
										</label>
									</div>
								</div>
							</td>
						)}
					</div>
				</div> */}

				<div className="row my-2">
					<table style={{ width: '70%', marginLeft: '15%' }}>
						<tbody>
							<tr>
								<td className="d-flex justify-content-center align-items-center">
									{' '}
									<label className="label me-3" for="upload-change-lookback">
										Lookback
									</label>
									<input
										id="upload-change-lookback"
										className="form-control my-1 text-center"
										type="number"
										style={{ width: '80px' }}
										min={-1}
										max={100}
										value={5}
									></input>
								</td>
								<td></td>
								<td className="d-flex justify-content-center align-items-center">
									<div className="form-check form-switch ms-3 mt-2">
										<input
											className="form-check-input"
											type="checkbox"
											id="flexSwitchCheckDefault2"
											onChange={() =>
												setOverwriteOnPostQC((prevState) => !prevState)
											}
										></input>
										<label
											className="form-check-label"
											for="flexSwitchCheckDefault2"
										>
											Overwrite existing data
										</label>
									</div>
								</td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td className="text-center">{renderPostQCButton()}</td>
								<td className="text-center">
									<i class="fa-solid fa-circle-arrow-right"></i>
								</td>
								<td className="text-center">{renderDevUploadButton()}</td>
								<td className="text-center">
									<i class="fa-solid fa-circle-arrow-right"></i>
								</td>
								<td className="text-center">{renderProdUploadButton()}</td>
								<td className="text-center">
									<i class="fa-solid fa-circle-arrow-right"></i>
								</td>
								<td className="text-center">{renderFinishQCButton()}</td>
							</tr>

							<tr>
								<td className="text-center fw-bold">Step 1</td>
								<td className="text-center"></td>
								<td className="text-center fw-bold">Step 2</td>
								<td className="text-center"></td>
								<td className="text-center fw-bold">Step 3</td>
								<td className="text-center"></td>
								<td className="text-center fw-bold">Step 4</td>
							</tr>
						</tbody>
					</table>
				</div>

				{/* <div className="row">
					<div className="col"></div>
					<div className="col-12 d-flex justify-content-center ">
						<>
							<div className="table-responsive">
								<table className="table" style={{ tableLayout: 'auto' }}>
									<tr>
										<td
											className="fw-bold px-4 py-2"
											style={{ whiteSpace: 'nowrap' }}
										>
											Step 1
										</td>
										<td className="px-4 py-2">{renderDevUploadButton()}</td>
									</tr>

									<tr>
										<td
											className="fw-bold px-4 py-2"
											style={{ whiteSpace: 'nowrap' }}
										>
											Step 2
										</td>

										<td className="px-4 py-2">{renderProdUploadButton()}</td>
									</tr>

									<tr>
										<td
											className="fw-bold px-4 py-2"
											style={{ whiteSpace: 'nowrap' }}
										>
											Step 3
										</td>

										<td className="px-4 py-2">{renderFinishQCButton()}</td>
									</tr>
								</table>
							</div>

							{renderDevUploadTT()}
							{renderProdUploadTT()}
						</>
					</div>
				</div> */}

				{!pipelineContextQCUpload.straightToUpload ? (
					<>
						<div className="row mt-5 px-2">
							{/* <h5>Captures QC'd ({manCapsUpload.length})</h5> */}

							<div className="row">
								<div className="col d-flex align-items-center">
									<div className="form-check form-switch ms-3 mt-2">
										<input
											className="form-check-input"
											type="checkbox"
											id="flexSwitchCheckDefault1"
											onChange={() => setToggleFill((prevState) => !prevState)}
										></input>
										<label
											className="form-check-label"
											for="flexSwitchCheckDefault1"
										>
											View captures where{' '}
											<span className="fw-bold">fill = 1</span>
										</label>
									</div>
								</div>
								<div className="col d-flex justify-content-end  align-items-center">
									Total captures: {dummyData.length} / sites : {uniqueSites}
								</div>
							</div>

							{pipelineContextQCUpload.comm === 'stockpiles' && (
								<div className="table-responsive" style={{ maxHeight: '40vh' }}>
									<StockpilesTableBasic
										props={props}
										captures={manCapsUpload}
										capturesToReset={[]}
										postQCPerformed={postQCPerformed}
										uploadedToDev={uploadedToDev}
										uploadedToProd={uploadedToProd}
									/>
								</div>
							)}

							{/* {pipelineContextQCUpload === 'smelters' && ( */}

							{/* <SmeltersTableBasic
										props={props}
										captures={manCapsUpload}
										capturesToReset={[]}
									/> */}

							{!postQCPerformed && tablesData && (
								<div className="my-3">
									<CapturesTable
										fields={smeltersPrePostQCFields}
										captures={dummyData}
										qcCat={'smelters'}
										currentCaptureIndex={null}
										tableHeight={'700px'}
									/>
								</div>
							)}

							{tablesData &&
								postQCPerformed &&
								tablesData.map((siteid, idx) => (
									<div className="my-3">
										<h5>{siteid[0].site_id}: smelter name</h5>
										<CapturesTable
											fields={smeltersPostQCFields}
											captures={siteid}
											qcCat={'smelters'}
											currentCaptureIndex={null}
											tableHeight={'500px'}
										/>
									</div>
								))}
						</div>

						{/* {capsToSendBack.length > 0 && (
						<Row className="mt-5 px-2">
							<h5>Captures not QC'd ({capsToSendBack.length})</h5>
							<p>
								These captures will be removed from your pipeline when you
								complete the QC process
							</p>

							<div className="table-responsive" style={{ maxHeight: '40vh' }}>
								{pipelineContextQCUpload.comm === 'stockpiles' && (
									<div
										className="table-responsive"
										style={{ maxHeight: '40vh' }}
									>
										<StockpilesTableBasic
											props={props}
											captures={capsToSendBack}
											capturesToReset={[]}
										/>
									</div>
								)}

								{pipelineContextQCUpload === 'smelters' && (
									<div
										className="table-responsive"
										style={{ maxHeight: '40vh' }}
									>
										<SmeltersTableBasic
											props={props}
											captures={capsToSendBack}
											capturesToReset={[]}
										/>
									</div>
								)}
							</div>
						</Row>
					)} */}
					</>
				) : (
					<>
						<div className="row mt-5 px-2">
							<h5>Captures QC'd ({manCapsUpload.length})</h5>

							{!uploaded && !postQCPerformed && (
								<p>
									These captures have been QC'd and are available to be
									uploaded. <br></br>Perform <strong>Post-QC</strong> first,
									then choose the database and perform <strong>upload</strong>.
								</p>
							)}

							{!uploaded && postQCPerformed && (
								<p>
									These captures have been QC'd, Post-QC has been performed and
									are ready to be uploaded. <br></br>Choose the database and
									perform <strong>upload</strong>.
								</p>
							)}

							{uploaded && postQCPerformed && (
								<p>
									These captures have been successfully uploaded to the{' '}
									<strong>{uploadToDatabase}</strong> database. <br></br>You can
									now finish QC and view the report for these captures.
								</p>
							)}

							{pipelineContextQCUpload.comm === 'stockpiles' && (
								<div className="table-responsive" style={{ maxHeight: '40vh' }}>
									<StockpilesTableBasic
										props={props}
										//captures={manCapsUpload}
										//captures={}
										capturesToReset={[]}
										postQCPerformed={postQCPerformed}
										uploadedToDev={uploadedToDev}
										uploadedToProd={uploadedToProd}
										allowJumpCapture={false}
									/>
								</div>
							)}

							{pipelineContextQCUpload.comm === 'smelters' && (
								<div className="table-responsive" style={{ maxHeight: '40vh' }}>
									<SmeltersTableBasic
										props={props}
										captures={manCapsUpload}
										capturesToReset={[]}
									/>
								</div>
							)}
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default QCUpload;
