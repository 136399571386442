import React, { useContext, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { pipelineContext } from '../contexts/pipelineContext';

export default function Logout({ props }) {
	const pipelineContextLogout = useContext(pipelineContext);

	const logoutApp = async () => {
		pipelineContextLogout.resetState();
		props.authProps.setIsAuthenticating(false);

		try {
			await Auth.signOut(); // Ensure signOut works correctly
			props.authProps.userHasAuthenticated(false, []); // Reset authentication
		} catch (error) {
			console.error('Error during sign out:', error); // Handle any errors
		}
	};

	return (
		<button
			type="button"
			className="dropdown-item pointered bg-dark text-light"
			onClick={() => logoutApp()}
		>
			<i className="fas fa-fw fa-sign-out-alt mr-1" /> Log out
		</button>
	);
}
