import React from 'react';
import StockpilesTableBasic from '../../StockpilesTableBasic';
import { useContext } from 'react';
import { pipelineContext } from '../../../contexts/pipelineContext';
import SmeltersTableBasic from '../../SmeltersTableBasic';
import { SkeletonTable } from '../../SkeletonTable';
import CapturesTable from '../../CapturesTable';
import { smelterFields, stockpileFields } from '../tableFields';

const QCReport = ({ manualQCCaptures, qcInProgess, qcCat }) => {
	const pipelineContextQCReport = useContext(pipelineContext);

	let caps = [
		{
			id: 2238312,
			captured: '2024-02-25 02:21:55.000',
			site_id: 1114,
			sensor: 'landsat-8-l1tp',
			state: 1,
			initial_state: 1,
			hotspots: 2,
			cloud_frac: 0.3364692861095739,
			low_count: 1,
			medium_count: 0,
			high_count: 1,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 2.2470133304595947,
			use_smoke: 0,
			site_name: 'Jilin Xinda Iron and Steel',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC09_L1TP_118031_20240225_20240226_02_T1-1114',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-19 16:43:22.654471',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 2242461,
			captured: '2016-01-01 02:32:53.000',
			site_id: 1174,
			sensor: 'landsat-8-l1tp',
			state: 0,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 0,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.7881369590759277,
			use_smoke: 0,
			site_name: 'Fuzhou Wuhang Steel Products',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1TP_119042_20160101_20200907_02_T1-1174',
			initial_smoke: null,
			smoke: null,
			qc_user: 'lucy.colley',
			qc_date: '2024-07-09 11:42:47.639635',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 2243421,
			captured: '2024-01-31 02:33:04.000',
			site_id: 1174,
			sensor: 'landsat-8-l1tp',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 0.993485342019544,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.858655571937561,
			use_smoke: 0,
			site_name: 'Fuzhou Wuhang Steel Products',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC09_L1TP_119042_20240131_20240131_02_T1-1174',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-19 16:27:54.246144',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 2239669,
			captured: '2024-03-14 03:00:19.000',
			site_id: 1176,
			sensor: 'landsat-8-l1tp',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 0.8847402597402597,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 1.1011978387832642,
			use_smoke: 0,
			site_name: 'Hebei Xinwuan Iron and Steel Group Xinhui Metallurgy',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1TP_124035_20240314_20240401_02_T1-1176',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-22 13:48:17.683206',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 2241294,
			captured: '2024-02-28 02:56:26.000',
			site_id: 1180,
			sensor: 'landsat-8-l1tp',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.7361693978309631,
			use_smoke: 0,
			site_name: 'Hubei Shunle Steel',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC09_L1GT_123039_20240228_20240228_02_T2-1180',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-22 13:49:37.725665',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 78460,
			captured: '2016-01-01 11:31:17.000',
			site_id: 1404,
			sensor: 'sentinel-2-l1c',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 1.072821855545044,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2A_OPER_PRD_MSIL1C_PDMC_20160101T192528_R037_V20160101T113117_20160101T113117-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-06-26 17:10:13.248236',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 77948,
			captured: '2016-01-06 10:58:14.000',
			site_id: 1404,
			sensor: 'landsat-8-l1tp',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 0.8605809128630706,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.9126312136650085,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1GT_202023_20160106_20200907_02_T2-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-06-26 17:18:19.933520',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 78457,
			captured: '2016-01-11 11:33:19.000',
			site_id: 1404,
			sensor: 'sentinel-2-l1c',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.7700887322425842,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2A_OPER_PRD_MSIL1C_PDMC_20160120T131717_R037_V20160111T113319_20160111T113319-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-06-26 17:21:01.291676',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 77947,
			captured: '2016-01-13 11:04:26.000',
			site_id: 1404,
			sensor: 'landsat-8-l1tp',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.9882978796958923,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1TP_203023_20160113_20200907_02_T1-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-06-26 17:24:17.882322',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 78459,
			captured: '2016-01-18 11:20:24.000',
			site_id: 1404,
			sensor: 'sentinel-2-l1c',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.91178297996521,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2A_OPER_PRD_MSIL1C_PDMC_20160119T091855_R137_V20160118T112024_20160118T112024-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-06-27 16:19:15.819843',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 78462,
			captured: '2016-01-21 11:30:08.000',
			site_id: 1404,
			sensor: 'sentinel-2-l1c',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.8671141266822815,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2A_OPER_PRD_MSIL1C_PDMC_20160121T194518_R037_V20160121T113008_20160121T113008-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-09 11:10:26.221341',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 77951,
			captured: '2016-01-22 10:58:15.000',
			site_id: 1404,
			sensor: 'landsat-8-l1tp',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 1.1081657409667969,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1GT_202023_20160122_20200907_02_T2-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-09 11:10:51.016245',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 77955,
			captured: '2016-01-29 11:04:25.000',
			site_id: 1404,
			sensor: 'landsat-8-l1tp',
			state: 0,
			initial_state: 1,
			hotspots: 0,
			cloud_frac: 0,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 2.517857074737549,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1TP_203023_20160129_20200907_02_T1-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-09 11:11:35.888510',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 77949,
			captured: '2016-02-07 10:58:11.000',
			site_id: 1404,
			sensor: 'landsat-8-l1tp',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 0.6229598893499309,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 1.0381903648376465,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1TP_202023_20160207_20200907_02_T1-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-09 11:39:17.710480',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 78466,
			captured: '2016-02-10 11:22:14.000',
			site_id: 1404,
			sensor: 'sentinel-2-l1c',
			state: 1,
			initial_state: 1,
			hotspots: 2,
			cloud_frac: 0.004979253112033195,
			low_count: 1,
			medium_count: 0,
			high_count: 1,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 22.645832061767578,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2A_OPER_PRD_MSIL1C_PDMC_20160210T210959_R037_V20160210T112214_20160210T112214-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-09 11:39:22.715120',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 77954,
			captured: '2016-02-14 11:04:18.000',
			site_id: 1404,
			sensor: 'landsat-8-l1tp',
			state: 0,
			initial_state: 0,
			hotspots: 0,
			cloud_frac: 0.023236514522821577,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 1.1167247295379639,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1TP_203023_20160214_20200907_02_T1-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-09 11:39:29.834934',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 78464,
			captured: '2016-02-17 11:11:23.000',
			site_id: 1404,
			sensor: 'sentinel-2-l1c',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 1.1254204511642456,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2A_OPER_PRD_MSIL1C_PDMC_20160217T200112_R137_V20160217T111123_20160217T111123-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-11 12:15:02.822027',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 77956,
			captured: '2016-02-23 10:58:04.000',
			site_id: 1404,
			sensor: 'landsat-8-l1tp',
			state: 1,
			initial_state: 1,
			hotspots: 3,
			cloud_frac: 0.052835408022130013,
			low_count: 0,
			medium_count: 2,
			high_count: 1,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 1.9914860725402832,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1TP_202023_20160223_20200907_02_T1-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-11 12:17:20.027093',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 77950,
			captured: '2016-03-01 11:04:15.000',
			site_id: 1404,
			sensor: 'landsat-8-l1tp',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 0.9222683264177041,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.9031541347503662,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1GT_203023_20160301_20200907_02_T2-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-11 12:48:11.157041',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 77953,
			captured: '2016-03-10 10:58:02.000',
			site_id: 1404,
			sensor: 'landsat-8-l1tp',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.7511880397796631,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1GT_202023_20160310_20200907_02_T2-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-11 13:29:52.738580',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 77952,
			captured: '2016-03-17 11:04:10.000',
			site_id: 1404,
			sensor: 'landsat-8-l1tp',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 0.9582295988934993,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.9935702681541443,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1TP_203023_20160317_20200907_02_T1-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-11 13:33:25.040561',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 78461,
			captured: '2016-03-18 11:15:23.000',
			site_id: 1404,
			sensor: 'sentinel-2-l1c',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.7927702069282532,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2A_OPER_PRD_MSIL1C_PDMC_20160318T232755_R137_V20160318T111523_20160318T111523-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-11 13:36:46.013190',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 77958,
			captured: '2016-03-26 10:57:53.000',
			site_id: 1404,
			sensor: 'landsat-8-l1tp',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.8502048254013062,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1GT_202023_20160326_20200907_02_T2-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-11 13:41:08.329304',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 78463,
			captured: '2016-03-28 11:13:53.000',
			site_id: 1404,
			sensor: 'sentinel-2-l1c',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 1.0394936800003052,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2A_OPER_PRD_MSIL1C_PDMC_20160331T111211_R137_V20160328T111353_20160328T111353-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-11 13:46:15.187308',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 77959,
			captured: '2016-04-02 11:04:00.000',
			site_id: 1404,
			sensor: 'landsat-8-l1tp',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.8393348455429077,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1GT_203023_20160402_20200907_02_T2-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-11 14:17:17.024095',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 78465,
			captured: '2016-04-07 11:12:23.000',
			site_id: 1404,
			sensor: 'sentinel-2-l1c',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.9043649435043335,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2A_OPER_PRD_MSIL1C_PDMC_20160408T072835_R137_V20160407T111223_20160407T111223-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-11 15:40:40.618834',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 78458,
			captured: '2016-04-10 11:23:58.000',
			site_id: 1404,
			sensor: 'sentinel-2-l1c',
			state: 0,
			initial_state: 0,
			hotspots: 0,
			cloud_frac: 0.11756569847856155,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 1.1898146867752075,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2A_OPER_PRD_MSIL1C_PDMC_20160412T175247_R037_V20160410T112358_20160410T112358-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-12 08:44:19.465863',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 77957,
			captured: '2016-04-11 10:57:47.000',
			site_id: 1404,
			sensor: 'landsat-8-l1tp',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 0.9925311203319502,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.9762835502624512,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1TP_202023_20160411_20200907_02_T1-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-12 08:51:29.229771',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 78467,
			captured: '2016-04-17 11:11:59.000',
			site_id: 1404,
			sensor: 'sentinel-2-l1c',
			state: 0,
			initial_state: 0,
			hotspots: 0,
			cloud_frac: 0.014107883817427386,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 1.2034631967544556,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2A_OPER_PRD_MSIL1C_PDMC_20160417T193751_R137_V20160417T111159_20160417T111159-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-22 09:56:06.196833',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 77961,
			captured: '2016-04-18 11:03:55.000',
			site_id: 1404,
			sensor: 'landsat-8-l1tp',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 0.9941908713692946,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 1.0092897415161133,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1GT_203023_20160418_20200907_02_T2-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-22 10:34:30.138516',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 78475,
			captured: '2016-04-20 11:22:42.000',
			site_id: 1404,
			sensor: 'sentinel-2-l1c',
			state: 1,
			initial_state: 1,
			hotspots: 2,
			cloud_frac: 0,
			low_count: 0,
			medium_count: 0,
			high_count: 2,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 1.6465163230895996,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2A_OPER_PRD_MSIL1C_PDMC_20160420T195930_R037_V20160420T112242_20160420T112242-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'qurratul.khan',
			qc_date: '2024-07-22 11:55:44.585515',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 78767,
			captured: '2018-12-28 11:16:16.000',
			site_id: 1404,
			sensor: 'sentinel-2-l1c',
			state: 0,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 0,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.9700213670730591,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2B_MSIL1C_20181228T111449_N0207_R137_T30UXE_20181228T113359-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'lucy.colley',
			qc_date: '2024-07-02 12:38:29.295024',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 78087,
			captured: '2018-12-29 10:58:03.000',
			site_id: 1404,
			sensor: 'landsat-8-l1tp',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.9030866026878357,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1TP_202023_20181229_20200829_02_T1-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'lucy.colley',
			qc_date: '2024-07-02 12:38:39.421118',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 78770,
			captured: '2018-12-31 11:26:13.000',
			site_id: 1404,
			sensor: 'sentinel-2-l1c',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.9872316122055054,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2B_MSIL1C_20181231T112459_N0207_R037_T30UXE_20181231T114603-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'lucy.colley',
			qc_date: '2024-07-02 12:38:45.060343',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 79173,
			captured: '2021-09-11 11:26:16.000',
			site_id: 1404,
			sensor: 'sentinel-2-l1c',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.9273949265480042,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2A_MSIL1C_20210911T112111_N0301_R037_T30UXE_20210911T133243-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'lucy.colley',
			qc_date: '2024-07-04 14:52:49.160773',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 79175,
			captured: '2021-09-13 11:16:15.000',
			site_id: 1404,
			sensor: 'sentinel-2-l1c',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.7568342685699463,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2B_MSIL1C_20210913T110629_N0301_R137_T30UXE_20210913T120150-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'lucy.colley',
			qc_date: '2024-07-04 14:53:02.589393',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 79338,
			captured: '2022-10-11 11:26:14.000',
			site_id: 1404,
			sensor: 'sentinel-2-l1c',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.9479196071624756,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2B_MSIL1C_20221011T112109_N0400_R037_T30UXE_20221011T121136-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'lucy.colley',
			qc_date: '2024-07-09 11:18:53.996652',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 79525,
			captured: '2024-01-06 11:16:15.000',
			site_id: 1404,
			sensor: 'sentinel-2-l1c',
			state: 0,
			initial_state: 0,
			hotspots: 0,
			cloud_frac: 0.09488243430152143,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 1.0928571224212646,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2A_MSIL1C_20240106T111441_N0510_R137_T30UXE_20240106T131158-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'lucy.colley',
			qc_date: '2024-05-31 13:07:20.143680',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 79562,
			captured: '2024-04-03 11:26:14.000',
			site_id: 1404,
			sensor: 'sentinel-2-l1c',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.689577043056488,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2B_MSIL1C_20240403T112109_N0510_R037_T30UXE_20240403T131954-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'lucy.colley',
			qc_date: '2024-06-26 11:03:54.309258',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 79563,
			captured: '2024-04-08 11:26:15.000',
			site_id: 1404,
			sensor: 'sentinel-2-l1c',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 1.0597376823425293,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2A_MSIL1C_20240408T112111_N0510_R037_T30UXE_20240408T132243-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'lucy.colley',
			qc_date: '2024-06-26 11:04:15.023675',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 95559,
			captured: '2024-05-10 11:16:20.000',
			site_id: 1404,
			sensor: 'sentinel-2-l1c',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.836571455001831,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2B_MSIL1C_20240510T110619_N0510_R137_T30UXE_20240510T120154-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'lucy.colley',
			qc_date: '2024-06-26 10:00:19.886760',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 94411,
			captured: '2024-05-19 10:57:17.000',
			site_id: 1404,
			sensor: 'landsat-8-l1tp',
			state: 0,
			initial_state: 0,
			hotspots: 0,
			cloud_frac: 0.0677731673582296,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 1.0052287578582764,
			use_smoke: 0,
			site_name: 'GFG Liberty Steel Rotherham',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1TP_202023_20240519_20240519_02_RT-1404',
			initial_smoke: null,
			smoke: null,
			qc_user: 'lucy.colley',
			qc_date: '2024-06-14 09:17:59.341023',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 2258321,
			captured: '2024-07-06 04:32:30.000',
			site_id: 2209,
			sensor: 'sentinel-2-l1c',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.7459562420845032,
			use_smoke: 0,
			site_name: 'BSRM Steels Chattogram expansion',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2B_MSIL1C_20240706T041549_N0510_R090_T46QCK_20240706T061102-2209',
			initial_smoke: null,
			smoke: null,
			qc_user: 'lucy.colley',
			qc_date: '2024-07-15 15:15:35.039323',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 2258320,
			captured: '2024-07-09 04:42:25.000',
			site_id: 2209,
			sensor: 'sentinel-2-l1c',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 0.7315372824668884,
			use_smoke: 0,
			site_name: 'BSRM Steels Chattogram expansion',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2B_MSIL1C_20240709T042709_N0510_R133_T46QCK_20240709T062240-2209',
			initial_smoke: null,
			smoke: null,
			qc_user: 'lucy.colley',
			qc_date: '2024-07-15 15:38:16.206433',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 2258312,
			captured: '2024-07-06 03:57:21.000',
			site_id: 2408,
			sensor: 'landsat-8-l1tp',
			state: 0,
			initial_state: 0,
			hotspots: 0,
			cloud_frac: 0.2181372549019608,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 1.0091452598571777,
			use_smoke: 0,
			site_name: 'Ellwood National Steel Irvine',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1TP_017031_20240706_20240706_02_RT-2408',
			initial_smoke: null,
			smoke: null,
			qc_user: 'lucy.colley',
			qc_date: '2024-07-15 15:01:54.685099',
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 2258292,
			captured: '2024-07-06 04:11:47.000',
			site_id: 2408,
			sensor: 'sentinel-2-l1c',
			state: 0,
			initial_state: 0,
			hotspots: 0,
			cloud_frac: 0.0392156862745098,
			low_count: 0,
			medium_count: 0,
			high_count: 0,
			invalid_data_frac: 0,
			qc_state: 4,
			max_band_ratio: 1.076082468032837,
			use_smoke: 0,
			site_name: 'Ellwood National Steel Irvine',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2B_MSIL1C_20240706T155819_N0510_R097_T17TPG_20240706T193818-2408',
			initial_smoke: null,
			smoke: null,
			qc_user: 'lucy.colley',
			qc_date: '2024-07-15 15:02:26.293905',
			table_name: 'SteelCaptureProcessingData'
		}
	];

	console.log(pipelineContextQCReport.comm);

	return (
		<>
			<hr></hr>
			<div className=" ms-4">
				<h1>QC Finished</h1>
				<h5>See below information about the performed QC</h5>

				<div className="row d-flex justify-content-center mt-4">
					<div className="col-3 d-flex justify-content-center">
						<table className="table qc-report-table ">
							<tbody>
								<tr>
									<td>Type</td>
									<td className="fw-bold">stockpiles</td>
								</tr>

								<tr>
									<td>No. captures</td>
									<td>48</td>
								</tr>

								<tr>
									<td>No. captures QC'd</td>
									<td>42 (88%)</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className="col-3 d-flex justify-content-center">
						<table className="table qc-report-table">
							<tbody>
								<tr>
									<td>Date</td>
									<td>02/08/2024</td>
								</tr>

								<tr>
									<td>Time</td>
									<td>10:51</td>
								</tr>

								<tr>
									<td>User</td>
									<td>lucy.colley</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div className="row">
					<CapturesTable
						fields={pipelineContextQCReport.comm === 'smelters' ? smelterFields : stockpileFields}
						captures={caps}
						qcCat={pipelineContextQCReport.comm}
						tableHeight={'600px'}
					/>
				</div>
			</div>
		</>
	);
};

export default QCReport;
