let dummyData = [
	{
		FIELD1: 0,
		captured: '2024-09-14',
		id: 2469929,
		site_id: 1712,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 6750,
		hotspots: 5,
		low_count: 2,
		medium_count: 0,
		high_count: 3,
		raw_index: 566.697,
		cloud_frac: 0.555357,
		perm_regions: 26,
		dispersion_index: 3.60169,
		fill: 0
	},
	{
		FIELD1: 1,
		captured: '2024-09-15',
		id: 2469929,
		site_id: 1712,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 6750,
		hotspots: 5,
		low_count: 2,
		medium_count: 0,
		high_count: 3,
		raw_index: 566.697,
		cloud_frac: 0.555357,
		perm_regions: 26,
		dispersion_index: 3.60169,
		fill: 1
	},
	{
		FIELD1: 2,
		captured: '2024-09-16',
		id: 2474858,
		site_id: 1712,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 6750,
		hotspots: 5,
		low_count: 0,
		medium_count: 1,
		high_count: 4,
		raw_index: 566.697,
		cloud_frac: 1,
		perm_regions: 26,
		dispersion_index: 3.60169,
		fill: 0
	},
	{
		FIELD1: 3,
		captured: '2024-09-17',
		id: 2474858,
		site_id: 1712,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 6750,
		hotspots: 5,
		low_count: 0,
		medium_count: 1,
		high_count: 4,
		raw_index: 566.697,
		cloud_frac: 1,
		perm_regions: 26,
		dispersion_index: 3.60169,
		fill: 1
	},
	{
		FIELD1: 4,
		captured: '2024-09-18',
		id: 2478767,
		site_id: 1712,
		sensor: 'landsat-8-l1tp',
		state: 1,
		capacity: 6750,
		hotspots: 6,
		low_count: 1,
		medium_count: 0,
		high_count: 5,
		raw_index: 563.363,
		cloud_frac: 0.920029,
		perm_regions: 20,
		dispersion_index: 3.38266,
		fill: 0
	},
	{
		FIELD1: 5,
		captured: '2024-09-19',
		id: 2480826,
		site_id: 1712,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 6750,
		hotspots: 16,
		low_count: 4,
		medium_count: 2,
		high_count: 10,
		raw_index: 1200,
		cloud_frac: 0.000331279,
		perm_regions: 26,
		dispersion_index: 58.1114,
		fill: 0
	},
	{
		FIELD1: 6,
		captured: '2024-09-20',
		id: 2480826,
		site_id: 1712,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 6750,
		hotspots: 16,
		low_count: 4,
		medium_count: 2,
		high_count: 10,
		raw_index: 1200,
		cloud_frac: 0.000331279,
		perm_regions: 26,
		dispersion_index: 58.1114,
		fill: 1
	},
	{
		FIELD1: 7,
		captured: '2024-09-21',
		id: 2487858,
		site_id: 1712,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 6750,
		hotspots: 13,
		low_count: 0,
		medium_count: 2,
		high_count: 11,
		raw_index: 1200,
		cloud_frac: 0.00112635,
		perm_regions: 26,
		dispersion_index: 58.1114,
		fill: 0
	},
	{
		FIELD1: 8,
		captured: '2024-09-22',
		id: 2487858,
		site_id: 1712,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 6750,
		hotspots: 13,
		low_count: 0,
		medium_count: 2,
		high_count: 11,
		raw_index: 1200,
		cloud_frac: 0.00112635,
		perm_regions: 26,
		dispersion_index: 58.1114,
		fill: 1
	},
	{
		FIELD1: 9,
		captured: '2024-09-23',
		id: 2487858,
		site_id: 1712,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 6750,
		hotspots: 13,
		low_count: 0,
		medium_count: 2,
		high_count: 11,
		raw_index: 1200,
		cloud_frac: 0.00112635,
		perm_regions: 26,
		dispersion_index: 58.1114,
		fill: 1
	},

	{
		FIELD1: 0,
		captured: '2024-09-15',
		id: 2470975,
		site_id: 1001,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 13000,
		hotspots: 6,
		low_count: 5,
		medium_count: 0,
		high_count: 1,
		raw_index: 225,
		cloud_frac: 0.0383715,
		perm_regions: 22,
		dispersion_index: 65.6803,
		fill: 0
	},
	{
		FIELD1: 1,
		captured: '2024-09-16',
		id: 2470976,
		site_id: 1001,
		sensor: 'landsat-8-l1tp',
		state: 1,
		capacity: 13000,
		hotspots: 3,
		low_count: 1,
		medium_count: 1,
		high_count: 1,
		raw_index: 178.812,
		cloud_frac: 0.0762414,
		perm_regions: 19,
		dispersion_index: 52.7219,
		fill: 0
	},
	{
		FIELD1: 2,
		captured: '2024-09-17',
		id: 2476124,
		site_id: 1001,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 13000,
		hotspots: 5,
		low_count: 4,
		medium_count: 1,
		high_count: 0,
		raw_index: 155.022,
		cloud_frac: 0.17429,
		perm_regions: 22,
		dispersion_index: 43.8491,
		fill: 0
	},
	{
		FIELD1: 3,
		captured: '2024-09-18',
		id: 2476124,
		site_id: 1001,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 13000,
		hotspots: 5,
		low_count: 4,
		medium_count: 1,
		high_count: 0,
		raw_index: 155.022,
		cloud_frac: 0.17429,
		perm_regions: 22,
		dispersion_index: 43.8491,
		fill: 1
	},
	{
		FIELD1: 4,
		captured: '2024-09-19',
		id: 2476124,
		site_id: 1001,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 13000,
		hotspots: 5,
		low_count: 4,
		medium_count: 1,
		high_count: 0,
		raw_index: 155.022,
		cloud_frac: 0.17429,
		perm_regions: 22,
		dispersion_index: 43.8491,
		fill: 1
	},
	{
		FIELD1: 5,
		captured: '2024-09-20',
		id: 2476124,
		site_id: 1001,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 13000,
		hotspots: 5,
		low_count: 4,
		medium_count: 1,
		high_count: 0,
		raw_index: 155.022,
		cloud_frac: 0.17429,
		perm_regions: 22,
		dispersion_index: 43.8491,
		fill: 1
	},
	{
		FIELD1: 6,
		captured: '2024-09-21',
		id: 2476124,
		site_id: 1001,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 13000,
		hotspots: 5,
		low_count: 4,
		medium_count: 1,
		high_count: 0,
		raw_index: 155.022,
		cloud_frac: 0.17429,
		perm_regions: 22,
		dispersion_index: 43.8491,
		fill: 1
	},
	{
		FIELD1: 7,
		captured: '2024-09-22',
		id: 2476124,
		site_id: 1001,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 13000,
		hotspots: 5,
		low_count: 4,
		medium_count: 1,
		high_count: 0,
		raw_index: 155.022,
		cloud_frac: 0.17429,
		perm_regions: 22,
		dispersion_index: 43.8491,
		fill: 1
	},
	{
		FIELD1: 8,
		captured: '2024-09-23',
		id: 2476124,
		site_id: 1001,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 13000,
		hotspots: 5,
		low_count: 4,
		medium_count: 1,
		high_count: 0,
		raw_index: 155.022,
		cloud_frac: 0.17429,
		perm_regions: 22,
		dispersion_index: 43.8491,
		fill: 1
	},
	{
		FIELD1: 0,
		captured: '2024-09-15',
		id: 2470977,
		site_id: 1002,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 3200,
		hotspots: 1,
		low_count: 0,
		medium_count: 0,
		high_count: 1,
		raw_index: 100,
		cloud_frac: 0.0188467,
		perm_regions: 2,
		dispersion_index: 14.8433,
		fill: 0
	},
	{
		FIELD1: 1,
		captured: '2024-09-16',
		id: 2470977,
		site_id: 1002,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 3200,
		hotspots: 1,
		low_count: 0,
		medium_count: 0,
		high_count: 1,
		raw_index: 100,
		cloud_frac: 0.0188467,
		perm_regions: 2,
		dispersion_index: 14.8433,
		fill: 1
	},
	{
		FIELD1: 2,
		captured: '2024-09-17',
		id: 2470977,
		site_id: 1002,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 3200,
		hotspots: 1,
		low_count: 0,
		medium_count: 0,
		high_count: 1,
		raw_index: 100,
		cloud_frac: 0.0188467,
		perm_regions: 2,
		dispersion_index: 14.8433,
		fill: 1
	},
	{
		FIELD1: 3,
		captured: '2024-09-18',
		id: 2470977,
		site_id: 1002,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 3200,
		hotspots: 1,
		low_count: 0,
		medium_count: 0,
		high_count: 1,
		raw_index: 100,
		cloud_frac: 0.0188467,
		perm_regions: 2,
		dispersion_index: 14.8433,
		fill: 1
	},
	{
		FIELD1: 4,
		captured: '2024-09-19',
		id: 2470977,
		site_id: 1002,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 3200,
		hotspots: 1,
		low_count: 0,
		medium_count: 0,
		high_count: 1,
		raw_index: 100,
		cloud_frac: 0.0188467,
		perm_regions: 2,
		dispersion_index: 14.8433,
		fill: 1
	},
	{
		FIELD1: 5,
		captured: '2024-09-20',
		id: 2483450,
		site_id: 1002,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 3200,
		hotspots: 1,
		low_count: 0,
		medium_count: 0,
		high_count: 1,
		raw_index: null,
		cloud_frac: 0.761181,
		perm_regions: 2,
		dispersion_index: null,
		fill: 0
	},
	{
		FIELD1: 6,
		captured: '2024-09-21',
		id: 2483450,
		site_id: 1002,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 3200,
		hotspots: 1,
		low_count: 0,
		medium_count: 0,
		high_count: 1,
		raw_index: null,
		cloud_frac: 0.761181,
		perm_regions: 2,
		dispersion_index: null,
		fill: 1
	},
	{
		FIELD1: 7,
		captured: '2024-09-22',
		id: 2483450,
		site_id: 1002,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 3200,
		hotspots: 1,
		low_count: 0,
		medium_count: 0,
		high_count: 1,
		raw_index: null,
		cloud_frac: 0.761181,
		perm_regions: 2,
		dispersion_index: null,
		fill: 1
	},
	{
		FIELD1: 8,
		captured: '2024-09-23',
		id: 2483450,
		site_id: 1002,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 3200,
		hotspots: 1,
		low_count: 0,
		medium_count: 0,
		high_count: 1,
		raw_index: null,
		cloud_frac: 0.761181,
		perm_regions: 2,
		dispersion_index: null,
		fill: 1
	},
	{
		FIELD1: 0,
		captured: '2024-09-15',
		id: 2470979,
		site_id: 1003,
		sensor: 'sentinel-2-l1c',
		state: 1,
		capacity: 5500,
		hotspots: 4,
		low_count: 1,
		medium_count: 1,
		high_count: 2,
		raw_index: 275.578,
		cloud_frac: 0.00577819,
		perm_regions: 16,
		dispersion_index: 35.0559,
		fill: 0
	},
	{
		FIELD1: 1,
		captured: '2024-09-16',
		id: 2470980,
		site_id: 1003,
		sensor: 'landsat-8-l1tp',
		state: 1,
		capacity: 5500,
		hotspots: 1,
		low_count: 0,
		medium_count: 0,
		high_count: 1,
		raw_index: 138.683,
		cloud_frac: 0.220317,
		perm_regions: 13,
		dispersion_index: 7.34289,
		fill: 0
	},
	{
		FIELD1: 2,
		captured: '2024-09-17',
		id: 2470980,
		site_id: 1003,
		sensor: 'landsat-8-l1tp',
		state: 1,
		capacity: 5500,
		hotspots: 1,
		low_count: 0,
		medium_count: 0,
		high_count: 1,
		raw_index: 138.683,
		cloud_frac: 0.220317,
		perm_regions: 13,
		dispersion_index: 7.34289,
		fill: 1
	},
	{
		FIELD1: 3,
		captured: '2024-09-18',
		id: 2470980,
		site_id: 1003,
		sensor: 'landsat-8-l1tp',
		state: 1,
		capacity: 5500,
		hotspots: 1,
		low_count: 0,
		medium_count: 0,
		high_count: 1,
		raw_index: 138.683,
		cloud_frac: 0.220317,
		perm_regions: 13,
		dispersion_index: 7.34289,
		fill: 1
	},
	{
		FIELD1: 4,
		captured: '2024-09-19',
		id: 2470980,
		site_id: 1003,
		sensor: 'landsat-8-l1tp',
		state: 1,
		capacity: 5500,
		hotspots: 1,
		low_count: 0,
		medium_count: 0,
		high_count: 1,
		raw_index: 138.683,
		cloud_frac: 0.220317,
		perm_regions: 13,
		dispersion_index: 7.34289,
		fill: 1
	},
	{
		FIELD1: 5,
		captured: '2024-09-20',
		id: 2470980,
		site_id: 1003,
		sensor: 'landsat-8-l1tp',
		state: 1,
		capacity: 5500,
		hotspots: 1,
		low_count: 0,
		medium_count: 0,
		high_count: 1,
		raw_index: 138.683,
		cloud_frac: 0.220317,
		perm_regions: 13,
		dispersion_index: 7.34289,
		fill: 1
	},
	{
		FIELD1: 6,
		captured: '2024-09-21',
		id: 2470980,
		site_id: 1003,
		sensor: 'landsat-8-l1tp',
		state: 1,
		capacity: 5500,
		hotspots: 1,
		low_count: 0,
		medium_count: 0,
		high_count: 1,
		raw_index: 138.683,
		cloud_frac: 0.220317,
		perm_regions: 13,
		dispersion_index: 7.34289,
		fill: 1
	},
	{
		FIELD1: 7,
		captured: '2024-09-22',
		id: 2470980,
		site_id: 1003,
		sensor: 'landsat-8-l1tp',
		state: 1,
		capacity: 5500,
		hotspots: 1,
		low_count: 0,
		medium_count: 0,
		high_count: 1,
		raw_index: 138.683,
		cloud_frac: 0.220317,
		perm_regions: 13,
		dispersion_index: 7.34289,
		fill: 1
	},
	{
		FIELD1: 8,
		captured: '2024-09-23',
		id: 2470980,
		site_id: 1003,
		sensor: 'landsat-8-l1tp',
		state: 1,
		capacity: 5500,
		hotspots: 1,
		low_count: 0,
		medium_count: 0,
		high_count: 1,
		raw_index: 138.683,
		cloud_frac: 0.220317,
		perm_regions: 13,
		dispersion_index: 7.34289,
		fill: 1
	}
];

export default dummyData;
