import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import earthiLogo from '../../images/earthi-rev.png';
import { Col } from 'reactstrap';
import { Tooltip } from 'react-tooltip';
import useGainsQCApi from '../../services/useGainsQCApi';
import CapturesTable from '../CapturesTable';
import exportConfig from '../../config';

const Testing = (props) => {
	const [message, setMessage] = useState('');
	const [socket, setSocket] = useState(null);
	const [receivedMessages, setReceivedMessages] = useState([]);


	return (
		<>
			<Col className="px-0">
				<div className="contentContainer" id="dashboard-container">
					<div style={{ marginTop: '68px' }}>
						<hr></hr>
						<h1>Testing</h1>
					
					</div>
				</div>
			</Col>
		</>
	);
};

export default Testing;
